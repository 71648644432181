// import { Base64 } from 'js-base64'
// import _ from 'lodash'
import superagent from 'superagent'

// simple key/value store
// import { Storage } from '@capacitor/storage';
// https://capacitorjs.com/docs/apis/storage

//import { Plugins } from "@capacitor/core";
//const { Storage } = Plugins;
import { Storage } from '@capacitor/storage';

// https://capacitorjs.com/docs/v2/apis/storage

// [error] Analyzing dependencies
// [!] CocoaPods could not find compatible versions for pod "CapacitorStorage":
//   In Podfile:
//     CapacitorStorage (from `../../node_modules/@capacitor/storage`)

// FIX: manually set iOS version in Podfile
// platform :ios, '12.0'
// use_frameworks!

// Specs satisfying the `CapacitorStorage (from `../../node_modules/@capacitor/storage`)` dependency were found, but they required a higher minimum deployment target.

// import { Storage } from '@ionic/storage';
// https://github.com/ionic-team/ionic-storage#api
// https://github.com/localForage/localForage#configuration

// query-able local cache saved in the cloud
// Firebase Cloud Firestore
// https://firebase.google.com/docs/firestore/manage-data/enable-offline

// import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

// project file as key string
// project file path as key string

// "lantern:tree"
// "lantern:manifest"
// "lantern:file:lantern/controller/config.json"

// https://api.github.com/repositories/388634363/contents/lantern/controller
// {
//     "name": "config.json",
//     "path": "lantern/controller/config.json",
//     "sha": "9e26dfeeb6e641a33dae4961196235bdb965b21b",
//     "size": 2,
//     "url": "https://api.github.com/repos/kitlab-io/kits-public/contents/lantern/controller/config.json?ref=main",
//     "html_url": "https://github.com/kitlab-io/kits-public/blob/main/lantern/controller/config.json",
//     "git_url": "https://api.github.com/repos/kitlab-io/kits-public/git/blobs/9e26dfeeb6e641a33dae4961196235bdb965b21b",
//     "download_url": "https://raw.githubusercontent.com/kitlab-io/kits-public/main/lantern/controller/config.json",
//     "type": "file",
//     "_links": {
//       "self": "https://api.github.com/repos/kitlab-io/kits-public/contents/lantern/controller/config.json?ref=main",
//       "git": "https://api.github.com/repos/kitlab-io/kits-public/git/blobs/9e26dfeeb6e641a33dae4961196235bdb965b21b",
//       "html": "https://github.com/kitlab-io/kits-public/blob/main/lantern/controller/config.json"
//     }
// }

// const setName = async () => {
//   await Storage.set({
//     key: 'name',
//     value: 'Max',
//   });
  
// };

// const checkName = async () => {
//   const { value } = await Storage.get({ key: 'name' });

//   alert(`Hello ${value}!`);
// };

// const removeName = async () => {
//   await Storage.remove({ key: 'name' });
// };

import logger from '../lib/logger'
const log = logger.log()
const debug = logger.debug()

const LocalStorage = async () => {

    const store = Storage

    // const store = new Storage({
    //     driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage]
    //   });
    //   await this.store.defineDriver(CordovaSQLiteDriver);
    //   await store.create();

    let keys = await store.keys()
    debug.storage('store initialized', keys)

    const reset = async ()=> {
        await store.clear()
    }

    const cacheCloudProjectManifest = async (projectName, projectItems) => {
        let key = [ projectName,':','manifest'].join('')
        let value = JSON.stringify(projectItems)

        return await store.set({
            key,
            value
        })
    }

    const cacheProjectFile = async (projectName, filePath, fileContent) => {
        let key = [ projectName,':','file',':',filePath].join('')
        debug.storage("cacheProjectFile: %s, len: %s", key, fileContent.length);
        return await store.set({
            key: key,
            value: fileContent
        });
    }

    const cacheGithubRepoItem = async (projectName, itemData) => {
        debug.storage(projectName,itemData)
        let key = [ projectName,':','file',':',itemData.path].join('')
        let value = await superagent.get(itemData.download_url).then(res => {
            debug.storage(res)
            return res.text;
        })
        debug.storage(key,value)

        return await store.set({
            key,
            value
        })
    }

    // https://docs.github.com/en/rest/releases/assets?apiVersion=2022-11-28#get-a-release-asset
    /*
    {
        "url": "https://api.github.com/repos/octocat/Hello-World/releases/assets/1",
        "browser_download_url": "https://github.com/octocat/Hello-World/releases/download/v1.0.0/example.zip",
        "id": 1,
        "node_id": "MDEyOlJlbGVhc2VBc3NldDE=",
        "name": "example.zip",
        "label": "short description",
        "state": "uploaded",
        "content_type": "application/zip",
        "size": 1024,
        "download_count": 42,
        "created_at": "2013-02-27T19:35:32Z",
        "updated_at": "2013-02-27T19:35:32Z",
        "uploader": {
            "login": "octocat",
            "id": 1,
            "node_id": "MDQ6VXNlcjE=",
            "avatar_url": "https://github.com/images/error/octocat_happy.gif",
            "gravatar_id": "",
            "url": "https://api.github.com/users/octocat",
            "html_url": "https://github.com/octocat",
            "followers_url": "https://api.github.com/users/octocat/followers",
            "following_url": "https://api.github.com/users/octocat/following{/other_user}",
            "gists_url": "https://api.github.com/users/octocat/gists{/gist_id}",
            "starred_url": "https://api.github.com/users/octocat/starred{/owner}{/repo}",
            "subscriptions_url": "https://api.github.com/users/octocat/subscriptions",
            "organizations_url": "https://api.github.com/users/octocat/orgs",
            "repos_url": "https://api.github.com/users/octocat/repos",
            "events_url": "https://api.github.com/users/octocat/events{/privacy}",
            "received_events_url": "https://api.github.com/users/octocat/received_events",
            "type": "User",
            "site_admin": false
        }
    }
    */
    const cacheGithubReleaseAsset = async (projectName, itemData) => {
        debug.storage(projectName,itemData)
        let key = [ projectName,':','file',':',itemData.name].join('')
        let value = await superagent.get(itemData.browser_download_url).then(res => {
            debug.storage(res)
            return res.text;
        })
        debug.storage(key,value)

        return await store.set({
            key,
            value
        })
    }

    const getProjectFile = async (projectName, filePath) => {
        let key = [ projectName,':','file',':',filePath].join('')
        const { value } = await store.get({key: key})
        debug.storageContent("getProjectFile, key %s, content %s", key, value);
        return value
    }

    const removeProjectFile = async (projectName, filePath) => {
        let key = [ projectName,':','file',':',filePath].join('')
        await store.remove({key: key})
        debug.storage("removeProjectFile, key %s", key);
        return true
    }

    const getLocalManifestItems = async (projectName) => {
        let key = [ projectName,':','manifest'].join('')
        const items = await store.get({key})
        return items
    }

    return {
        reset,
        cacheCloudProjectManifest,
        cacheGithubRepoItem,
        cacheGithubReleaseAsset,
        getProjectFile,
        getLocalManifestItems,
        cacheProjectFile,
        removeProjectFile
    }
}

export default LocalStorage
