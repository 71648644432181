/*
pyboard.js
Description:
     used to upload and download files to / from micropython board via BLE or other comm port
*/
'use strict'
import { GetChecksum, SleepMs, ReadFileResponse } from "./helpers.js";
export const CTRL_A = 0x01; // raw repl
export const CTRL_B = 0x02; // exit raw repl
export const CTRL_C = 0x03; // ctrl-c
export const CTRL_D = 0x04; // reset (ctrl-d)
export const CTRL_E = 0x05; // paste mode (ctrl-e)
export const CTRL_F = 0x06; // safe boot (ctrl-f)
export const CRTRN = 0x0D; // carrage return
export const EOT = 0x04; // end of transmission
export const RESP_WAIT_TIME_MS = 500; //500 ms
export const EOT_INDEX_LAST_OFFSET = 3;
const EXEC_RAW_RESP_OK = 'OK\x04\x04>';
  
export class Pyboard {
      constructor(repl){
        this.repl = repl;
        this.encoder = new TextEncoder();
        this.decoder = new TextDecoder();
        this.rawmode = false;
      }
      async enterRawRepl(){
          //send ctr-C twice (to quit any running program) then ctrl-A to enter raw REPL
          console.log("Pyboard.enterRawRepl");
          await this.repl.enter_raw_repl();
      }
  
      async exitRawRepl(timeoutMs = 200){
          //ctrl-B to enter friendly REPL
          console.log("Pyboard.exitRawRepl");
          await this.repl.exit_raw_repl();
      }

      async reboot(){
        console.log("Pyboard.reboot");
        let encoder = new TextEncoder();
        await this.repl.write(new Uint8Array([CRTRN, CTRL_C, CTRL_C]));
        await SleepMs(1000);
        let cmdBytes = encoder.encode("import machine; machine.reset()\r\n"); // str to bytes
        await this.repl.write(cmdBytes);
        await SleepMs(5000);
        return new Promise((resolve) => {resolve(true);});
      }
  
      async readFile(fileName){
        console.log("Pyboard.readFile: " + fileName);
        await this.enterRawRepl();
        console.log("Pyboard.readFile f=open");
        let out = await this.repl.exec_raw("f=open('" + fileName + "','rb')\r\n");
        if(out != EXEC_RAW_RESP_OK)
        {
            return false;
        }
        console.log("Pyboard.readFile r=f.read");
        let checksumResp = await this.repl.exec_raw("r=f.read()\r\nc=0\r\nfor d in r:\r\n   c=(c+d) & 0x00FF\r\nc=((c ^ 0xFF) + 1) & 0x00FF\r\nimport sys\r\nsys.stdout.write(str(c))\r\n");
        console.log("Pyboard.readFile execute");
        checksumResp = this.extract(checksumResp); 
        console.log("checksumResp.length: " + checksumResp.length);
        console.log("Pyboard.readFile->checksum: " + checksumResp);
        out = await this.repl.exec_raw("f.close()\r\n");
        if(out != EXEC_RAW_RESP_OK || this.extractErr(checksumResp))
        {
            console.error("readFile failed for: " + fileName);
            return false;
        }

        let checksum = String(checksumResp);
        let resp = await this.repl.exec_raw("sent=sys.stdout.write(r)\r\ndel(r)\r\n");
        resp = this.extract(resp);
        console.log("received file read data with resp.length: " + resp.length);
        await this.exitRawRepl();
        if(this.extractErr(resp))
        {
            console.error("sys.stdout.write(r) readFile failed for: " + fileName);
            return false;
        }
        return new Promise((resolve, reject) => { 
            this.checksumResp = checksumResp;
            resolve(new ReadFileResponse({name: fileName, checksum: parseInt(checksum), data: resp.replace(/\r/g, "")}));
        });
      }

      async getDirList(rootPath="/"){
        //list all contents of directory on MicroPython board from root path
        let cmd = 
"import os, sys, json\r\n\
t=[{'path': '" + rootPath + "', 'type':'tree'}]\r\n\
def get_dir_tree(root='" + rootPath + "', tree=t):\r\n\
    for f in os.listdir(root):\r\n\
        path = root.rstrip('/') + '/' + f.lstrip('/')\r\n\
        is_dir = (os.stat(path)[0] == 0x4000)\r\n\
        ftype = 'tree' if is_dir else 'blob'\r\n\
        tree.append({'path': path, 'type': ftype})\r\n\
        if is_dir:\r\n\
            get_dir_tree(path, tree)\r\n\
    return tree\r\n\
tree=get_dir_tree()\r\n\
sys.stdout.write(json.dumps(tree))\r\n";
        await this.enterRawRepl();
        let resp = await this.repl.exec_raw(cmd)
        let parsedResp = this.extract(resp);
        await this.exitRawRepl();
        let dirListJson = JSON.parse(parsedResp);
        return dirListJson;
    }

    async writeFile(fileName, fileContentStr){
        function getSumz(data)
        {
            let sum = 0;
            for(let i in data){
                sum  = (sum + data[i]) & 0x00FF;
            }
            return sum;
        }

        console.log("Pyboard.writeFile: " + fileName);
        //let fileData = fileContentStr.replace(/\r/g,"\\r").replace(/\n/g,"\\n").replace(/"/g,'\\"'); //for python bytearray purposes
        let bytes = this.encoder.encode(fileContentStr);
        let checksum = GetChecksum(bytes);
        await this.enterRawRepl();
        let ok = await this.repl.exec_raw(`import sys; f=open("${fileName}","wb")\r\nc=0\r\n`);
        if(ok != EXEC_RAW_RESP_OK)
        {
            console.error("import sys; f=open failed, try again");
            return false;            
        }
        let chunkSize = 1024*2;

        while(fileContentStr.length > 0){
            let msgChunk = fileContentStr.slice(0,chunkSize);
            let chunkBytes = this.encoder.encode(msgChunk);
            let fileData = this.convertToEscapedString(msgChunk);
            let out = await this.repl.exec_raw(`b=bytearray(b"${fileData}")\n`);
            if(out != EXEC_RAW_RESP_OK)
            {
                console.error("failed bytearray for " + fileData);
                continue; //try again
            }
            SleepMs(100);
            
            let chunkChecksum = await this.repl.exec_raw(`temp = (sum(b) & 0x00FF);sys.stdout.write(str(temp))\n`);
            chunkChecksum = this.extract(chunkChecksum);
            if(getSumz(chunkBytes).toString() != chunkChecksum.trim())
            {
                console.error("chunk checksum failed " + getSumz(chunkBytes).toString() + " != " + chunkChecksum.trim());
                continue;
            }

            out = await this.repl.exec_raw("f.write(b)\n");
            SleepMs(100);

            if(out != EXEC_RAW_RESP_OK)
            {
                console.error("f.write(b) fail " + out);
                continue;
            }
            else 
            {
                // increment chunk position and crc if success
                out = await this.repl.exec_raw("c=((c + temp) & 0x00FF)\n");
                if(out != EXEC_RAW_RESP_OK)
                {
                    console.error("checksum update failed " + out); 
                    break;
                }   
                fileContentStr = fileContentStr.slice(chunkSize); 
            }       
        }
        
        let checksumStr = await this.repl.exec_raw(`c=(((c ^ 0xFF) + 1) & 0x00FF)\nsys.stdout.write(str(c))`);
        checksumStr = this.extract(checksumStr);
        await this.repl.exec_raw("f.close()");
        await this.exitRawRepl();
        return new Promise((resolve)=>{
        try {
            let checksumReceived = checksumStr.trim();
            resolve(checksumReceived == checksum.toString());
        }catch (e) {
            console.error(e);
            resolve(false);
        }
        });
    }
    // make new directory using os.mkdir
    async mkDir(dirPath) {
        let res = true;
        let command = "import os; os.mkdir('" + dirPath + "')\n";
        await this.enterRawRepl();
        let ok = null;
        let tries = 0;
        do
        {
            ok = await this.repl.exec_raw(command);
            tries++;
            
        }while(ok != EXEC_RAW_RESP_OK && tries <= 3);

        if(ok != EXEC_RAW_RESP_OK)
        {
            res = false;
        }
        await this.exitRawRepl();
        return res;
    }

    async renameFile(old_file_path, new_file_path)
    {
        let res = true;
        let command  =`import os; os.rename( "${old_file_path}", "${new_file_path}") \r\n`;
        await this.enterRawRepl();
        let ok = null;
        let tries = 0;
        do
        {
            ok = await this.repl.exec_raw(command);
            tries++;
        }while(ok != EXEC_RAW_RESP_OK && tries <= 3);

        if(ok != EXEC_RAW_RESP_OK)
        {
            res = false;
        }

        await this.exitRawRepl();
        return res;
    }

    async removeFiles(paths=[]) {
        await this.enterRawRepl();
        for(let p in paths){
            let command = "import os; os.remove('" + paths[p] + "')\r\n";
            let ok = await this.repl.exec_raw(command);
            if(ok != EXEC_RAW_RESP_OK)
            {
                // retry
                ok = await this.repl.exec_raw(command);
                if(ok != EXEC_RAW_RESP_OK)
                {
                    console.error("failed to remove " + paths[p]);
                }
            }
        }
        return this.exitRawRepl();
    }

    async removeDirs(paths=[]) {
    const defCommand = 
"import os\r\n\
def rmdir(directory):\r\n\
    os.chdir(directory)\r\n\
    for f in os.listdir():\r\n\
        try:\r\n\
            os.remove(f)\r\n\
        except OSError:\r\n\
            pass\r\n\
    for f in os.listdir():\r\n\
        rmdir(f)\r\n\
    os.chdir('..')\r\n\
    os.rmdir(directory)\r\n";

        await this.enterRawRepl();
        await this.repl.exec_raw(defCommand);
        for(let p in paths){
            let command = "rmdir('" + paths[p] + "')\r\n";
            await this.repl.exec_raw(command);
        }
        return this.exitRawRepl();
    }

    async getFileChecksum(filePath)
    {
        await this.enterRawRepl();
        let ok = await this.repl.exec_raw(`import sys;f=open("${filePath}","rb");c=0\n`);
        if(ok != EXEC_RAW_RESP_OK)
        {
            console.error("import sys; f=open failed for " + filePath);
            return null;            
        }
        ok = await this.repl.exec_raw(`s=0\nfor l in f.readlines():\n   s += sum(l)\nc = (((s ^ 0xFF) + 1) & 0xFF)\n`);
        if(ok != EXEC_RAW_RESP_OK)
        {
            console.error("checksum func failed: " + ok);
            return null;
        }
        let checksumStr = await this.repl.exec_raw(`sys.stdout.write(str(c))`);
        return parseInt(this.extract(checksumStr));
    }

    convertToEscapedString(data){
        return encodeURI(data).replace(/%/g,"\\x"); //pepare for bytearray format for micropython (ex: '\x69\n\x56')
    }

    extract(out) {
        /*
         * Message ($msg) will come out following this template:
         * "OK${msg}\x04${err}\x04>"
         * TODO: consider error handling
         * ex err: `OK\x04Traceback (most recent call last):\r\n  File "<st…odule>\r\nNameError: name 'woops' isn't defined\r\n\x04>`
         * ex resp: 'OK5\x04\x04>' this is if board returns '5'
         * 
         */
        return out.slice(2, -3)
    }

    extractErr(out)
    {
        /*
        * ex: out = `OK\x04Traceback (most recent call last):\r\n  File "<st…odule>\r\nNameError: name 'woops' isn't defined\r\n\x04>`
        * will return 'Traceback (most recent call last):\r\n  File "<st…odule>\r\nNameError: name 'woops' isn't defined\r\n'
        */

        const regex = /OK\x04(.+?)\x04>/;
        let matches = out.match(regex);
        if(matches && matches.length == 2)
        {
            return matches[1];
        }
        return null;
    }

}
