<template>
    <v-container fluid>
      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title v-if="!deviceConnected">
                No JEM Connected
              </v-list-item-title>
              <v-list-item-title v-if="deviceConnected">
                {{ deviceName }}
              </v-list-item-title>
              <!-- <v-list-item-subtitle>...</v-list-item-subtitle> -->
            </v-list-item-content>
            <v-list-item-action>
              <v-btn class="ma-2" :loading="connectRequested" :disabled="connectRequested"
                :color="bleConnected ? 'error' : 'blue'" @click="toggleBleConnect">
                {{ connectStatus }}
                <template v-slot:loader>
                  <span>Connecting...</span>
                </template>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-if="featureFlags.serialConnect">
            <v-list-item-content>
              <v-list-item-title>
                <!-- todo ?-->
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn class="ma-2" @click="serialConnect">
                {{ serialConnectStatus }}
              </v-btn>
            </v-list-item-action>
          </v-list-item>          
        </v-list>
      </v-card>
      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                Custom Kit
              </v-list-item-title>
              <v-list-item-subtitle>
                from a public Github repo
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-dialog v-model="dialogCustomKitSourceInfo" max-width="500">

                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="teal" dark v-bind="attrs" v-on="on">
                    Load
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="text-h5">
                    Add a custom JEM Project or Kit
                  </v-card-title>
                  <v-card-text>
                    <v-expansion-panels v-model="panel" :readonly="readonly">
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <div>
                            <v-icon left color="blue">mdi-information</v-icon>
                            How it works
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <p>
                            You can load a source code for a JEM project or kit from a Github repo that has the same
                            structure
                            as a release in the official <a href="https://github.com/kitlab-io/micropython/releases"
                              target="blank">Kitlab micropython repo</a>.
                          </p>
                          <p>
                            Learn how to make a release from a Github branch with <a
                              href="https://docs.github.com/en/repositories/releasing-projects-on-github/managing-releases-in-a-repository"
                              target="blank">this Github docs page</a>.
                          </p>
                        </v-expansion-panel-content>
                      </v-expansion-panel>

                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <div>
                            <v-icon left color="teal">mdi-cloud-download</v-icon>
                            Load the project or kit
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <div class="pa-3">
                            <div class="text-h6">Kitlab Mircopython Repo URL</div>
                            <div class="mb-2">https://github.com/<span class="purple">kitlab-io</span>/<span
                                class="teal">micropython</span>/tree/<span class="green darken-2">main</span></div>

                            <div class="text-h6">Custom Kit Repo URL</div>
                            <div>https://github.com/<span class="font-italic purple">{repo owner}</span>/<span
                                class="font-italic teal">{repo name}</span>/tree/<span
                                class="font-italic green darken-2">{repo
                                tree}</span>
                            </div>
                          </div>
                          <v-form v-model="form" @submit.prevent="onSubmit">
                            <!-- <v-text-field v-model="githubRepoURL" :readonly="loading" :rules="[required]" class="mb-2" clearable
                        label="Github repo URL" placeholder="https://github.com/kitlab-io/micropython"></v-text-field> -->

                            <v-text-field v-model="githubRepoOwner" :readonly="loading" :rules="[required]" class="mb-2"
                              clearable label="Github repo owner" placeholder="kitlab-io"></v-text-field>

                            <v-text-field v-model="githubRepoName" :readonly="loading" :rules="[required]" class="mb-2"
                              clearable label="Github repo name" placeholder="micropython"></v-text-field>

                            <v-text-field v-model="githubRepoTree" :readonly="loading" :rules="[required]" class="mb-2"
                              clearable label="Github repo tree" placeholder="main"></v-text-field>

                            <div v-if="form" class="pa-3">
                              Loading from: https://github.com/{{ githubRepoOwner }}/{{
                                githubRepoName }}/tree/{{ githubRepoTree }}
                            </div>

                            <v-btn :disabled="!form" :loading="loading" block color="teal" size="large" type="submit"
                              variant="elevated">
                              Load from Github
                            </v-btn>
                            <div v-if="loadingGithubError != null">
                              <strong class="red--text text--lighten-1">
                                {{ loadingGithubError }}
                              </strong>
                            </div>
                            <v-btn v-if="loading" color="warning darken-1" text size="large"
                              @click="cancelLoadingProject">
                              Cancel
                            </v-btn>
                          </v-form>
                        </v-expansion-panel-content>
                      </v-expansion-panel>

                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <div><v-icon left color="warning">mdi-alert</v-icon>
                            Troubleshooting
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <div class="pa-3">
                            <p>

                              If you load custom code and then have issues connecting to JEM, the firmware or filesystem
                              may
                              have
                              been corrupted. In this case, you may need to connect directly to JEM over USB to reset its
                              firmware.
                            </p>
                            <p>We recommend the <a href="https://labs.arduino.cc/en/labs/micropython">Arduino Editor for
                                Micropython</a> to connect to JEM over USB and debug further.
                            </p>
                            <p>
                              Get further support by joining the <a href="https://discord.gg/b7eqPCJUpm"
                                target="blank">Kitlab Discord community</a> and posting any issue you encounter. Happy
                              Creating!
                            </p>
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>

                  </v-card-text>

                  <v-card-actions>

                    <v-spacer></v-spacer>
                    <v-btn color="warning darken-1" text @click="cancelLoadingProject">
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
    <!-- NOTE: need to do: key=""'A' + index" to avoid conflict with the other key index in the officla release v-for -->
    <v-card v-for="(release, index) in customGithubProjects" :key="'A'+ index">
        <v-list>

         <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ release.title }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ release.subtitle }}
              </v-list-item-subtitle>

            </v-list-item-content>
            <v-list-item-action>
              <v-btn class="ma-2" color="teal" :loading="loadingProjectName == release.name" :disabled="!activeButtons"
                @click="loadCustomProject(release.owner, release.repo, release.tree)">
                Select
              </v-btn>

            </v-list-item-action>
          </v-list-item>
        </v-list>

      </v-card>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                Official Kitlab Kits
              </v-list-item-title>
              <v-list-item-subtitle v-if="officialReleases.length == 0">
                No kits loaded
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="officialReleases.length > 0">
                {{ officialReleases.length }} kits loaded
              </v-list-item-subtitle>
              <!-- <v-list-item-subtitle>...</v-list-item-subtitle> -->
            </v-list-item-content>
            <v-list-item-action>
              <v-btn class="ma-2" color="success" v-if="officialReleases.length == 0" @click="showRepoReleases">
                Load all
              </v-btn>
              <v-btn class="ma-2" color="primary" v-if="officialReleases.length > 0" @click="showRepoReleases">
                Reload all
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>

     <!-- Updated v-card for official releases -->
     <v-card v-for="(rls, index) in officialReleases" :key="index">
    <v-card-title>
      {{ rls.name }}
      <v-spacer></v-spacer>
      <v-btn class="ma-2" color="success" :loading="loadingProjectName == rls.name" :disabled="!activeButtons" @click="loadProject(rls)">
        SYNC
      </v-btn>
    </v-card-title>
    <v-card-subtitle>
      <v-btn @click="toggleReadme(rls)">
        {{ rls.showReadmeContent ? 'HIDE README' : 'README' }}
      </v-btn>
    </v-card-subtitle>
    <v-card-text v-if="rls.showReadmeContent" v-html="rls.readmeContent"></v-card-text>
  </v-card>
  <v-card>
    <v-card-title>
      Clear Projects Storage
      <v-spacer></v-spacer>
      <v-btn @click="clearStorage">Clear Storage</v-btn>
    </v-card-title>
    <v-card-subtitle>
      Used for troubleshooting if local storage full
    </v-card-subtitle>
  </v-card>

    </v-container>
</template>
<script>
import logger from '../lib/logger';
const log = logger.log();
const debug = logger.debug();
import { Device } from '../store/projects.js';
import { mapState } from 'vuex';
import LocalStorage from '@/lib/localstorage';
import CloudProjects from '../lib/cloud_projects';
import MarkdownIt from 'markdown-it';
let localstorage = null;

import { Capacitor } from '@capacitor/core';
const isCameraAvailable = Capacitor.isPluginAvailable('Camera');
const currentPlatform = Capacitor.getPlatform()

console.log(`Capacitor.isNativePlatform ${Capacitor.isNativePlatform}`)
console.log(`currentPlatform ${currentPlatform}`)
console.log(`isCameraAvailable ${isCameraAvailable}`)

let featureFlags = {
  serialConnect: false
}

// handle different platforms
if (currentPlatform === 'web') {
  console.log('enable features for web')
  featureFlags.serialConnect = true
}

//motion: release 1.0.5: 
//https://github.com/ionic-team/capacitor-plugins/tree/3beb75a9bfc18d0586f2c9df1b584250e95e0237/motion

export default {
  props: [],
  computed:
  {
    bleConnected() {
      return Device.bleManager.connected;
    },
    deviceConnected(){
      return Device.isConnected();
    },
    ...mapState({
      deviceProject: state => state.projects.deviceProject,
      deviceName: state => state.projects.deviceName,
    })
  },

  data: () => ({
    featureFlags, 
    
    // custom kit source info
    // panel: [0, 1], // multiple panels open simultaneously
    panel: 1,
    readonly: false,

    form: false,
    loading: false,
    loadingGithubError: null,

    githubRepoOwner: "kitlab-io",
    githubRepoName: "micropython",
    githubRepoTree: "main",
    // githubRepoName: null,
    // githubRepoOwner: null,
    // githubRepoTree: null,
    // githubRepoURL: null,
    customGithubProjects: [],

    count: 0,
    menu: true,
    connectRequested: false,
    flashRequested: false,
    loadingProject: false,
    loadingProjectName: null,
    activeButtons: true,
    connectStatus: "BLE Connect",
    serialConnectStatus: "Serial Connect",
    officialReleases: [
   // { name: '...', tag: '...', id: '...', url: '...', previewImage: null, showReadmeContent: false, readmeContent: '' },
    ],
    // dialogs
    dialogCustomKitSourceInfo: false,
  }),

  watch: {
    bleConnected(connected) {
      this.prevAccelTimeMs = 0;
      this.currentAccelTimsMs = 0;
      if (connected)
        this.connectStatus = "BLE Disconnect";
      else
        this.connectStatus = "BLE Connect";
    }
  },
  mounted() {
    this.initLocalStorage();
  },

  created() {
    // warns user before navigating away from page
    window.addEventListener('beforeunload', function (e) {
      // Cancel the event
       e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      // Chrome requires returnValue to be set
      e.returnValue = 'leave?';
    });
  },

  methods: {
    async clearStorage()
    {
      await this.$store.dispatch('projects/clearStorage');
      alert("Storage Cleared");
    },

    getReadmeUrl (release) {
      const tag_name = release.tag;
      const repoUrl = release.url.replace("https://api.github.com/repos/", "").replace(`/releases/${release.id}`, "");
      const readme_url = `https://github.com/${repoUrl}/blob/${tag_name}/README.md`;
      return readme_url;
    },

    // Updated toggleReadme method to show/hide README content
    toggleReadme: async function (rls) {
      try {
        let readmeUrl = this.getReadmeUrl(rls);
        console.log(readmeUrl);
        const cloudProjects = await CloudProjects();

        // Find the index of the release in the officialReleases array
        const index = this.officialReleases.findIndex((release) => release.id === rls.id);

        if (index !== -1) {
          if (this.officialReleases[index].showReadmeContent) {
            // If README is visible, hide it
            this.$set(this.officialReleases, index, {
              ...this.officialReleases[index],
              showReadmeContent: false,
            });
          } else {
            // If README is hidden, fetch and show it
            const readmeContent = await cloudProjects.getReadme(readmeUrl);
            console.log(readmeContent);
            // Create a new instance of markdown-it
            const md = new MarkdownIt();

            // Render the markdown to HTML
            let renderedMarkdown = md.render(readmeContent);
            this.$set(this.officialReleases, index, {
              ...this.officialReleases[index],
              showReadmeContent: true,
              readmeContent: renderedMarkdown,
            });
          }
        }

      } catch (error) {
        console.error('Error fetching or hiding README:', error);
      }
    },

    async serialConnect()
    {
      if(Device.bleManager.connected)
      {
        alert("Disconnect from BLE first");
        return;
      }

      if(this.serialConnectStatus == "Serial Connect")
      {
        //await Device.connect('serial', navigator);
        await this.$store.dispatch('projects/connectDevice', {connectionType: 'serial', args: navigator});
        this.serialConnectStatus = "Serial Disconnect";
        await this.$store.dispatch('projects/updateDeviceTreeAfterConnected');
      }
      else 
      {
        await Device.disconnect('serial');
        this.serialConnectStatus = "Serial Connect";
      }
    },

    // loading custom kit
    async onSubmit() {
      if (!this.form) return

      this.loading = true

      // setTimeout(() => (this.loading = false), 2000)

      // TODO validate Github repo URL
      // TODO show validation error
      // TODO show general error
      let loadSuccess = await this.getCustomKit()

      if (loadSuccess) {
        this.loading = false

        this.dialogCustomKitSourceInfo = false
      } else {
        this.loading = false
        console.error("error loading from Github")
      }

    },
    required(v) {
      return !!v || 'Field is required'
    },
    async getCustomKit() {
      this.loadingGithubError = null

      let projectData = await this.loadCustomProject(this.githubRepoOwner, this.githubRepoName, this.githubRepoTree)

      console.log(projectData)

      if (projectData != null) {
        this.customGithubProjects.push(projectData)
        return true

      } else {
        this.loadingGithubError = "Error loading Github project. Is the URL valid?"
        return false
      }
    },

    cancelLoadingProject() {
      this.dialogCustomKitSourceInfo = false
      this.loading = false
    },

    // local storage interaction witn local project cache
    async initLocalStorage() {
      localstorage = await LocalStorage();
    },

    async cacheProjectFile() {
      let projectName = "testproject";
      let filePath = '/flash/test/test.py';
      await localstorage.cacheProjectFile(projectName, filePath, 'hello world');
    },

    async getCachedProjectFile() {
      let projectName = "testproject";
      let filePath = '/flash/test/test.py';
      let value = await localstorage.getProjectFile(projectName, filePath);
      debug.log("getCachedProjectFile value: " + value);
    },

    loadProject: async function (release) {

      // clear out loaded state for custom projects so user can select and reload them
      this.customGithubProjects.forEach(project => {
        project.loaded = false
      })

      // show loading status on load button
      this.loadingProjectName = release.name;
      this.loadingProject = true
      // lock UI while loading
      this.activeButtons = false

      let options = {
        name: release.name,
        tag_name: release.tag, // == tree_sha for Github API request
        repo_name: "micropython",
        owner_name: "kitlab-io",
        release_url: release.url,
      };
      await this.$store.dispatch('projects/fetchGithubProject', options);
      await this.$store.dispatch('projects/updateActiveProjectByName', release.name);
      //await this.$store.dispatch('projects/setKitFileToCurrentFile'); // Editor.vue does this now

      // unlock UI
      this.activeButtons = true
      // restore UI
      this.loadingProject = false
      this.loadingProjectName = null
    },

    // ex. https://github.com/asyncopatedsoul/micropython/tree/main
    loadCustomProject: async function (ownerName, repoName, treeSha) {
      console.log("loadCustomProject")
      let releaseUrl = `https://github.com/${ownerName}/${repoName}/tree/${treeSha}`
      // let projectName = `${ownerName}/${repoName}`
      let projectName = `${repoName}/${treeSha}`
      // show loading status on load button
      this.loadingProjectName = projectName
      this.loadingProject = true
      // lock UI while loading
      this.activeButtons = false


      let projectOptions = {
        name: projectName,
        tag_name: treeSha, // == tree_sha for Github API request
        repo_name: repoName,
        owner_name: ownerName,
        release_url: releaseUrl
      };
      console.log(projectOptions)
      let projectLoaded = await this.$store.dispatch('projects/fetchGithubProject', projectOptions)

      if (projectLoaded) {
        await this.$store.dispatch('projects/updateActiveProjectByName', projectName)
        //await this.$store.dispatch('projects/setKitFileToCurrentFile'); // Editor.vue does this now


        // unlock UI
        this.activeButtons = true
        // restore UI
        this.loadingProject = false
        this.loadingProjectName = null

        // TODO set data for showing custom project in UI
        let projectData = {
          "owner": ownerName,
          "repo": repoName,
          "tree": treeSha,
          "loaded": true,
          "title": projectName,
          "subtitle": `by ${ownerName}`,

          "name": projectName,
          "tag": treeSha,
          "id": projectName,
          "previewImage": null, // will be set after fetching the docs folder for the kit
        }

        return projectData

      } else {
        console.log(`project failed to load`)


        // unlock UI
        this.activeButtons = true
        // restore UI
        this.loadingProject = false
        this.loadingProjectName = null

        return null
      }



    },

    showRepoReleases: async function () {
      debug.log("showRepoReleases!");
      let repo_releases = await this.$store.dispatch('projects/getRepoReleases');
      
      this.officialReleases = [];

      for (let i = 0; i < repo_releases.length; i++) {
        let release = repo_releases[i];
        debug.log(release);
      
        // check for release preview assets, like images/gifs
        release.assets.forEach(async (asset) => {
          debug.log(asset)
          if (asset.content_type == "image/gif") {
            debug.log('release asset is GIF')
            // TODO fetch content from repo blob, instead of release asset binary
            // let release_asset = await this.$store.dispatch('projects/getRepoReleaseAsset', asset.id);
            // let release_asset = await localstorage.cacheGithubReleaseAsset(release.name, asset)
            // debug.log(release_asset)
          }
        });
      
        if (!release.prerelease) //only show releases that are not marked 'prerelease'
        {
          let rls = {
            "name": release.name,
            "tag": release.tag_name,
            "id": release.id,
            "url": release.url,
            "previewImage": null, // will be set after fetching the docs folder for the kit
          };
          this.officialReleases.push(rls);
        }
      }

    },

    toggleBleConnect: function () {
      if(Device.serial.isConnected())
      {
        alert("Disconnect from Serial first");
        return;
      }

      if (this.bleConnected)
        this.bleDisconnect();
      else if(!Device.isConnected())
        this.bleConnect();
    },

    bleConnect: async function () {
      this.$track.JEMConnectStart();

      this.connectRequested = true;
      let connectionResult = await this.$store.dispatch('projects/connectDevice', {connectionType: 'ble', args: null});
      this.connectRequested = false;
      debug.log("bleConnected ")
      debug.log(connectionResult);
      if (connectionResult.connected) {
        await this.$store.dispatch('projects/updateDeviceTreeAfterConnected');
      } else {
        this.$track.JEMConnectCanceled();
        // this.$track.JEMConnectFailed();
      }
    },

    bleDisconnect: async function () {
      this.$track.JEMDisconnectByUser();

      await this.$store.dispatch('projects/disconnectDevice', { connectionType: 'ble'});
    },
  },
};
</script>
<style scoped>
.v-card {
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>