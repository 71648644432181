//ble_service.js
'use strict'
export class BleService {
    constructor(serviceUUID){
        this.bleManager = null; //this is populated by BleManager.addService
        this.serviceUUID = serviceUUID;
        this.mtu = 200;
        this.available = false;
    }

    ping()
    {
        //override this
        console.error("ping not implemented");
    }

    startNotifications()
    {
        //override this
        console.error("startNotifications not implemented");
    }

    async onConnect()
    {
        // override this in your service
    }
}