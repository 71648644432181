'use strict'

export class ProjectStorageManager {
    static async saveFile({storage=null, project=null, filePath=null, content=null}){
        console.log("ProjectStorageManager.saveFile from project %s, file %s", project.name, filePath)
        //store to localstorage
        let result = false;
        
        if(storage && project && project.getFileFromTree(filePath)){
          await storage.cacheProjectFile(project.storage_name(), filePath, content);
          result = true;
        } else 
            console.warn("failed")
        return new Promise((resolve)=>{resolve(result);});
    }
  
    static async getSavedFile({storage=null, project=null, filePath=null}){
    //get previously stored file from local storage
    console.log("ProjectStorageManager.getSavedFile from project %s, file %s", project.name, filePath)
    let value = null;        
    if(storage && project && project.getFileFromTree(filePath))
        value = await storage.getProjectFile(project.storage_name(), filePath);
    else 
        console.warn("failed")
    return new Promise((resolve)=>{resolve(value);});
    }

    static async removeSavedFile({storage=null, project=null, filePath=null}){
        console.log("ProjectStorageManager.getSavedFile from project %s, file %s", project.name, filePath)
        let success = false;        
        if(storage && project && project.getFileFromTree(filePath)){
            await storage.removeProjectFile(project.storage_name(), filePath);
            success = true;
        }
        else 
            console.warn("failed")
        return new Promise((resolve)=>{resolve(success);});
    }
}