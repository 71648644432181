//ble_info_service.js
'use strict'

import { BleService } from "./ble_service.js";
import { numbersToDataView, numberToUUID } from '@capacitor-community/bluetooth-le';

const struct = require('python-struct'); //for packing / unpacking bytes

//repl uart ble uuids
const infoServiceUUID  = numberToUUID(0xABCD);
const mtuCharUUID = numberToUUID(0x1234); 

export class BleInfoService extends BleService {
    constructor(serviceUUID=infoServiceUUID, mtuUUID=mtuCharUUID){
        super(serviceUUID);
        this.mtuCharUUID = mtuCharUUID;
    }

    mtuChangeHandler(value)
    {
        let resp = [];
        for (let i = 0; i < value.byteLength; i++){
            resp.push(value.getUint8(i));
        }
        let mtu_size = struct.unpack("<h", Buffer.from(resp));
        console.log("mtu changed to " + mtu_size);
        this.mtu = mtu_size
        this.bleManager.updateMtuSize(mtu_size)
    }
    
    startNotifications() {        
        this.bleManager.driver.startNotifications(
            this.bleManager.device.deviceId,
            this.serviceUUID,
            this.mtuCharUUID,
            value => { this.mtuChangeHandler(value); },
        );
    }
    // override default
    async onConnect()
    {
        console.log("request mtu size");
        this.write();
    }

    async write(){
        this.bleManager.driver.write(this.bleManager.device.deviceId, this.serviceUUID, this.mtuCharUUID, numbersToDataView([1]));
    }
  
}
