'use strict'
import { FileElement, ProjectTree } from "./project_tree.js";

export class Project extends ProjectTree {
    constructor({ name="New Project", rootPath="/", isDevice=false }){
        super({rootPath: rootPath});
        this.name = name //ex; BOARD, RC_CAR_Kit ..etc
        this.github_tag_name = null; // we change this in projects.js
        this.github_repo_name = null; //  we change this in projects.js
        this.github_release_url = null; // we change this in project.js
        this.github_owner_name = null;
        this.currentFile = null;//new FileElement({});
        this.componentFile = null; //file used to compile kit Remote Control vue during runtime
        this.kitFile = null; //this is the kit python file (kind of like main.py) that user can edit to change behavior
        this.isDevice = isDevice;
        this.zipName = null; // for project that was imported from zip file
    }

    isDeviceProject()
    {
        return this.isDevice;
    }

    isGitHubProject()
    {
        return (this.github_tag_name != null || this.github_repo_name != null)
    }

    isZipProject()
    {
        return this.zipName != null;
    }

    storage_name(){
        // this is the getter for the storage tag of a project
        // since there could easily be a two github kits with same name (testing or different repos) we need a way to 
        // keep them in different places in the local file system when saving files to cache
        // same for projects imported from a zip file
        // so, the project name may not be the same as the storage_name
        // ex: project name = "TestKit", storage_name = "TestKit@commit12345", this keeps things unique (mostly)
        if(this.github_tag_name && !this.isDevice)
        {
            return this.github_release_url;
        }
        else if(this.zipName && !this.isDevice) 
        {
            return this.zipName;
        }
    }

}