//ble_repl_service.js
'use strict'

import { BleUartService } from "./ble_uart_service.js";

//repl uart ble uuids
const replServiceUUID  = '6e400001-b5a3-f393-e0a9-e50e24dcca9e';
const replRxCharUUID   = '6e400002-b5a3-f393-e0a9-e50e24dcca9e';
const replTxCharUUID   = '6e400003-b5a3-f393-e0a9-e50e24dcca9e';

export class BleUartReplService extends BleUartService {
    constructor(serviceUUID=replServiceUUID, txUUID=replTxCharUUID, rxUUID=replRxCharUUID){
        super(serviceUUID, txUUID, rxUUID);
    }
}

