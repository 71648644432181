import { Octokit } from "@octokit/core";
import { Base64 } from 'js-base64';
import { ReadFileResponse, GetChecksum } from "./helpers.js";
import _ from 'lodash'
import superagent from 'superagent'
import { RequestError } from "@octokit/request-error";

// TODO set access_token as environment variable
let access_token = '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'
let github_org = 'kitlab-io'

// https://github.com/kitlab-io/micropython/tree/main/api/jem

const CloudProjects = async function () {

    // TODO get all user projects on Github

    const getReadme = async function (url) {
        const parts = url.split('/');
        const owner = parts[3];
        const repo = parts[4];
        const branch = parts[6];
        const filePath = parts.slice(7).join('/');
    
        const readmeUrl = `https://api.github.com/repos/${owner}/${repo}/contents/${filePath}?ref=${branch}`;
    
        try {
            const response = await octokit.request('GET ' + readmeUrl);
            const content = Base64.decode(response.data.content);
            return content;
        } catch (error) {
            console.error('Error fetching README:', error);
            throw error;
        }
    }

    const fetchProjectListPublic = async function () {
        let projectsUnresolved = []

        let owner = 'kitlab-io'
        let repo = 'kits-public'

        let repoUrl = 'https://api.github.com/repos/' + owner + '/' + repo + '/contents/'

        let repoTopLevelItems = await superagent.get(repoUrl).then(res => {
            return res.body;
        })

        let projectPaths = []
        let projectNames = []

        _.each(repoTopLevelItems, async (item) => {
            if (item.type == 'dir') {
                projectNames.push(item.name)
                projectPaths.push(item)
                projectsUnresolved.push(resolveProjectItems(item.url))
            }
        })

        async function resolveProjectItems(projectContentsURL) {
            console.log('resolveProjectItems', projectContentsURL)

            let directories = []
            let directoriesUnresolved = []

            let repoItems = await superagent.get(projectContentsURL).then(res => {
                return res.body;
            })

            _.each(repoItems, (item) => {
                if (item.type == 'dir') { directories.push(item) }
                console.log(item)
            })

            _.each(directories, async (dirItem) => {
                directoriesUnresolved.push(resolveDirectoryItem(dirItem.url, directories, repoItems))
            })

            return await Promise.all(directoriesUnresolved).then(values => {
                console.log('all repo items resolved', JSON.stringify(repoItems))
                return repoItems
            })
        }

        async function resolveDirectoryItem(repoItemURL, directories, projectItems) {
            let directoryItems = await superagent.get(repoItemURL).then(res => {
                return res.body;
            })

            _.each(directoryItems, (item) => {
                if (item.type == 'dir') { directories.push(item) }
                projectItems.push(item)
                console.log(item)
            })

            return;
        }

        return await Promise.all(projectsUnresolved).then(projectItems => {
            let projects = []
            _.each(projectNames, (name) => {
                let items = projectItems[projectNames.indexOf(name)]
                projects.push({
                    name,
                    items
                })
            })
            return projects
        })
    }


    // Create a personal access token at https://github.com/settings/tokens/new?scopes=repo
    const octokit = new Octokit({ auth: access_token });

    const getPrivateRepos = async function (repoOwner) {
        const response = await octokit.request("GET /orgs/{org}/repos", {
            org: repoOwner,
            type: "private",
        });

        console.log(response)
    }


    // f4c422f
    let tree_sha = 'main'; // ex. jt/school-demo/kitfile
    // let repo = 'micropython'


    const getRepoReleases = async function (repo, owner) {
        return octokit.request('GET /repos/{owner}/{repo}/releases', {
            owner,
            repo,
            tree_sha: tree_sha
        });
    }

    const getProjectTree = async function (owner, repo, tree_sha) {
        console.log(`getProjectTree: https://github.com/${owner}/${repo}/tree/${tree_sha}`);

        const repoTreeRequest = await getRepoTree(owner, repo, tree_sha);
        console.log(repoTreeRequest)

        if (repoTreeRequest.status == 200) {
            return {
                success: true,
                tree: repoTreeRequest.data.tree
            }
        } else {
            return {
                success: false,
                error: repoTreeRequest
            } // REST GET error
        }
    }

    // https://docs.github.com/en/rest/reference/git#get-a-tree
    const getRepoTree = async function (owner, repo, tree_sha) {
        console.log(`getRepoTree`)
        try {
            // your code here that sends at least one Octokit request
            // await octokit.request("GET /");
            let repoTreeRequest = await octokit.request('GET /repos/{owner}/{repo}/git/trees/{tree_sha}?recursive=true', {
                owner, repo, tree_sha
            });

            return repoTreeRequest

        } catch (error) {
            console.error('getRepoTree error')
            console.log(error)
            // Octokit errors always have a `error.status` property which is the http response code
            if (error.status) {
                // handle Octokit error

            } else {
                // handle all other errors
                //   throw error;
            }

            return error
        }
    }

    // load a file from a Github repo branch snapshot
    const loadFile = async function (owner, repo, file_sha, file_name) {
        console.log(`loadFile: ${file_name}`);
        console.log(`GET /repos/${owner}/${repo}/git/blobs/${file_sha}`);
        // https://docs.github.com/en/rest/reference/git#get-a-blob

        // "https://api.github.com/repos/kitlab-io/micropython/git/blobs/63b4b681cb65bcf92db3d26bc3664a1298cbeea8"        
        // https://api.github.com/repos/kitlab-io/git/blobs/63b4b681cb65bcf92db3d26bc3664a1298cbeea8
        const blob = await octokit.request('GET /repos/{owner}/{repo}/git/blobs/{file_sha}', {
            owner: owner,
            repo: repo,
            file_sha: file_sha
        });
        const content = Base64.decode(blob.data.content);
        return new Promise((resolve) => {
            resolve(new ReadFileResponse({ name: file_name, checksum: GetChecksum(content), data: content }))
        });

    }

    const getProjectTreeFromRelease = async function (repo, owner, release_id) {
        console.log("getProjectTreeFromRelease: repo " + repo + " release " + release_id);
        const releaseAssets = await listReleaseAssets(repo, owner, release_id);
        console.log(releaseAssets)
        return releaseAssets;
    }

    // https://docs.github.com/en/rest/releases/assets?apiVersion=2022-11-28#list-release-assets
    const listReleaseAssets = function (repo, owner, release_id) {
        return octokit.request('GET /repos/{owner}/{repo}/releases/{release_id}/assets?per_page=100', {
            owner,
            repo,
            release_id
        });

        /*
        Example Assets:
        preview.gif
        Source code (zip)
        Source code (tar.gz)
        */
    }

    // load a file from a Github repo release
    // https://docs.github.com/en/rest/releases/assets?apiVersion=2022-11-28#get-a-release-asset
    // https://github.com/dsaltares/fetch-gh-release-asset/blob/master/index.ts
    const getReleaseAsset = async function (repo, asset_id) {
        // let asset = await octokit.request('GET /repos/{owner}/{repo}/releases/assets/{asset_id}', {
        //     owner: github_org,
        //     repo,
        //     asset_id
        // });

        const {
            body,
            headers: { accept, 'user-agent': userAgent },
            method,
            url,
        } = octokit.request.endpoint(
            'GET /repos/:owner/:repo/releases/assets/:asset_id',
            {
                asset_id,
                headers: {
                    accept: 'application/octet-stream',
                },
                owner: github_org,
                repo,
            }
        );
        let headers = {
            accept,
            authorization: `token ${access_token}`,
        };

        console.log(url, body, headers, method)

        const response = await fetch(url, { body, headers, method });

        // https://objects.githubusercontent.com/github-production-release-asset-2e65be/315162922/1a6b81df-d60d-4ad1-abdc-eecb48561970?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIWNJYAX4CSVEH53A%2F20230510%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20230510T232918Z&X-Amz-Expires=300&X-Amz-Signature=4ccc01c8d54324045e7ebc7eaf9d3e66adf47fe09df1f072453cba8f1bcc2329&X-Amz-SignedHeaders=host&actor_id=476333&key_id=0&repo_id=315162922&response-content-disposition=attachment%3B%20filename%3Dpreview.gif&response-content-type=application%2Foctet-stream
        console.log(response)
        if (!response.ok) {
            const text = await response.text();
            // core.warning(text);
            console.warn(text)
            throw new Error('Invalid response');
        }
        const blob = await response.blob();
        // const arrayBuffer = await blob.arrayBuffer();

        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                // Use a regex to remove data url part
                const base64String = reader.result
                    .replace('data:', '')
                    .replace(/^.+,/, '');

                console.log(base64String);
                // Logs wL2dvYWwgbW9yZ...

                resolve(base64String)
            };
            reader.readAsDataURL(blob);
        });

    }


    return {
        ...octokit,
        loadFile,
        fetchProjectListPublic,
        getProjectTree,
        getRepoReleases,
        getReleaseAsset,
        getReadme
    }
}

export default CloudProjects
