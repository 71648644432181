import CloudProjects from '../lib/cloud_projects'
import { BleClient } from '@capacitor-community/bluetooth-le';
import { MicropythonBoard } from '../lib/micropython_board.js'
import { MicropythonBoardProject } from '../lib/micropython_board_project.js'
import { RemoveNonAscii, GetChecksum, SleepMs } from '../lib/helpers.js';
import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";
import { Project } from '../lib/project/project.js';
import LocalStorage from "@/lib/localstorage";
import { ProjectStorageManager } from '../lib/project/project_storage_manager';
import logger from '../lib/logger'
import JSZip from 'jszip';
var pathLib = require("path"); // extra path stuff

const log = logger.log()
const debug = logger.debug()
// debug.storage(debug)

export const Device = new MicropythonBoard(BleClient);

const UseTerminal = true; //change to true if we want to show terminal (obvs)
const DeviceProject = new MicropythonBoardProject();
var Storage = null;
const term = new Terminal({
    //cols: 120,
    //rows: 10,
    useStyle: true,
    screenKeys: true,
    cursorBlink: true,
});
let terminalFitAddon = null;

// static functions
function _saveFileToStorage({filePath, fileData, project})
{
    if(project){
        let f = project.getFileFromTree(filePath);
        console.log("saveFileToStorage: %s for project: %s", filePath, project.name);
        console.log(f);
        return ProjectStorageManager.saveFile({storage: Storage, project: project, filePath: filePath, content: fileData});
    }
}

function _updateProjectDeviceRootPathIfConnected(project)
{
    if(Device.isConnected())
    {
        project.updateDeviceRootPath(DeviceProject.deviceRootPath);
    }
}

async function _extractZipFile(file)
{
    return file.async("string");
    /*then(function (data) {
        console.log("save zip file " + file.path);
        _saveFileToStorage({filePath: file.path, fileData: data, project: project});
    });*/
}


// initial state
const state = () => ({
  deviceProject: DeviceProject, // files as read from JEM board
  activeProject: DeviceProject, // local project currently edited
  githubProject: null,
  localProjects: [], // all projects cached on app device
  cloudProjects: [], // all projects 
  terminalInitialized: false,
  terminalConnected: false,
  deviceName: "",
  flashing:false,
  syncing: false,
  syncFilesTotal: 0,
  syncFilesLoaded: 0,
  loading: false,
  availableProjects: [DeviceProject],
  connectionType: null
})

// getters
const getters = {
    allProjects:  (state, getters, rootState) => {
        return [].concat(state.cloudProjects).concat(state.localProjects)
    },
}

// actions
const actions = {
    async initLocalStorage({state, commit}){
        if(Storage == null){
            Storage = await LocalStorage();
        }
    },

    async clearStorage({state, commit}){
        await Storage.reset();
    },

    async processZipProjectContent ({ state, commit, dispatch }, options) {
        // ex: tag_name = "main"; // branch
        // ex: repo_name = "micropython"
        let count = 1;
        let done = false;
        let zipContent = options.zipContent;
        console.log("zipContent: ");
        console.log(zipContent);
        while(!done)
        {
            done = true;
            for(let p in state.availableProjects)
            {
                let proj = state.availableProjects[p];
                if(proj.name == options.name && options.zipName == proj.zipName)
                {
                    project = proj;
                    debug.storage("zip proj " + proj.name + " already exists, update name count")
                    options.name = options.name + "-" + count;
                    options.zipName + "-" + count;
                    done = false; //update name, ex project2, and try again (make sure no project2 already exists)
                    break;
                }
            }
        }
        let project = new Project({name: options.name, rootPath: ""});
        project.zipName = options.zipName;
        console.log("new project: " + project.name);

        // --- unzip project files and store contents in local storage
        // For example, you can use JSZip to extract files or perform other operations
        // For instance, you can log the content to the console to verify it's working:
        console.log("got zip!");
        //console.log(zipContent);
        const zip = new JSZip();
        zip.loadAsync(zipContent).then(async (zipFiles) => {
            // Create a flat tree structure to hold all the files and directories
            console.log(zipFiles.files);
            let jsonFlatTree = [];
            for(let f in zipFiles.files)
            {
                console.log(zipFiles.files[f]);

                // update the element to support project_tree.js requirements
                let type = zipFiles.files[f].dir ? 'tree' : 'blob';
                let path = zipFiles.files[f].name;
                if(type == 'tree')
                {                    
                    if(path.length > 1 && path[path.length-1] == pathLib.sep)
                    {
                        path = path.substring(0,path.length-1);
                    }
                }

                // normalizes path by adding '/' (for example) if there isn't one
                // ex: drivers -> /drivers (must do this!)
                path = pathLib.join(pathLib.sep, path); 
                zipFiles.files[f].path = path;
                let projFile = {path: path, type: type};
                jsonFlatTree.push(projFile);
            }
            // update project with these new files
            console.log("jsonFlatTree");
            console.log(jsonFlatTree);
            project.updateTreeFromJson(jsonFlatTree);
            console.log("deviceFlatTree");
            console.log(project.getDeviceFlatTree());
            console.log(project);
            
            //only grab files or directories within the root dir where main.py resides
            for(let f in zipFiles.files) // now save the file content to local storage
            {
                let file = zipFiles.files[f];

                // make sure this file is within the main.py root dir
                // else don't save to local storage
                if(project.fileInDeviceTree(file.path))
                { 
                    let fileElement = project.getFileFromTree(file.path);
                    console.log("zip: found " + file.path +  " in dev tree");
                    let data = await _extractZipFile(file);
                    console.log("_extractZipFile " + file.path);
                    await _saveFileToStorage({filePath: file.path, fileData: data, project: project});
                    fileElement.setSynced(true);
                }
                else
                {
                    console.log("zip: could not find " + file.path +  " in dev tree");
                }
            }
            commit('setUpdateAvailableProjects', { project: project })
        });
            
        //commit('setProjectFromZip', { zipProject: project })
        //await dispatch('sync', {project: project})
    },

    async fetchGithubProject ({ state, commit, dispatch }, options) {
        // ex: tag_name = "main"; // branch
        // ex: repo_name = "micropython"
        
        let exists = false;
        let project = null;
        for(let p in state.availableProjects)
        {
            let proj = state.availableProjects[p];
            if(proj.name == options.name && options.repo_name == proj.github_repo_name && options.tag_name == proj.github_tag_name)
            {
                project = proj;
                debug.storage("fetchGithubProject proj " + proj.name + " already loaded")
                exists = true;
                break;
            }
        }
            
        if(!exists){
            // options from DeviceConnection.vue 
            // loadProject or loadCustomProject
            project = new Project({name: options.name, rootPath: ""});
            project.github_tag_name = options.tag_name;
            project.github_repo_name = options.repo_name;
            project.github_owner_name = options.owner_name
            project.github_release_url = options.release_url;
            debug.storage("new project: " + project.name);

            commit('setUpdateAvailableProjects', { project: project })
            
        } else {
            debug.storage("Project " + options.name + " already exists");
        }
        if(project)
        {
            commit('setGithubProjectFromCloud', { cloudProject: project })
            let projectLoaded = await dispatch('sync', {project: state.githubProject})

            return projectLoaded
        }

        return true
    },

    async fetchCloudProject ({ state, commit }, options) {
        let name = "micropython";
        if(options && options.name)
            name = options.name;
        let exists = false;
        for(let i=0; state.localProjects.length; i++){
            if(state.localProjects[i].name == name){
                exists = true;
                break;
            }
        }
        if(!exists){
            const cloudProjects = await CloudProjects()
            const resp = await cloudProjects.loadProject(name);
            commit('setProjectFromCloud', { cloudProject: resp.project })
        } else {
            debug.storage("Project " + name + " already exists");
        }
    },

    async updateActiveProject ({ state, commit, dispatch }, {project: project}) {
        debug.storage("updateActiveProject to " + project.name);
        if(project != state.deviceProject) //if this project is from github (not jem board) just reall all files
            await dispatch('sync', { project: project });
        commit('setActiveProject', { activeProject: project });
    },

    async updateActiveProjectByName ({ state, commit, dispatch }, projectName) {
        let project = null;
        if(projectName == state.deviceProject.name)
            project = state.deviceProject;
        else if(state.githubProject && projectName == state.githubProject.name){
            project = state.githubProject;
        }
        else {
            for(let i=0; state.localProjects.length; i++){
                if(state.localProjects[i].name == projectName){
                    project = state.localProjects[i];
                    break;
                }
            }
        }
        if(project)
            await dispatch('updateActiveProject', { project: project } );
    },

    async setActiveProjectByName ({ state, commit, dispatch }, projectName) {
        let project = null;
        for(let p in state.availableProjects)
        {
            if(projectName == state.availableProjects[p].name)
            {
                project = state.availableProjects[p];
            }
        }
        /*
        if(projectName == state.deviceProject.name)
            project = state.deviceProject;
        else if(state.githubProject && projectName == state.githubProject.name){
            project = state.githubProject;
        }
        else {
            for(let i=0; state.localProjects.length; i++){
                if(state.localProjects[i].name == projectName){
                    project = state.localProjects[i];
                    break;
                }
            }
        }*/

        if(project)
            commit('setActiveProject', { activeProject: project });
    },

    async setupTerminal ({state, commit}) {
        debug.storage("setupTerminal %s", UseTerminal);
        if(!state.terminalInitialized && UseTerminal){
            debug.storage("attempting to setup terminal")
            // https://xtermjs.org/docs/api/addons/fit/
            const fitAddon = new FitAddon();
            term.loadAddon(fitAddon);
            term.open(document.getElementById("term"));
            fitAddon.fit();
            terminalFitAddon = fitAddon; // expose fitAddon for resizing from any other component
            commit('setTerminalInitialized', { termInit: true });
        }
        else{
            debug.storage("Already initialized");
        }

        if(!state.terminalConnected && UseTerminal){
            term.onData((data) => {
                data = data.replace(/\n/g, "\r");
                let raw_data = new TextEncoder().encode(data);
                Device.send(raw_data);
            });

            term.focus();
            term.element.focus();
            term.write('\x1b[31mWelcome to MicroPython!\x1b[m\r\n');
            //Device.replService.updateTerminal = (text) => { term.write(text); };
            //Device.replSerial.updateTerminal = (text) => { term.write(text); };
            Device.replInterface.registerUpdateTerminalCallback((text) => { term.write(text); });
            commit('setTerminalConnected', { termConn: true });
        }

        return {
            terminal: term,
            terminalFitAddon
        }
    },
    
    async connectDevice({state, commit, dispatch}, { connectionType, args }){
        let connectionResult = await Device.connect(connectionType, args);  
        debug.storage(connectionResult)
        commit('setConnectedDeviceName', { connectionType: connectionType, deviceName: connectionResult.name });
        if(connectionResult.connected){
            await Device.pyboard.exitRawRepl();
        }
        return new Promise((resolve) => { resolve(connectionResult); });
    },

    async disconnectDevice({state, commit}, { connectionType }){
        await Device.disconnect(connectionType);
        return new Promise((resolve) => { resolve(true); });
    },

    /* Project specific methods - File Read / Write and Sync, Save, Delete */
    async loadFile({state, commit, dispatch}, {filePath}) {
        let fileElement = state.activeProject.getFileFromTree(filePath);
        if(fileElement && fileElement.isDir == false){
            commit('setLoadingCurrentFile', {loading: true});
            await SleepMs(50);
            let success = await dispatch('syncFile', {project: state.activeProject, fileElement: fileElement, force: false});
            commit('setLoadingCurrentFile', {loading: false});  
            if(success)
            {
                commit('setCurrentFile', { file: fileElement});
            }
        }else 
            debug.storage("loadFile ignore dir: " + filePath);
    },

    async closeCurrentFile({state, commit}, {project}) {
        if(project.currentFile){  
            commit('setCurrentFile', { file: null});
        }
        else 
        {
            console.warn("closeCurrentFile: no current file");
        }
    },
    async setKitFileToCurrentFile({state, commit, dispatch}) {
        let fileElement = state.activeProject.kitFile; 
        if(fileElement){
            await SleepMs(50);
            await dispatch('syncFile', {project: state.activeProject, fileElement: fileElement, force: false});
            commit('setCurrentFile', { file: fileElement});
        }else 
            debug.storage("loadFile failed, no kit file: " + fileElement);
    },

    async syncFile({state, commit, dispatch}, {project, fileElement, force=true}) {
        if(fileElement && fileElement.isDir == false){
            let fileCached = await dispatch('readFileFromStorage', {filePath: fileElement.filePath, project: project});
            if(!fileCached || force || !fileElement.synced){
                let filePath = fileElement.filePath;
                let resp = null;
                if(project.isDeviceProject())
                {
                    if(!Device.isConnected())
                    {
                        alert("syncFile failed - Not Connected");
                        return false;
                    }

                    resp = await Device.readFile(filePath);
                    if(!resp)
                    {
                        alert("syncFile failed for " + filePath);
                        return false;
                    }
                    else if(resp.checksum != GetChecksum(resp.fileData))
                    {
                        alert("syncFile checksum failed for " + filePath);
                        return false;
                    }

                }
                else if(project.isGitHubProject()){
                    const cloudProjects = await CloudProjects();
                    resp = await cloudProjects.loadFile(
                        project.github_owner_name,
                        project.github_repo_name,
                        fileElement.src.sha,
                        fileElement.name 
                        );               
                }
                else if(project.isZipProject())
                {
                    console.log("TODO: what do we do here?");
                }
                else 
                {
                    console.alert("syncFile failed, invalid project type (zip, github or device are valid)");
                    return false;
                }
                await dispatch('saveFileToStorage', {filePath: fileElement.filePath, fileData: resp.fileData, project: project});
                commit('setFileSynced', { file: fileElement, synced: true});
                commit('clearPendingNewName', {fileElement: fileElement});

            } else {
                debug.storage("syncFile " + fileElement.filePath + ", already cached, ignore");
            }
            return true;
      }
  },

    async writeFile({state, commit, dispatch}, {deviceFilePath, content, chunkSize}){
        //file object is of type FileElement, contains file content
        // let f = fileElement;
        debug.storage("writeFile: " + deviceFilePath);
        if(content==null || content.length == 0){
          debug.storage("action writeFile skipping " + deviceFilePath + ", no content");
          return true; // ok for now
        }
        let result = await Device.writeFile(deviceFilePath, RemoveNonAscii(content), chunkSize);
        if(result)
        {
            debug.storage("Flash file completed");
            return true;
        }
        else
        {
            //setTimeout(()=>{alert("write file checksum failed");}, 100);
            console.error("writeFile: " + deviceFilePath + " failed")
            return false;
        }
    },

    async renameFileOnDevice({state, commit, dispatch}, {oldFilePath, newFilePath}){
        // send command to connected jem device to rename existing file on board to new_file_path
        debug.storage("renameFileOnDevice " + oldFilePath + " to " + newFilePath);
        await Device.renameFile(oldFilePath, newFilePath);
        await SleepMs(2000); // need this or flash will be less reliable
    },

    async renameFileLocally({state, commit, dispatch}, {project, filePath, newName, isDir}){
        // rename the file on the app / project tree but don't update file name on connected device (yet)
        debug.storage("renameFileLocally " + filePath + " to " + newName);
        commit("setNewFileName", {project: project, filePath: filePath, newName: newName});
        if(isDir)
        {
            debug.storage("todo: not handle dir name change properly yet");
        }
        //TODO: handle directory name change
    },

    async addNewDirectory({state, commit, dispatch}, {dirPath})
    {
        debug.storage("addNewDirectory: " + dirPath);
        await Device.mkDir(dirPath); // TODO: verify dir created
    },

    async updateFileContent({state, commit, dispatch}, {fileElement, content, project}){
        if(project && fileElement){
            let currentContent = await dispatch('readFileFromStorage', {filePath: fileElement.filePath, project: project});
            if(currentContent.toString() != content.toString())
            {
                await dispatch('saveFileToStorage', {filePath: fileElement.filePath, fileData: content, project: project})
                commit("setFileDirty", {filePath: fileElement.filePath, project: project});
            }
        }
        else 
        {
            console.warn("updateFileContent failed for project %s and file %s", project, fileElement);
        }
    },

    async flashFile({state, commit, dispatch}, {project, fileElement, force = false}){
            debug.storage("flashFile " + fileElement.name);
            let content = null;
            let write = force; // if force == true we always write
            let success = true;
            let devFilePath = fileElement.deviceFilePath;

            if(fileElement.isDir) // we don't flash dir elements
                return success;

            content = await dispatch('readFileFromStorage', {filePath: fileElement.filePath, project: state.activeProject});
            
            if(!force) {
                debug.storage("deviceFilePath " + fileElement.deviceFilePath);
                let checksumMsg = await Device.readFileChecksum(fileElement.deviceFilePath);
                
                let checksum = GetChecksum(content);
                debug.storage("checksums: %s -> %s", checksumMsg.checksum, checksum);
                write = (checksumMsg.checksum != checksum);   
            }

            if(write){
                let attempts = 0; 
                do 
                {
                    let tempFilePath = project.generateFileRootPath(`flash_${fileElement.name}.tmp`);
                    debug.storage("tempFilePath " + tempFilePath);
                    let chunkSize = 1000; // if we failed once, use smaller chunks
                    if(attempts >= 1)
                    {
                        let mtuSize = Device.bleManager.mtuSize;
                        chunkSize = (mtuSize <= 100) ? mtuSize : mtuSize/2; 
                        debug.storage("chunkSize changed to %s bytes", chunkSize);
                    }
                    
                    success = await dispatch('writeFile', {deviceFilePath: tempFilePath, content: content, chunkSize: chunkSize});
                    if(!success)
                    {
                        console.error("(%d) write file %s failed", attempts, fileElement.name);
                        Device.send("import gc; gc.collect()\r\n");
                        await SleepMs(500);
                    }
                    else 
                    {
                        await dispatch('renameFileOnDevice', {oldFilePath: tempFilePath, newFilePath: devFilePath});                        
                        //TODO: we assume success, but need to add some verification to make sure file rename worked
                        success = true;                    
                    }
                    await SleepMs(500);
                } while(!success && (attempts++ < 3));

                if(success)
                {
                    debug.storage("write file success, TODO: set dirty = false");
                }
            }
            else
                debug.storage("skipping flash file: " + fileElement.filePath);

            if(fileElement.nameChanged())
            {
                await SleepMs(1000); // give some time for any previous cmds to finish just in case
                let newDevFilePath = fileElement.getNewDeviceFilePath();
                await dispatch('renameFileOnDevice', {oldFilePath: devFilePath, newFilePath: newDevFilePath});

                //remove old file local storage
                await ProjectStorageManager.removeSavedFile({storage: Storage, project: project, filePath: fileElement.filePath});
                
                // now update file element with new name and save content to local storage under new filePath
                commit('updateWithNewFilePath', {fileElement: fileElement});
                await dispatch('saveFileToStorage', {filePath: fileElement.filePath, fileData: content, project: project});
            }
            return success;
    },

    async removeFiles({state, commit, dispatch}, {pathsList}){
        debug.storage("removeFiles");
        return Device.removeFiles(pathsList);
    },

    async removeDirs({state, commit, dispatch}, {pathsList}){
        debug.storage("removeDirs");
        return Device.removeDirs(pathsList);
    },

    async flash({state, commit, dispatch}, {project: project}){
            debug.storage("flashing project " + project.name);
            commit('setFlashingState', {flashing: true});
            let flatTree = project.getDeviceFlatTree();
            let filesToRemove = project.filesToRemove;
            let total = flatTree.length + filesToRemove.length;
            let totalFlashed = 0;
            let dirsToNameChange = []; 

            dispatch("ide/updateDeviceStream", {progress: 0, bufferProgress: 100}, {root:true})
            for(let i in flatTree){
                if(flatTree[i].isDir)
                {
                    // make sure this directory exists in device first else make new one
                    let newDir = false;
                    if(flatTree[i].dirty)
                    {
                        newDir = true;
                    }
                    else 
                    {
                        // we use get file instead of get directory even if element if a dir
                        let devFlatTree = DeviceProject.getDeviceFlatTree()
                        newDir = true;
                        for(let f in devFlatTree)
                        {
                            let manFile = devFlatTree[f]; //manifest file
                            if(flatTree[i].deviceFilePath == manFile.filePath)
                            {
                                newDir = false; // file exists in device path, existing dir
                                break;
                            }
                            
                        }
                    }

                    if(newDir)
                    {
                        await dispatch('addNewDirectory', {dirPath: flatTree[i].deviceFilePath});
                        await SleepMs(50);
                    }
                    else if(flatTree[i].nameChanged())
                    {
                        dirsToNameChange.push(flatTree[i]);
                    }
                }
                else if(!flatTree[i].isDir)
                {
                    let file = flatTree[i]; //project.getFileFromTree(flatTree[i].filePath);
                    if(file.synced)
                    {
                        // if this is github project and file doesn't exist in device path then force flash'
                        let devFile = DeviceProject.getFileFromTree(file.deviceFilePath);
                        let forceFlash = file.dirty;
                        if(!devFile)
                        {
                            debug.storage("file " + file.deviceFilePath + " not found in device, forceFlash");
                            forceFlash = true;
                        }
                        let flashSuccess = await dispatch('flashFile', {project: project, fileElement: file, force: forceFlash});
                        if(!flashSuccess)
                        {
                            commit('setFlashingState', {flashing: false});
                            await dispatch("ide/closeDeviceStream" , {}, { root: true });
                            setTimeout(()=>{alert("Flash Failed");}, 100);
                            return;
                        }
                    }
                    else // file not synced, but might have been a name change in file explorer
                    {
                        if(project == DeviceProject && file.nameChanged()){
                            // user changed name of file on device but didn't sync the original file
                            // so we just need to rename the file on device, don't need to flash any content
                            
                            let devFilePath = file.deviceFilePath;
                            let newDevFilePath = file.getNewDeviceFilePath();
                            await dispatch('renameFileOnDevice', {oldFilePath: devFilePath, newFilePath: newDevFilePath});
                            commit('updateWithNewFilePath', {fileElement: file});
                            await SleepMs(100);
                        }
                        else if(file.nameChanged())
                        {
                            console.error("can't rename unsynced github file %s to device", file.name);
                        }
                    }
                }
                totalFlashed += 1;
                dispatch("ide/updateDeviceStream", {progress: (totalFlashed*100)/total, bufferProgress: 100}, {root:true})
            }
            await SleepMs(100);
            let paths = [];
            let dirs = [];

            // handle any directories set to have name change
            for(let i in dirsToNameChange)
            {
                let dir = dirsToNameChange[i];
                console.log("dir %s name changed", dir.filePath);
                await dispatch('renameFileOnDevice', {oldFilePath: dir.deviceFilePath, newFilePath: dir.getNewDeviceFilePath()});
                commit('updateWithNewFilePath', {fileElement: dir});
            }

            // handle any files set to be removed
            // always do this last, in case a dir / file had name changed 
            // but then was removed by user in same session
            for(let i in filesToRemove){
                if(!filesToRemove[i].isDir)
                    paths.push(filesToRemove[i].deviceFilePath);
                else
                    dirs.push(filesToRemove[i].deviceFilePath);
            }

            await dispatch('removeFiles', {pathsList: paths});
            totalFlashed += paths.length;
            dispatch("ide/updateDeviceStream", {progress: (totalFlashed*100)/total, bufferProgress: 100}, {root:true})
            await dispatch('removeDirs', {pathsList: dirs});
            totalFlashed += dirs.length;
            await dispatch("ide/updateDeviceStream", {progress: 100, bufferProgress: 100}, {root:true})
            commit("clearProjectRemoveFilesList", {project: project});
            commit('setFlashingState', {flashing: false});
            await dispatch("ide/closeDeviceStream" , {}, { root: true });
            await dispatch('rebootDevice');
    },

    async rebootDevice({state, commit, dispatch}){
        await Device.pyboard.reboot();
        SleepMs(4000);
        return new Promise((resolve) => { resolve(true); });
    },

    async reconnectDevice({state, commit, dispatch}){
        let connectionType = state.connectionType;
        await dispatch('disconnectDevice', {connectionType: connectionType});
        SleepMs(1000);
        await dispatch('connectDevice', {connectionType: connectionType, args: null});
        SleepMs(500);
        await dispatch('updateProjectTree', {project: state.deviceProject});
        return new Promise((resolve) => { resolve(true); });
    },


    async sync({state, commit, dispatch}, {project: project}){
        debug.storage("sync project " + project.name);
        let success = false;
        if(project){
            commit("setSyncingState", {syncing: true});
            let startProg = 5; //5%
            dispatch("ide/updateDeviceStream", {progress: startProg, bufferProgress: 100}, {root:true})
            
            let projectTreeLoaded = await dispatch('updateProjectTree', {project: project});

            if (!projectTreeLoaded) {
                return false
            }

            let flatTree = project.getFlatTree();
            let total = flatTree.length;
            for(let i in flatTree){
                if(flatTree[i].isDir)
                {
                    continue; // only sync files
                }
                let fileElement = flatTree[i];
                debug.storage("sync file: " + fileElement.filePath);
                success = await dispatch('syncFile', {project: project, fileElement: fileElement});
                if(!success)
                {
                    break;
                }
                dispatch("ide/updateDeviceStream", {progress: startProg + (i*95)/total, bufferProgress: 100}, {root:true})
            }
            dispatch("ide/updateDeviceStream", {progress: 100, bufferProgress: 100}, {root:true})
            commit("setSyncingState", {syncing: false});

            return success;
        }

        return success;
    },

    async updateProjectTree({ state, commit, dispatch }, {project}){
        debug.storage("updateProjectTree: " + project.name);
        let treeJson = null;
        if(project == state.deviceProject){
            let rootPath = state.deviceProject.rootPath; // ex: "/flash"
            let listDirResp = await Device.getDirList(rootPath);
            treeJson = listDirResp; //JSON.parse(listDirResp); //convert to json
            debug.storage("treeJson: " + treeJson);
        } else if(project == state.githubProject){
            const cloudProjects = await CloudProjects();
            let treeJsonRequest = await cloudProjects.getProjectTree(
                project.github_owner_name,
                project.github_repo_name, 
                project.github_tag_name
                );
            debug.storage("treeJson: " + treeJsonRequest);

            if (treeJsonRequest.success) {
                treeJson = treeJsonRequest.tree
            } else {
                treeJson = null

                return false
            }
        }

        if(treeJson){
            await commit('setProjectTree', { project: project, treeJson: treeJson });
            if(!project.isDevice) // device project already has root path of itself
            {
                await commit('updateProjectDeviceRootPathIfConnected', {project: project});
            }

            // TODO get docs media
            let docsFiles =  await dispatch('findDocs', {project: project});
            debug.storage('>>>> DOCS FOUND: >>>>>')
            debug.storage(docsFiles)
            // await commit('setDocs', {project: project, vueFile: kitFiles['vue'], pyFile: kitFiles['py']});

            let kitFiles = await dispatch('findKit', {project: project});

            if(kitFiles['vue']){
                await dispatch('syncFile', {project: project, fileElement: kitFiles['vue'], force: true});
            }

            if(kitFiles['vue'] || kitFiles['py'])
            {
                await commit('setKit', {project: project, vueFile: kitFiles['vue'], pyFile: kitFiles['py']});
            }
            
            if(project.kitFile){
                //await dispatch('syncFile', {project: project, fileElement: project.componentFile, force: true});
                await dispatch('loadFile', {filePath: project.kitFile.filePath});
            }

            return true
        }
    },

    async updateDeviceTreeAfterConnected({ state, commit, dispatch }){
        debug.storage("updateDeviceTreeAfterConnected");
        await dispatch('updateProjectTree', {project: state.deviceProject});
        await dispatch('updateActiveProjectByName', state.deviceProject.name);
        // update all kit projects with device root path (so they can flash to device)
        commit('updateAvailableGithubProjectsWithDeviceRootPath');
    },

    async removeFileFromProject({state, commit, dispatch}, {project, path}){
        debug.storage("removeFileFromProject: ", path);
        await ProjectStorageManager.removeSavedFile({storage: Storage, project: project, filePath: path});
        commit('setRemoveFileFromProject', { project: project, path: path });
    },

    async addFileElementToProject({state, commit, dispatch}, {project, dirPath, itemName, isDir}){
        debug.storage("addFileElementToProject: %s, path: %s", itemName, dirPath);
        if(project)
        {
            commit('setAddFileElementToProject', { project: project, dirPath: dirPath, itemName: itemName, isDir: isDir});
        }
    },

    async saveFileToStorage({state, commit}, {filePath, fileData, project}){
        _saveFileToStorage({filePath: filePath, fileData: fileData, project: project});
    },

    async readFileFromStorage(state, {filePath, project}){
        let value = null;
        if(project){
            debug.storage("readFileFromStorage: " + filePath);
            value = await ProjectStorageManager.getSavedFile({storage: Storage, project: project, filePath: filePath});
        }
        else 
        {
            console.error("readFileFromStorage no valid project");
        }
        return value;
    },

    async getRepoReleases(state){
        const cloudProjects = await CloudProjects()
        const resp = await cloudProjects.getRepoReleases('micropython', 'kitlab-io');
        let releases = resp.data;
        return releases;
    },

    async getRepoReleaseAsset(state, assetId){
        debug.storage(assetId)
        const cloudProjects = await CloudProjects()
        const resp = await cloudProjects.getReleaseAsset('micropython', assetId)
        debug.storage(resp)
        // const resp = await cloudProjects.getRepoReleases('micropython');
        // let releases = resp.data;
        // return releases;
    },

    async findKit({ state, commit, dispatch }, { project }){
        debug.storage("findKit");
        let kitFiles = project.searchForFilesByType("kit");
        let kitFileList = {'img': null, 'py': null, 'vue': null};
        if(kitFiles.length){
            //if exists then set kit name variable - will be used when looking for component.vue
            //let kitConfigfileName = kitFiles[0].name; // ex: config.kit
            // read the contents ofo config.kit to get the kit name ex: 'window.kit'

            let kitConfigFile = project.getFileFromTree(kitFiles[0].filePath);
            debug.storage("kitConfigFile " + kitConfigFile.name);
            await dispatch('syncFile', {project: project, fileElement: kitConfigFile, force: true});
            let content = await dispatch('readFileFromStorage',{filePath: kitConfigFile.filePath, project: project});
            debug.storage("!kit content: " + content);
            let kitConfigJson = JSON.parse(content); // ex: {'kit' : {'name' : 'simplebot'} }

            let kitName = kitConfigJson['kit']['name'];//kitConfigFileName.split(".kit")[0];
            project.kitName = kitName;
            let vueFiles = project.searchForFilesByName(kitName + ".vue");
            let pyFiles = project.searchForFilesByName(kitName + ".py");
            kitFileList['vue'] = vueFiles[0];
            kitFileList['py'] = pyFiles[0];
        }
        return kitFileList;
    },

    async findDocs({ state, commit, dispatch }, { project }){
        debug.storage("findDocs");

        // let kitFiles = project.searchForFilesByType("kit");
        let docsFiles = project.getFilesInFolder("docs");
        let docsFileList = {
            'img': null, 
            'py': null
        };
        // if(kitFiles.length){
        //     //if exists then set kit name variable - will be used when looking for component.vue
        //     //let kitConfigfileName = kitFiles[0].name; // ex: config.kit
        //     // read the contents ofo config.kit to get the kit name ex: 'window.kit'

        //     let kitConfigFile = project.getFileFromTree(kitFiles[0].filePath);
        //     debug.storage("kitConfigFile " + kitConfigFile.name);
        //     await dispatch('syncFile', {project: project, fileElement: kitConfigFile, force: true});
        //     let content = await dispatch('readFileFromStorage',{filePath: kitConfigFile.filePath, project: project});
        //     debug.storage("!kit content: " + content);
        //     let kitConfigJson = JSON.parse(content); // ex: {'kit' : {'name' : 'simplebot'} }

        //     let kitName = kitConfigJson['kit']['name'];//kitConfigFileName.split(".kit")[0];
        //     project.kitName = kitName;
        //     let vueFiles = project.searchForFilesByName(kitName + ".vue");
        //     let pyFiles = project.searchForFilesByName(kitName + ".py");
        //     kitFileList['vue'] = vueFiles[0];
        //     kitFileList['py'] = pyFiles[0];
        // }
        return docsFileList;
    }
}

// mutations
const mutations = {
    updateWithNewFilePath(state, {fileElement})
    {
        debug.storage("updateWithNewFilePath for " + fileElement.name);
        fileElement.updateWithNewFilePath();
    },

    clearPendingNewName(state, {fileElement})
    {
        debug.storage("clearPendingNewName for " + fileElement.name);
        fileElement.clearPendingNewName();
    },

    setNewFileName(state, {project, filePath, newName})
    {
        let fileElement = project.getFileFromTree(filePath);
        fileElement.setNewName(newName);
    },

    setLoadingCurrentFile(state, {loading}){
        state.loading = loading;
    },

    setAddFileElementToProject(state, {project, dirPath, itemName, isDir}){
        let fileElement = null;
        if(project)
        {
            fileElement = project.addFileElement({dirPath: dirPath, itemName: itemName, isDir: isDir});
            console.log("setAddFileElementToProject ", fileElement.filePath);
            if(!isDir)
            {   // only save files to local storage, not folders
                _saveFileToStorage({filePath: fileElement.filePath, fileData: "# " + itemName, project: project});
            }
            // we do this so that function 'syncFile' doesn't try to do fresh read from github or device
            // when we click on the file created later on
            fileElement.setSynced(true);
        }
    },

    clearProjectRemoveFilesList(state, {project}){
        if(project)
            project.filesToRemove = [];
    },

    setRemoveFileFromProject(state, {project, path}){
        project.removeFile(path);
    },

    setTerminalInitialized(state, { termInit }){
        state.terminalInitialized = termInit;
    },
    setFlashingState(state, { flashing }){
        state.flashing = flashing;
    },
    setSyncingState(state, { syncing }){
        state.syncing = syncing;
    },
    setTerminalConnected(state, { termConn }){
        state.terminalConnected = termConn;
    },
    setConnectedDeviceName(state, { connectionType, deviceName }){
        state.deviceName = deviceName;
        state.connectionType = connectionType;
    },
    setProjectFromCloud  (state, { cloudProject }){
        state.localProjects.push(cloudProject);
    },
    setGithubProjectFromCloud  (state, { cloudProject }){
        state.githubProject = cloudProject;
    },

    setUpdateAvailableProjects (state, { project} )
    {
        state.availableProjects.push(project);
        debug.storage("!!!josh")
        for(let p in state.availableProjects)
        {
            debug.storage(state.availableProjects[p].name)
        }
    },

    setCloudProjectList  (state, { cloudProjects }){
        state.cloudProjects = cloudProjects
    },
    setActiveProject (state, { activeProject }){
        console.log("active project = " + activeProject.name);
        state.activeProject = activeProject
    },

    setCurrentFile(state, {file}){
        state.activeProject.currentFile = file;
    },

    setFileSynced(state, {file, synced}){
        if(file)
            file.synced = synced;
    },
    setFileDirty(state, {filePath, project}){
        if(project){
            let fileElement = project.getFileFromTree(filePath);
            if(fileElement)
                fileElement.dirty = true;
        }
    },

    setProjectTree(state, { project, treeJson }){
        debug.storage("setProjectTree");
        project.updateTreeFromJson(treeJson);
    },

    async setKit(state, { project, vueFile, pyFile }){
            debug.storage("setKit");
            debug.storage("set componentFile to " + vueFile.filePath);
            let componentVue = project.getFileFromTree(vueFile.filePath);
            if(componentVue)
                project.componentFile = componentVue;
    
            
            debug.storage("pyfile: " + pyFile.filePath)
            let fileElement = project.getFileFromTree(pyFile.filePath);
            if(fileElement)
                project.kitFile = fileElement;
    },

    updateAvailableGithubProjectsWithDeviceRootPath(state)
    {
        for(let p in state.availableProjects)
        {
            debug.storage("update " + state.availableProjects[p].name + " dev root to " + DeviceProject.deviceRootPath);
            _updateProjectDeviceRootPathIfConnected(state.availableProjects[p]);
        }
    },

    updateProjectDeviceRootPathIfConnected(state, {project})
    {
        debug.storage("update " + project.name + " dev root to " + DeviceProject.deviceRootPath);
        _updateProjectDeviceRootPathIfConnected(project);
    }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}