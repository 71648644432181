//ble_uart_service.js
'use strict'
import { Mutex } from "../mutex.js";
import { BleService } from "./ble_service.js";

export class BleUartService extends BleService {
    constructor(serviceUUID, txCharUUID, rxCharUUID){
        super(serviceUUID);
        this.txCharUUID = txCharUUID;
        this.rxCharUUID = rxCharUUID;
        this.saveResp = false;
        this.mutex = new Mutex();
        this.encoder = new TextEncoder();
        this.decoder = new TextDecoder();
        this.debug = false;

        //private
        this._eotReceived = false;
        this._savedResp = []; // this gets set by ble service setSavedResp
        this.rxCallback = null; // call registerRxCallback to fill this
    }

    /************** Serial Interface Required Functions ***************/
    // whoever is using this uart serial expects these methods
    registerRxCallback(callback)
    {
        this.rxCallback = callback;
    }

    write(b){ // write bytes, don't wait for resp
        return this.mutex.synchronize(() => {return this._write(b);});
    }

    async _write(b,){ // write bytes, don't wait for resp
        if(!this.isConnected()){
            console.error("BleService._send: failed, not connected");
            return false;
        }
        
        while(b.length > 0){
            await this.sendBytes(b.slice(0,this.mtu));
            b = b.slice(this.mtu);
        }
        return true;
    }

    /************** End Serial Interface Required Functions ***********/

    ping(){
        //use to verify we can talk to 
        return this.sendBytes(new Uint8Array([0]));
    }

    rxHandler(value) {
        //value is a type DataView
        let resp = [];
        for (let i = 0; i < value.byteLength; i++){
            resp.push(value.getUint8(i));
        }
        
        if(this.rxCallback)
        {
            this.rxCallback(resp);
        }
        else  //deprecated
        {
            this.respCallback(resp); // use this to save resp in whatever service inhertics from ble_uart_service
        }
    }

    respCallback(resp){
        // override this 
        // for ex in ble repl or ft service we do stuff with resp that sets this.eot
        console.warn("respCallback not implemented in this service");
    }

    decodeRawResp(resp){
        //resp is assumed to be an array like [0x04, 0x33, 0x68] ... etc
        return this.decoder.decode(new Uint8Array(resp));
    }

    setSavedResp(resp)
    {
        this._savedResp = resp;
    }

    getSavedResp()
    {
        return this._savedResp;
    }

    clearWaitForResp() {
        this.saveResp = false;
        this._savedResp = [];
        this._eotReceived = false;
        this.clearResp();
    }

    clearResp()
    {
        // override this in child class
        // ex: self.resp = []
    }

    isConnected()
    {
        //NOTE: can override this
        return this.bleManager.device != null && this.bleManager.connected;
    }

    async _send(b, saveResp=false, timeoutMs=500){
        if(!this.isConnected()){
            console.error("BleService._send: failed, not connected");
            return new Promise((resolve) => { resolve(false);});
        }
        if(saveResp)
        {
            this.clearResp(); // implement this in the child class
        }
        this.saveResp = saveResp;
        var resp = true;
        while(b.length > 0){
            await this.sendBytes(b.slice(0,this.mtu));
            b = b.slice(this.mtu);
        }
        if(this.saveResp)
            resp = await this.waitForResp(timeoutMs);
        return new Promise((resolve) => { 
            console.log("resolved");
            resolve(resp); 
        });
    }

    send(b, saveResp=false, timeoutMs=500){
        return this.mutex.synchronize(() => {return this._send(b, saveResp, timeoutMs);});
    }

    signalWaitForRespCompleted()
    {
        // call this in respCallback if resp received inicates we are done sending
        // or we received something of note
        this._eotReceived = true; // end of transmission
    }

    responseReceived()
    {
        return this._eotReceived;
    }

    waitForResp(timeoutMs=500){
        // this function starts a timer that polls the this.resp buffer
        // this.resp is typicall filled in by the rxHandler -> respCallback function
        // once signalWaitForRespCompleted is set we are done
        return new Promise((resolve) => {
            try {
            var timeoutID = null;
            var intervalID = setInterval(() => {
                if(!this.saveResp || (this.saveResp && this.responseReceived())){
                    clearInterval(intervalID);
                    if(timeoutID != null)
                        clearTimeout(timeoutID);
                    if(this.saveResp){
                        let resp = this.getSavedResp(); //this.decodeRawResp(this.resp)
                        this.clearWaitForResp(); //clear settings that were set when waitForResp started
                        resolve(resp); // returns with response
                    }
                    else { // TODO: does this every happen? waitForResp if this.saveResp == false
                        resolve(true); // return with true if not saving resp
                    }
                }
            }, 2);
            timeoutID = setTimeout(()=>{
                console.log("BleService.waitForResp: Timeout")
                clearInterval(intervalID); 
                this.clearWaitForResp();
                resolve(false);
            }, timeoutMs);
        }catch(e){
            console.error("waitForResp failed " + e);
        }
        });

    }

    sendBytes(bytes) {
        //bytes is uint8 array
        if(this.debug)
            console.log("BleService.sendBytes: " + Buffer.from(bytes).toString());
        return this.bleManager.driver.write(
                this.bleManager.device.deviceId,
                this.serviceUUID,
                this.rxCharUUID,
                new DataView(bytes.buffer),
            );
    }

    startNotifications() {
        this.bleManager.driver.startNotifications(
            this.bleManager.device.deviceId,
            this.serviceUUID,
            this.txCharUUID,
            value => { this.rxHandler(value); },
        );
    }
}