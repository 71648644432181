<template>
    <v-container fluid>
        <v-btn icon color="green" v-on:click="reloadKitVue">
          <v-icon>mdi-cached</v-icon>
        </v-btn>
        <component v-bind:is="currentTabComponent" :parent="parent"></component>
    </v-container>
</template>

<script>
import Vuetify from 'vuetify'
import { Device } from '../store/projects.js';
import Vue from 'vue';
import { mapState } from 'vuex'
import DefaultKit from "@/components/DefaultKit";
const compiler = require('vue-template-compiler');
import { Motion } from '@capacitor/motion';

export default {
  
  computed: {

    parent() { // this is what we pass to child component so it can use our funcs and objects
        return this;
    },

    currentTabComponent: function () {
      try
      {
        console.log("currentTabComponent");
      Vue.use(Vuetify);
      //if not kit from jem available then load default kit
      if(this.kitComponent == null){
        Vue.component('DefaultKit', DefaultKit);
        return DefaultKit;
      }

      //else, use the kit vue file saved on jem board
      const componentScriptFunction = new Function(this.kitComponent.script.content);
        const scriptContent = componentScriptFunction();
      const appComponent = Object.assign({ template: this.kitComponent.template.content }, scriptContent);

      /*const appComponent = Object.assign(
        { template: this.kitComponent.template.content},
        eval(this.kitComponent.script.content)
      );*/
      
      Vue.component('Kit', appComponent);
      console.log("appComponent: " + appComponent);
      return appComponent;
      }catch(e)
      {
        console.error("currentTabComponent: " + e);
        return null;
      }
      
    },
    ...mapState({
      componentFile: state => state.projects.activeProject.componentFile,
      activeProject: state => state.projects.activeProject
    })
  },
  data() {
    return {
      name: "Remote Control",
      device: Device,
      kitComponent: null,
      rcResponse: "test",
      rcAuxResponse: "test",
      accelHandler: null, //PluginListenerHandle,
    };
  },
  methods: {
    reloadKitVue()
    {
      // reload the kit vue file manually, for example if you made changes live
      // you can view them without re-flashing the code to JEM
      console.log("reload component");
      this.loadComponent();
    },

    remoteControlResponse(resp){
      let decode = new TextDecoder().decode(resp);
      console.log("RemoteControl got: " + decode);
      this.rcResponse = decode;
    },

    remoteControlAuxResponse(resp){
      let decode = new TextDecoder().decode(resp);
      console.log("RemoteControlAux got: " + decode);
      this.rcAuxResponse = decode;
    },

    loadComponent(){
      if(this.activeProject && this.componentFile){
      this.$store.dispatch('projects/readFileFromStorage',{filePath: this.componentFile.filePath, project: this.activeProject}).then(
        (content) => {
          content = this.replaceExportWithReturn(content);
          console.log(content);
          this.kitComponent = compiler.parseComponent(`${content}`);
          console.log("kitComponent: " + this.kitComponent);
          });
      }
    },

    replaceExportWithReturn(fileContent) {
      // Define the pattern to search for
      const searchPattern = /module\.exports\s*=\s*\{/g;

      // Replace the pattern with the desired string
      const modifiedContent = fileContent.replace(searchPattern, 'return {');

      return modifiedContent;
    },

    // motion plugin support
    // user provides func callback when motion data is available
    // ex: event.accelerationIncludingGravity.x, event.accelerationIncludingGravity.y, event.accelerationIncludingGravity.z
    //     event.interval (period of motion data in seconds ... ex: 0.016 sec)
    startMotion: async function(motionHandler){
      console.log("startMotion")
      try {
      await DeviceMotionEvent.requestPermission();
      } catch (e) {
        // Handle error
        alert("no motion support");
        return;
      }

      // Once the user approves, can start listening:
      this.accelHandler = await Motion.addListener('accel', event => {
          motionHandler(event);
        });
      },

    // Stop the acceleration listener
    stopMotion: function(){
      console.log("stopMotion");
      if (this.accelHandler) {
        this.accelHandler.remove();
        this.accelHandler = null;
      }
    }, 
  },
  mounted() {
    //this.device.rcService.handleResp = this.remoteControlResponse;
    //this.device.rcService.handleAuxResp = this.remoteControlAuxResponse;
    this.loadComponent();
  },
  watch: {
    async componentFile(file){
      console.log("Editor.componentFile changed " + file);
      if(file){
        this.loadComponent();
      }
    }
  },
};
</script>