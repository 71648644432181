import amplitude from 'amplitude-js';

/*
Event Taxonomy at https://docs.google.com/spreadsheets/d/16zSPcY2a1vmxzz1-JwK7GgXjscKakhQkkgr37OdxLAY/edit#gid=0
*/

export default {
  install: (Vue, { apiKey, userId, config }) => {
    amplitude.getInstance().init(apiKey, userId, config);

    // eslint-disable-next-line
    Vue.prototype.$amplitude = amplitude;

    const monitor = {
        track: (eventName, eventProperties) => {
          console.log("track: "+eventName)
          console.log(eventProperties)
          amplitude.getInstance().logEvent(eventName, eventProperties);
        }
    }

    const track = {
      MonitorStart: () => {
        monitor.track("MonitorStart",{})
      },
      AppLaunch: () => {
        monitor.track("AppLaunch",{})
      },
      ViewJEMProductPage: () => {
        monitor.track("ViewJEMProductPage",{})
      },
      ChoseHasJEM: (hasJEM) => {
        monitor.track("ChoseHasJEM",{ "User Has JEM": hasJEM })  
      },
      ViewJEMConnections: () => {
        monitor.track("ViewJEMConnections",{})
      },
      JEMPairingSearch: () => {
        monitor.track("JEMPairingSearch",{})
      },
      JEMDevicesFound: () => {
        monitor.track("JEMDevicesFound",{})
      },
      JEMConnectStart: () => {
        monitor.track("JEMConnectStart",{})
      },
      JEMConnectSuccess: () => {
        monitor.track("JEMConnectSuccess",{})
      },
      JEMConnectFailed: () => {
        monitor.track("JEMConnectFailed",{})
      },
      JEMConnectCanceled: () => {
        monitor.track("JEMConnectCanceled",{})
      },
      JEMDisconnectByUser: () => {
        monitor.track("JEMDisconnectByUser",{})
      },
      JEMDisconnectSilent: () => {
        monitor.track("JEMDisconnectSilent",{})
      },
      JEMReboot: () => {
        monitor.track("JEMReboot",{})
      },
      ViewCodeEditor: () => {
        monitor.track("ViewCodeEditor",{})
      },
      ViewConsole: () => {
        monitor.track("ViewREPL",{})
      },
      ViewRemoteControl: () => {
        monitor.track("ViewRemoteControl",{})
      },
      SendRPC: () => {
        monitor.track("SendRPC",{})
      },
      FlashCodeToJEM: (info) => {
        monitor.track("FlashCodeToJEM",{ ...info })
      },
      ViewFeatureImplementation: () => {
        monitor.track("ViewFeatureImplementation",{})
      },
      DownloadAllCloudProjects: () => {
        monitor.track("DownloadCloudProjects",{})
      },
      DownloadCloudProject: () => {
        monitor.track("DownloadCloudProject",{})
      },
      UploadProject: () => {
        monitor.track("UploadProject",{})
      },
      ShareProject: () => {
        monitor.track("ShareProject",{})
      },
      // : () => {
      //   monitor.track("",{})
      // },
    }

    Vue.prototype.$monitor = monitor;
    Vue.prototype.$track = track;

    // const event = "Monitor Start";
    // amplitude.getInstance().logEvent(event);
    track.MonitorStart();

  },
};