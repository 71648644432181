<template>
  <div id="editor">
    <v-btn icon v-on:click="navigation.shown = !navigation.shown" center>
      <v-icon left>{{ navigation.shown ? 'mdi-folder-open' : 'mdi-folder-open-outline' }}</v-icon>
    </v-btn>
    <v-btn v-if="featureFlags.zipImportExport" @click="exportProject">Export Project</v-btn>
    <v-btn v-if="featureFlags.zipImportExport" class="ma-2" color="red" dark @click="showImportProjectTool = true">
      Import Project

      <v-dialog v-model="showImportProjectTool" width="auto">
        <v-card>
          <v-card-text>
            Import JEM Project from Zip File
          </v-card-text>
          <v-card-actions>
            <!--<input type="file" @change="handleFileUpload"/>-->
            <v-file-input @change="handleFileUpload" accept=".zip"></v-file-input>
            <v-btn @click="unzipFiles" :disabled="!zipFile">Unzip</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-btn>

    <v-navigation-drawer id="file-explorer-drawer" ref="drawer" app left hide-overlay :width="navigation.width"
      v-model="navigation.shown" handleColor="white">
      <v-select label="Select" v-model="selectedProject" :items="items" return-object single-line></v-select>
      <FileExplorer />
    </v-navigation-drawer>

    <v-dialog v-model="isFlashing" hide-overlay persistent width="300">
      <v-card class="text-h5" dark>
        <v-card-text>
          Flashing
          <v-progress-linear color="amber" class="mb-0" :value="deviceStreamProgress"
            :buffer-value="deviceStreamBufferProgress" stream></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loadingFile" hide-overlay persistent width="300">
      <v-card class="text-h5" dark>
        <v-card-text>
          Loading File
          <v-progress-linear color="amber" class="mb-0" indeterminate></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isSyncing" hide-overlay persistent width="300">
      <v-card class="text-h5" dark>
        <v-card-text>
          Syncing Project
          <v-progress-linear color="amber" class="mb-0" :value="deviceStreamProgress"
            :buffer-value="deviceStreamBufferProgress" stream></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-navigation-drawer width="auto" v-model="drawer" temporary absolute right>
      <v-divider></v-divider>
    </v-navigation-drawer>

    
    <v-card>
      <v-tabs v-model="tab" bg-color="red-lighten-2">
        <v-tab v-for="t in tabs" :key="t.file.name" :value="t" @click="tabSelected(t)" append-icon="mdi-close-box">
          {{ t.file.name }}
          <v-icon v-if="tabs.length >= 1" @click="closeTab(t)">
            mdi-close-box
          </v-icon>
        </v-tab>
      </v-tabs>
    </v-card>
    <codemirror ref="cmEditor" :value="code" :options="cmOptions" @focus="onCmFocus" @refresh="onCmRefresh"
      @changes="onCmChanges" @update="onCmUpdate" @input="onCmCodeChange" />
  </div>
</template>

<style>
#editor {
  /* background: black; */
}

.v-tab {
  text-transform: none !important;
}

.CodeMirror {
  height: auto !important;
}

/* display notch showing */
#app-container.display-notch #file-explorer-drawer {
  padding-top: 44px;
  /* height: auto !important; */
}

/* add space at bottom of nav drawer to let buttons appear above global tab bar  */
#app-container .v-navigation-drawer__content {
  /* height: 100%;
    overflow-y: auto;
    overflow-x: hidden; */
  padding-bottom: 140px; /* extra space for additional toolbar for REPL + Logs buttons */
}
</style>

<script>
import { Device } from '../store/projects.js';
import codemirror from "./codemirror/codemirror";
import "codemirror/lib/codemirror.css";
// language python: TODO: need make the python parser work
import 'codemirror/mode/python/python.js'
// theme css
import 'codemirror/theme/base16-dark.css'
import { mapState } from 'vuex'
import FileExplorer from "@/components/FileExplorer";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { SleepMs } from '../lib/helpers.js';

import { Capacitor } from '@capacitor/core';
const isCameraAvailable = Capacitor.isPluginAvailable('Camera');
const currentPlatform = Capacitor.getPlatform()

console.log(`Capacitor.isNativePlatform ${Capacitor.isNativePlatform}`)
console.log(`currentPlatform ${currentPlatform}`)
console.log(`isCameraAvailable ${isCameraAvailable}`)

let featureFlags = {
  zipImportExport: false
}

// handle different platforms
if (currentPlatform === 'web') {
  console.log('enable features for web')
  featureFlags.zipImportExport = true
}

export default {
  computed: {
    direction() {
      return this.navigation.shown === false ? "Open" : "Closed";
    },
    ...mapState({
      activeProject: state => state.projects.activeProject,
      currentFile: state => state.projects.activeProject.currentFile,
      loadingFile: state => state.projects.loading,
      flashing: state => state.projects.flashing,
      availableProjects: state => state.projects.availableProjects,
      syncing: state => state.projects.syncing,

      // IDE app bar
      isCommunicatingWithDevice: (state) => state.ide.isCommunicatingWithDevice,
      deviceStreamProgress: (state) => state.ide.deviceStreamProgress,
      deviceStreamBufferProgress: (state) => state.ide.deviceStreamBufferProgress,
    })

  },

  components: {
    codemirror,
    FileExplorer,
  },
  props: {
    focused: Boolean
  },
  data() {
    return {
      length: 1,
      tab: null,
      tabs: [
        // { file: FileElement, active: false}
      ],
      featureFlags,
      navigation: {
        shown: true,
        width: 300,
        borderSize: 3
      },
      showImportProjectTool: false,
      zipFile: null,
      drawer: true,
      device: Device,
      changedFromApp: false,
      code: "# select file from project to view code",
      selectedFile: null,
      flashCompleted: false,
      isFlashing: false,
      isSyncing: false,
      cmOptions: {
        tabSize: 4,
        mode: 'text/x-python',
        theme: 'base16-dark',
        lineNumbers: true,
        line: true,
        version: 3,
        autofocus: true,
        // https://codemirror.net/5/demo/resize.html
        viewportMargin: Infinity,
        readOnly: false,
        // more CodeMirror options...
      },
      cmInstance: null,
      exampleCmOptions: {
        tabSize: 4,
        mode: 'text/x-python',
        //theme: 'base16-dark',
        lineNumbers: true,
        line: true,
        version: 3,
        readOnly: true,
        // more CodeMirror options...
      },
      // showFiles: true,
      miniVariant: false,
      items: [],
      selectedProject: null,
    };
  },
  watch: {
    tab(val, oldVal) {
      console.log(val, oldVal)
    },
    length(val) {
      this.tab = val - 1
    },

    availableProjects(projects) {
      this.updateSelectableProjects(projects)
    },

    activeProject(value) {
      // this will call the async selectedProject(projectName) watch func
      this.selectedProject = this.activeProject.name;
    },

    async selectedProject(projectName) {
      // first close tabs for previously active project
      await this.resetProject(projectName);
      this.selectedFile = null;
      await SleepMs(100);
      console.log("project selected " + projectName);
      await this.$store.dispatch('projects/setActiveProjectByName', projectName);
      await this.$store.dispatch('projects/setKitFileToCurrentFile');
    },

    // This would be called anytime the flashing state changes
    flashing(isFlashing) {
      if (isFlashing) {
        this.isFlashing = true;
        this.cmOptions.readOnly = true;
      } else {
        this.flashCompleted = true; //trigger popup asking user for permissino to reboot / connect
        this.isFlashing = false;
        this.cmOptions.readOnly = false;
        this.resetProject(this.activeProject);
      }
    },

    syncing(isSyncing) {
      if (isSyncing) {
        this.isSyncing = true;
        this.cmOptions.readOnly = true;
      } else {
        this.isSyncing = false;
        this.cmOptions.readOnly = false;
        this.resetProject(this.activeProject);
      }
    },

    focused(isFocused) {
      console.log(this.cmInstance);
      this.navigation.shown = isFocused;
      if (isFocused) {
        this.cmInstance.focus();
        this.cmInstance.refresh();
      }
    },

    loadingFile(loading) {
      if (loading)
        console.log("loading file!!!");
    },

    // this only gets called if user selected a file in the explorer
    async currentFile(file) {
      console.log('currentFile', file);
      this.selectedFile = file;
      await this.updateTabs(file);
    }
  },
  methods: {
    async resetProject(projectName) {
      // first close tabs for previously active project
      this.closeAllTabs();
      await this.$store.dispatch('projects/closeCurrentFile', { project: this.activeProject });
    },

    async updateTabs(file) {
      if (!file) {
        return;
      }
      // check if current file in open tabs
      let fileHasTab = this.findTabFromFile(file) != null;

      if (!fileHasTab) {
        // if current file NOT in open tabs
        // add tab with name of new file 
        console.log("addTab for " + file.filePath);
        this.addTab(file);

      } else {
        // if current file IN open tabs 
        // refresh content
        console.log("woops!")
      }

      if (file) {
        let content = await this.$store.dispatch('projects/readFileFromStorage', { filePath: file.filePath, project: this.activeProject });
        if (content.length) {
          console.log("file has content");
        }
        else {
          console.log("empty file", file.filePath);
          content = "";
        }
        this.code = content;
        // focus Editor so height resizes to current file size
        this.cmInstance.focus();

        let tab = this.findTabFromFile(file);
        tab.content = content;
      }

      // switch active tab 
      let tabFile = this.findTabFromFile(file);
      this.tab = this.tabs.indexOf(tabFile);
    },

    setBorderWidth() {
      let i = this.$refs.drawer.$el.querySelector(
        ".v-navigation-drawer__border"
      );
      i.style.width = this.navigation.borderSize + "px";
      i.style.cursor = "ew-resize";
      i.style.backgroundColor = "white";
    },
    setEvents() {
      const minSize = this.navigation.borderSize;
      const el = this.$refs.drawer.$el;
      const drawerBorder = el.querySelector(".v-navigation-drawer__border");
      const vm = this;
      const direction = el.classList.contains("v-navigation-drawer--right")
        ? "right"
        : "left";

      function resize(e) {
        console.log(direction);
        document.body.style.cursor = "ew-resize";
        let f =
          direction === "right"
            ? document.body.scrollWidth - e.clientX
            : e.clientX;
        el.style.width = f + "px";
      }

      drawerBorder.addEventListener(
        "mousedown",
        (e) => {
          if (e.offsetX < minSize) {
            el.style.transition = "initial";
            document.addEventListener("mousemove", resize, false);
          }
        }
      );

      document.addEventListener(
        "mouseup",
        () => {
          el.style.transition = "";
          this.navigation.width = el.style.width;
          document.body.style.cursor = "";
          document.removeEventListener("mousemove", resize, false);
        },
        false
      );
    },

    handleFileUpload(zipFile) {
      console.log(zipFile);
      this.zipFile = zipFile;
    },

    async unzipFiles() {
      if (this.zipFile) {
        this.showImportProjectTool = false;
        this.readZipFile(this.zipFile);
      }
    },

    readZipFile(zipFile) {
      const reader = new FileReader();

      reader.onload = () => {
        // The 'result' attribute contains the content of the file
        const zipContent = reader.result;
        //this.processZipContent(zipContent);
        this.$store.dispatch('projects/processZipProjectContent', { name: zipFile.name, zipName: zipFile.name, zipContent: zipContent });

      };

      reader.readAsArrayBuffer(zipFile);
    },

    async exportProject() {
      const zip = new JSZip();

      let allFiles = this.activeProject.getDeviceFlatTree()
      for (let i in allFiles) {
        let f = allFiles[i];
        if (f.isDir) {
          zip.folder(f.filePath);
        }
        else {
          let content = await this.$store.dispatch('projects/readFileFromStorage', { filePath: f.filePath, project: this.activeProject });
          if (!content || 0 == content.length) {
            content = "";
          }

          zip.file(f.filePath, content);
        }
      }

      zip.generateAsync({ type: "blob" }).then(function (content) {
        // see FileSaver.js
        saveAs(content, "jem.zip");
      });
    },

    findTabFromFile(file) {
      for (let i in this.tabs) {
        let fileTab = this.tabs[i];
        if (fileTab.file.filePath == file.filePath) {
          return fileTab;
        }
      }
      return null;
    },

    async tabSelected(tab) {
      this.selectedFile = tab.file;
      await this.updateTabs(tab.file); // will read file data from storage and update contents
    },

    async closeTab(tab) {
      for (let i = 0; i < this.tabs.length; i++) {
        if (this.tabs[i] === tab) {
          this.tabs.splice(i, 1);
          if (tab.file == this.currentFile) {
            console.log("tab closes current file");
            await this.$store.dispatch('projects/closeCurrentFile', { project: this.activeProject });
          }
        }
      }
      console.log(this.tabs)
      if (this.tabs.length) {
        this.tabSelected(this.tabs[this.tabs.length - 1]);
      }
      else {
        this.code = "# no file selected";
        this.cmInstance.focus();
      }
    },

    closeAllTabs() {
      while (this.tabs.length) {
        this.tabs.pop();
      }
      this.code = "# no file selected";
      if (this.cmInstance) {
        this.cmInstance.focus(); // close all tabs might happen before cmInstance is set
      }
    },

    addTab(file) {
      let newTab = {
        file: file,
        active: false,
        content: ""
      }
      this.tabs.push(newTab)
    },

    updateSelectableProjects(projects) {
      this.items = []
      for (let p in projects) {
        this.items.push(projects[p].name)
      }
    },

    onCmReady(cm) {
    },
    onCmFocus(cm) {
      this.navigation.shown = false;
    },
    onCmChanges(cm) {
    },
    onCmUpdate(cm) {
    },
    onCmRefresh(cm) {
      this.cmInstance = cm;
    },
    onCmCodeChange(newCode) {
      this.code = newCode;
      if (this.activeProject && this.selectedFile && this.tabs.length) {
        this.$store.dispatch('projects/updateFileContent', { fileElement: this.selectedFile, content: newCode, project: this.activeProject });
      }
      else {
        console.warn("onCmCodeChange: no active projet or file");
      }
    },
  },
  mounted() {
    this.setBorderWidth();
    this.setEvents();

    this.flashCompleted = false;
    for (let p in this.availableProjects) {
      console.log(this.availableProjects[p].name)
    }
    this.updateSelectableProjects(this.availableProjects);
    this.selectedProject = this.activeProject.name;
  },
};

</script>