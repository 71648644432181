// initial state
const state = () => ({
  // IDE app bar
//   isCommunicatingWithDevice: false,
//   deviceStreamProgress: 0,
//   deviceStreamBufferProgress: 0,
  isCommunicatingWithDevice: false,
  deviceStreamProgress: 0,
  deviceStreamBufferProgress: 0,
  isPromptingUserForDeviceReconnect: false,
})

// getters
const getters = {
    // isCommunicatingWithDevice: false,
    // deviceStreamProgress: 0,
    // deviceStreamBufferProgress: 0,
    // allProjects:  (state, getters, rootState) => {
    //     return [].concat(state.cloudProjects).concat(state.localProjects)
    // }
}

// actions
const actions = {
    openDeviceStream ({ state, commit }, options) {
        commit('openDeviceStream', { ...options })
    },
    updateDeviceStream ({ state, commit }, options) {
        commit('updateDeviceStream', { bufferProgress: options.bufferProgress, progress: options.progress })
    },
    closeDeviceStream ({ state, commit }, options) {
        commit('closeDeviceStream', {...options })
    },
    promptUserInputDeviceReconnect ({ state, commit }, options) {
        commit('promptUserInputDeviceReconnect', {...options })
    },
    dismissUserInputDeviceReconnect ({ state, commit }, options) {
        commit('dismissUserInputDeviceReconnect', {...options })
    },
    // async fetchCloudProject ({ state, commit }, options) {
        
    //     const cloudProjects = await CloudProjects()
    //     const projectSummary = await cloudProjects.loadProject('micropython')
    //     commit('setProjectFromCloud', { cloudProject: projectSummary })
    // },
}

// mutations
const mutations = {
    openDeviceStream (state, { options }){
        state.deviceStreamBufferProgress = 0
        state.deviceStreamProgress = 0 
        state.isCommunicatingWithDevice = true
    },
    updateDeviceStream (state, { bufferProgress, progress }){
        state.deviceStreamBufferProgress = bufferProgress
        state.deviceStreamProgress = progress
    },
    closeDeviceStream (state, { options }){
        state.isCommunicatingWithDevice = false
    },
    promptUserInputDeviceReconnect (state, { options }){
        state.isPromptingUserForDeviceReconnect = true
    },
    dismissUserInputDeviceReconnect (state, { options }){
        state.isPromptingUserForDeviceReconnect = false
    },
    // setProjectFromCloud  (state, { cloudProject }){
    //     // console.log('setProjectFromCloud',state)
    //     state.localProjects.push(cloudProject)
    // },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}