<template>
  <v-app id="app">

    <v-system-bar app dark :color="systemBarColor" v-if="systemBarShown"
      v-bind:class="[hasDisplayNotch ? 'display-notch' : 'no-notch']">
      <v-spacer></v-spacer>
      {{ systemBarText }}
      <v-spacer></v-spacer>
      <!-- https://vuetifyjs.com/en/components/progress-linear/#default -->
    </v-system-bar>
    <!-- <AppBarGlobal/> -->

    <v-main id="app-main">
      <v-container id="app-container" v-bind:class="[hasDisplayNotch ? 'display-notch' : 'no-notch']" fluid>
        <!-- DEBUG -->
        <v-card v-if="showDebugView" outlined elevation="0" class="pa-2" width="100%">
          <div>
            hasDisplayNotch: {{ hasDisplayNotch }}
            {{ cssInfo }}
            {{ deviceInfo }}
          </div>
        </v-card>

        <!-- Kit Selection -->
        <!-- https://vuetifyjs.com/en/api/v-tabs-items/#props-touchless -->
        <!-- https://vuetifyjs.com/en/api/v-tab-item/#props-eager -->
        <v-tabs-items v-model="activeTab" :show-arrows="false" :touchless="true">
          <v-tab-item eager key="pair" :value="`tab-pair`">
            <!-- Dialog for confirming user has a JEM -->
            <v-container fluid>
              <v-card>
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Don't have a JEM?
                      </v-list-item-title>
                      <!-- <v-list-item-subtitle>...</v-list-item-subtitle> -->
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-dialog v-model="dialogUserHasJEM" persistent max-width="400">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="orange" dark v-bind="attrs" v-on="on">
                            Get JEM
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="text-h5">
                            This app + JEM = best friends
                          </v-card-title>
                          <v-card-text>
                            <p>
                              You need a JEM microcontroller to use all the features of this app.
                            </p>
                            <p>
                              If you don't have a JEM, you can get one from the <a href="https://kitlab.io/shop"
                                target="blank">Kitlab Store</a>.
                            </p>
                            <p>
                              While you wait for your JEM to arrive, learn about all the things you can make in the <a
                                href="https://discord.gg/b7eqPCJUpm" target="blank">Kitlab Discord community</a>.
                            </p>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="choseHasJEM(false)">
                              I don't have a JEM
                            </v-btn>
                            <v-btn color="green darken-1" text @click="choseHasJEM(true)">
                              I have a JEM
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        JEM Developer Resources
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Docs, Guides, Get Support
                      </v-list-item-subtitle>
                      <!-- <v-list-item-subtitle>...</v-list-item-subtitle> -->
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-dialog v-model="dialogDeveloperResources" persistent max-width="400">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="orange" dark v-bind="attrs" v-on="on">
                            Learn
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="text-h5">
                            All about JEM
                          </v-card-title>
                          <v-card-text>
                            <p>
                              Get more JEMs + accessories at the
                              <a href="https://kitlab.io/shop" target="blank">Kitlab Store</a>
                            </p>
                            <p>
                              Get guides, example code, and API details at
                              <a href="https://github.com/kitlab-io/micropython/wiki" target="blank">Kitlab Github</a>
                            </p>
                            <p>
                              Get support & inspiration by joining the <a href="https://discord.gg/b7eqPCJUpm"
                                target="blank">Kitlab Discord community</a>
                            </p>
                            <p>
                              App Version: {{ AppVersion }}
                            </p>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click=" dialogDeveloperResources = false">
                              Close
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-container>
            <DeviceConnection />
          </v-tab-item>
          <!-- <v-tab-item eager key="repl" :value="`tab-repl`">
            <Terminal></Terminal>
          </v-tab-item> -->
          <v-tab-item key="editor" value="tab-editor">
            <!-- DO NOT SET "eager" prop = true, this will cause rendering errors -->
            <Editor :focused="activeTab == 'tab-editor'"></Editor>
            <!--
            <Overlay v-bind:overlay="overlayEditor">
              <Editor></Editor>
              <template slot="overlay">
                <v-progress-circular
                  indeterminate
                  size="64"
                ></v-progress-circular>
              </template>
            </Overlay>
            -->
          </v-tab-item>
          <v-tab-item eager key="remotecontrol" value="tab-remotecontrol">
            <v-container fluid fill-height>
              <RemoteControl />
            </v-container>
          </v-tab-item>
        </v-tabs-items>

      </v-container>

    </v-main>

    <v-footer id="footer" app>
      <!-- https://materialdesignicons.com/ -->

      <!-- Floating Action Button for Debug Console -->
      <!-- <v-btn @click="showConsole"> Console </v-btn>
      <v-btn @click="showRepl"> REPL </v-btn> -->


      <v-row class="resize-container" :height="consoleHeight">
        <v-col>
          <!-- Resizable bottom navigation -->
          <v-bottom-sheet v-model="showBottomSheet" :hide-overlay="true" id="console-bottomsheet">
            <!-- Handle for resizing -->
            <div class="resize-handle" @mousedown="startResize"></div>

            <!-- Your bottom navigation content goes here -->
            <v-container ref="consoleSheet" id="console-container">
              <!-- <v-row>
                <v-btn @click="showConsole"> Clear </v-btn>
                <v-btn @click="showRepl"> Save </v-btn>
              </v-row> -->
              <v-toolbar dense color="grey darken-4">
                <v-tabs v-model="tabConsole">
                  <v-tab key="repl">REPL</v-tab>
                  <v-tab key="logs">Logs</v-tab>
                </v-tabs>
                <v-spacer></v-spacer>

                <!-- <v-btn icon>
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>

                <v-btn icon>
                  <v-icon>mdi-heart</v-icon>
                </v-btn>

                <v-btn icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn> -->
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn dark icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item v-for="(item, i) in drawerOptions" :key="i" @click="drawerAction(item.action)">
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-toolbar>
              <v-tabs-items v-model="tabConsole" :show-arrows="false" :touchless="true">
                <v-tab-item eager key="repl" ref="tabRepl">
                  <Terminal ref="terminal"></Terminal>
                </v-tab-item>
                <v-tab-item eager key="logs" ref="tabLogs">
                  <DebugTerminal />
                </v-tab-item>
              </v-tabs-items>

              <!-- <DebugTerminal /> -->
            </v-container>

          </v-bottom-sheet>
        </v-col>
      </v-row>

      <v-tabs v-model="activeTab" hide-slider icons-and-text grow @change="onKitTabChange">
        <v-tab key="pair" :href="`#tab-pair`">
          PAIR
          <v-icon>mdi-bluetooth-connect</v-icon>
        </v-tab>
        <!-- <v-tab key="repl" :href="`#tab-repl`">
          REPL
          <v-icon>mdi-console</v-icon>
        </v-tab> -->
        <v-tab key="editor" :href="`#tab-editor`">
          EDIT
          <v-icon>mdi-code-braces-box</v-icon>
        </v-tab>
        <v-tab key="remotecontrol" :href="`#tab-remotecontrol`">
          RC
          <v-icon>mdi-remote</v-icon>
        </v-tab>
      </v-tabs>


    </v-footer>

    <!-- <v-bottom-navigation
        color="indigo"
        height="300"
        app
        handleColor="white"
        ref="drawer"
      >
        <DebugTerminal/>
        
      </v-bottom-navigation> -->



    <v-navigation-drawer app clipped :value="isDrawerOpenLeft" :stateless="true">
    </v-navigation-drawer>
    <!-- right drawer for debug view -->
    <v-navigation-drawer app clipped right :value="isDrawerOpenRight" :stateless="true">

    </v-navigation-drawer>

  </v-app>
</template>

<style>
.jem-progress-bar {
  bottom: -10px !important;
}

.tabs__content,
.v-tabs-items,
.v-window,
.v-window-item {
  min-height: 100vh;
}

.v-slide-group__prev {
  display: none !important;
}

.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)>.v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
  display: none !important;
}

.v-main,
.v-card {
  background: #222;
}

.v-card {
  border: none !important;
}

.v-app-bar,
.v-toolbar {
  background: #6CECBE !important;
}


/* console bottom sheet resizing */
.container#console-container {
  padding-bottom: 120px;
  /* padding-bottom: 80px; */

}

.v-bottom-sheet.v-dialog {
  /* background-color: #121212; */
  background-color: #1e1e1e;
}

/* .tabs__content,
.v-tabs-items,
.v-window, */
#console-container .v-window-item {
  /* overflow-y: scroll; */
  /* height: fit-content; */
  min-height: auto !important;
}


.resize-container {
  position: absolute;
  width: 100%;
  /* height: 10px; */
  /* Set the initial height of the bottom navigation */
  bottom: 0;
  left: 0;
  cursor: row-resize;
}

.resize-handle {
  width: 100%;
  height: 3px;
  background-color: #e0e0e0;
  /* Set the color of the handle */
  cursor: row-resize;
}

/* sytem bar showing */
.v-app-bar.system-bar {
  margin-top: 22px !important;
  z-index: 999 !important;
}

/* display notch showing */
.v-app-bar.display-notch {
  padding-top: 44px;
  height: auto !important;
}

/* sytem bar AND display notch showing */
.v-app-bar.display-notch.system-bar {
  margin-top: 65px !important;
  padding-top: 0px;
  height: auto !important;
}

.v-system-bar.display-notch {
  padding-top: 44px;
  height: auto !important;
}

.v-progress-linear.display-notch {
  bottom: -10px !important;
}

/* display notch showing */
#app-container.display-notch {
  padding-top: 44px;
  height: auto !important;
}

/* .v-btn {
  color: black !important;
} */
/* .v-input__slot, .v-text-field__slot {
  background: #555 !important;
  color: #FFF !important;
} */

.appBarDefault {
  /* margin-top: -72px; */
  width: 100%;
}

.appBarExtended {
  /* margin-top: 10px; */
  width: 100%;
}

.v-footer {
  z-index: 999 !important;
}

.v-bottom-navigation {
  padding: 0;
  /* Remove padding if present */
}

/* If your DebugTerminal component has an outer class, ensure it's also set to 100% */
.v-bottom-navigation .debug-terminal {
  width: 100%;
}
</style>

<script>
// import Debug from "@/components/Debug";
import Editor from "@/components/Editor";
//import Overlay from "@/components/Overlay"
import DeviceConnection from "@/components/DeviceConnection";
import RemoteControl from "@/components/RemoteControl";
import Terminal from "@/components/Terminal";
import "xterm/css/xterm.css";
import { Plugins } from "@capacitor/core";
const { Storage } = Plugins;
import { mapState } from 'vuex'
import { mapActions } from "vuex";
import { Device } from './store/projects.js';
import { Device as ControlDevice } from '@capacitor/device';
import { SafeArea } from "capacitor-plugin-safe-area";
import DebugTerminal from '@/components/DebugTerminal';

import Vue from 'vue';
export const EventBus = new Vue();
import logger from './lib/logger'

const log = logger.log()
const debug = logger.debug()


// App Git Commit: please include this before release 
// TODO: the CI / CD tool should inject this automatically
const AppGitCommit = '01a03b'; //Fixes #396 Mar 12, 2024

// https://vuetifyjs.com/en/components/floating-action-buttons/#speed-dial

const logDeviceInfo = async () => {
  const info = await ControlDevice.getInfo();
  console.log(info);
  return info;
};

const logBatteryInfo = async () => {
  const info = await ControlDevice.getBatteryInfo();
  console.log(info);
  return info;
};

const getCSSInfo = async () => {
  const insets = await SafeArea.getSafeAreaInsets()
  console.log(insets);
  // ⚡️  To Native ->  SafeArea getSafeAreaInsets 59823706
  // ⚡️  TO JS {"insets":{"right":0,"top":44,"left":0,"bottom":34}}

  return {
    safeArea: insets["insets"]
  }
}

const detectDisplayNotch = (cssInfo) => {
  // portrait orientation
  return (cssInfo.safeArea.top != 0)
}

const detectDeviceFeatures = (deviceInfo, cssInfo) => {

  let formFactor = '';

  return {
    isMobileDevice: (deviceInfo.platform == 'ios' || deviceInfo.platform == 'android'),
    hasDisplayNotch: detectDisplayNotch(cssInfo),
    formFactor: formFactor
  }
}

const detectDevice = async (vueComponent) => {
  // Detect device
  console.log('Detecting device...');
  let deviceInfo = await logDeviceInfo();
  let batteryInfo = await logBatteryInfo();
  let cssInfo = await getCSSInfo();

  let deviceFeatures = detectDeviceFeatures(deviceInfo, cssInfo)
  console.log('deviceFeatures:');
  console.log(deviceFeatures);
  console.log('cssinfo:');
  console.log(cssInfo);

  // alert(cssInfo.safeArea.top);
  // alert(JSON.stringify(deviceInfo));

  // simulating iPhonme from Chrome browser
  // isVirtual: false
  // manufacturer: "Google Inc."
  // model: "iPhone"
  // operatingSystem: "ios"
  // osVersion: "13.2.3"
  // platform: "web"
  // webViewVersion: undefined

  vueComponent.CSSInfo = cssInfo;
  vueComponent.deviceInfo = deviceInfo;

  vueComponent.hasDisplayNotch = deviceFeatures.hasDisplayNotch;
}


// JSON "set" example
async function setObject() {
  await Storage.set({
    key: "user",
    value: JSON.stringify({
      id: 1,
      name: "Max",
    }),
  });
}

// storage
export default {
  name: "App",
  // https://medium.com/@Taha_Shashtari/the-easy-way-to-change-page-title-in-vue-6caf05006863
  title: "JEM IDE",
  components: {
    Editor,
    RemoteControl,
    DeviceConnection,
    Terminal,
    DebugTerminal
    //Overlay,
    // Debug
  },
  // https://vuex.vuejs.org/guide/modules.html#namespacing
  computed: mapState({
    deviceConnected: state => state.projects.deviceConnected,
    deviceName: state => state.projects.deviceName,
    projects: (state, getters) => getters['projects/allProjects'],
    activeProject: state => state.projects.activeProject,
    deviceProject: state => state.projects.deviceProject,
    localProjects: state => state.projects.localProjects,
    // IDE app bar
    isCommunicatingWithDevice: (state) => state.ide.isCommunicatingWithDevice,
    deviceStreamProgress: (state) => state.ide.deviceStreamProgress,
    deviceStreamBufferProgress: (state) =>
      state.ide.deviceStreamBufferProgress,
    // global dialog modal
    dialog: (state) => state.ide.isPromptingUserForDeviceReconnect,
    // local<>cloud projects
    overlayEditor: (state) => state.ide.overlays.tabEditor
  }),
  data: function () {
    return {
      // device detection
      cssInfo: {},
      deviceInfo: {},
      showDebugView: false,
      isMobileDevice: false,
      isDesktopScreen: false,

      // system bar
      systemBarShown: false,
      systemBarColor: "primary",
      systemBarText: "",

      // app bar display                                                                                                             
      showAppBar: true,
      hasDisplayNotch: true,
      appBarClass: "appBarDefault",
      isAppBarExtended: false,
      appBarExtensionHeight: 0,

      // nav drawer display
      isDrawerOpenLeft: false,
      isDrawerOpenRight: false,

      // tab bar
      activeTab: "tab-pair",

      // tab bar bottom sheet
      tabSelected: null,
      activeKitView: null,

      // active project
      count: 0,
      content: "#todo",

      // JEM device connection
      device: Device,
      connectRequested: false,
      // deviceConnected: false,

      // Kit Selection
      kitSelected: false,
      preparingKitView: false,
      currentKit: '',

      // floating button speed dial
      direction: 'top',
      fab: false,
      fling: false,
      hover: false,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: 'slide-y-reverse-transition',

      // bottom sheet for console
      // bottomSheet: true,
      // tabConsole: null,
      drawerOptions: [
        {
          title: "Show full",
          action: "show-full"
        },
        {
          title: "Show half",
          action: "show-half"
        },
        {
          title: "Hide",
          action: "hide"
        },
      ],
      tabConsole: 'logs',
      showBottomSheet: true,
      resizing: false,
      startY: 0,
      startHeight: 0,
      consoleHeight: 200,
      minHeight: 200,

      // dialogs
      dialogUserHasJEM: false,
      dialogDeveloperResources: false,

      // App version
      AppVersion: AppGitCommit,

      // Debug Terminal
      isDebugTerminalVisible: false,

      // repl terminal
      isReplVisible: false
    };
  },
  watch: {
    deviceConnected(connected) {
      console.log("deviceConnected " + connected);
      if (!connected) {
        this.systemBarText = "No JEM connected :("
        this.systemBarShown = true
      } else {
        this.systemBarText = this.deviceName + " connected :)"
        this.systemBarShown = true
      }
    }
  },
  mounted() {
    this.$track.AppLaunch();

    this.$store.dispatch('projects/initLocalStorage');
    //this.$store.dispatch('projects/setupTerminal');
    this.$store.dispatch('projects/updateActiveProject', { project: this.deviceProject });

    detectDevice(this);

    this.drawerAction("hide")
  },
  methods: {
    ...mapActions({
      'dismissModal': 'ide/dismissUserInputDeviceReconnect'
    }),

    drawerAction(action) {
      debug.log(action)

      switch (action) {
        case "show-full":
          this.consoleHeight = 800;
          break;
        case "show-half":
          this.consoleHeight = 400;
          break;
        case "hide":
          this.consoleHeight = 100;
          break;
      }

      this.resizeAllBottomSheetViews()
    },

    startResize(event) {
      console.log('startResize', event)
      console.log(this.$refs.consoleSheet)

      this.resizing = true;
      this.startY = event.clientY;
      this.startHeight = this.$refs.consoleSheet.clientHeight;

      document.addEventListener('mousemove', this.resize, false);
      document.addEventListener('mouseup', this.stopResize);
    },
    resize(event) {
      // if (this.resizing) {
      const deltaY = event.clientY - this.startY;
      // this.$refs.bottomSheet.setHeight(this.startHeight + deltaY);
      this.consoleHeight = this.startHeight - deltaY
      // console.log(deltaY, this.consoleHeight)
      // console.log(this.$refs)
      
      this.resizeAllBottomSheetViews()
      // }
    },
    resizeAllBottomSheetViews() {
      this.$refs.consoleSheet.style.height = this.consoleHeight + "px";
      let innerViewHeight = this.consoleHeight-140
      this.$refs.tabRepl.$el.style.height = innerViewHeight + "px";
      this.$refs.tabLogs.$el.style.height = innerViewHeight + "px";

      this.$refs.terminal.resizeTerminal()
    },
    stopResize() {
      console.log('stopResize')

      this.resizing = false;
      document.removeEventListener('mousemove', this.resize, false);
      document.removeEventListener('mouseup', this.stopResize);
    },

    // showConsole() {
    //   this.isDebugTerminalVisible = !this.isDebugTerminalVisible;
    //   //console.log("hello!");
    //   debug.storage("console")
    // },

    // showRepl() {
    //   this.isReplVisible = !this.isReplVisible;
    //   //console.log("hello!");
    //   debug.storage("repl")
    // },

    showSystemBar(text, color) {
      this.systemBarShown = true;
      this.systemBarColor = color;
      this.systemBarText = text;
    },
    hideSystemBar() {
      this.systemBarShown = false;
      this.systemBarColor = "primary";
      this.systemBarText = "";
    },
    choseHasJEM(userHasJEM) {
      if (!userHasJEM) {
        this.$track.ChoseHasJEM(userHasJEM);
      } else {
        this.$track.ChoseHasJEM(userHasJEM);
      }
      this.dialogUserHasJEM = false;
    },
    cleanInput() {
      this.inputSubmitted = false;
      this.submittedCredentials = false;
    },
    async login() {
      // show loading on button and lock input
      this.submittedCredentials = true;
      this.inputSubmitted = true;

      // mock sending credentials to server for validation 

      // validate email & password locally
      this.validLogin = (
        this.inputUser == 'kitlab' && this.inputPassword == 'kitlab'
      )
      this.submittedCredentials = false;

      // show validation error

      // on valid credentials 
      if (!this.validLogin) return
      // transition to main application 


      this.authenticatedUser = true;
      this.showAppBar = true;

      // force check to paired JEM
      // show JEM pairing view
      // show kit selection 
      // show remote control view
    },
    validateLogin(value) {
      console.log("validateLogin")
      return this.submittedCredentials && this.validLogin;
    },

    connectJEM() {
      this.connectRequested = true;

      // on JEM connection success
      this.deviceConnected = true;
      this.deviceConnectionType = 'ble'

      this.connectRequested = false;
    },

    selectKit(kitID) {
      this.preparingKitView = true;

      // on kit view ready
      this.currentKit = kitID;
      this.kitSelected = true;

      this.tabSelected = 'tab-connect'
      this.activeKitView = 'connect'
    },

    onKitTabChange(href) {
      console.log(href, this.tabSelected)

      switch (href) {
        case "tab-pair":
          this.$track.ViewJEMConnections();
          this.activeKitView = 'connect'
          break;
        case "tab-remotecontrol":
          this.$track.ViewRemoteControl();
          this.activeKitView = 'control'
          break;
        case "tab-editor":
          this.$track.ViewCodeEditor();
          this.activeKitView = 'code'
          break;
        case "tab-repl":
          this.$track.ViewConsole();
          this.activeKitView = 'console'
          break;
      }
    },

    async fetchAllCloudProjects() {
      this.$track.DownloadAllCloudProjects();
      this.$store.dispatch("projects/fetchAllCloudProjects");
    },
    async fetchCloudProject() {
      this.$track.DownloadCloudProject();
      this.$store.dispatch("projects/fetchCloudProject");
    },

    async rebootDevice() {
      this.$track.JEMReboot();
      this.$store.dispatch('projects/rebootDevice');
    },

    async updateActiveProjectByName(projectName) {
      this.$track.UploadProject();
      //ex: projectName = "micropython" or "MicropythonBoardProject" for device
      this.$store.dispatch('projects/updateActiveProjectByName', projectName);
    },

    toggleDrawerLeft() {
      console.log("toggleDrawerLeft", this.isDrawerOpenLeft);
      this.isDrawerOpenLeft = !this.isDrawerOpenLeft;
    },
    toggleDrawerRight() {
      console.log("toggleDrawerright", this.isDrawerOpenRight);
      this.isDrawerOpenRight = !this.isDrawerOpenRight;
    },
  },
};
</script>