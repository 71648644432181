// ES2015/Typescript
import { Deploy } from "cordova-plugin-ionic";

const changeToProductionChannel = async () => {
  console.log("changeToProductionChannel")
  await Deploy.configure({ channel: "Production" });
};

const performManualUpdate = async () => {
  console.log("performManualUpdate");
  const update = await Deploy.checkForUpdate();
  if (update.available) {
    console.log("update available. starting download");
    await Deploy.downloadUpdate((progress) => {
      console.log("downloadUpdate " + progress);
    });
    await Deploy.extractUpdate((progress) => {
      console.log("extractUpdate " + progress);
    });
    console.log("reloadApp");
    await Deploy.reloadApp();
  } else {
    console.log("no update available");
  }
};

const runLiveUpdate = async () => {
  console.log("runLiveUpdate")
  // await changeToProductionChannel();
  await performManualUpdate();
};

export default {
  install: (Vue, { buildChannel }) => {
    console.log(buildChannel)
    runLiveUpdate()
  }

}