//ble_manager.js
'use strict'
const MAX_MTU_SIZE = 512;  // max for ble capacitor plugin as of July 4, 2023

export class BleManager {
    constructor(driver){
        this.driver = driver;
        this.services = [];
        this.connected = false;
        this.device = null;
        this.mtuSize = 23;
    }

    updateMtuSize(mtuSize)
    {
        if(mtuSize >= 256 && mtuSize < MAX_MTU_SIZE)
        {
            console.warn("BleManager updateMtuSize %d truncated to 100 bytes", mtuSize);
            mtuSize = 100; // temporary until we update micropython rxbuff to be more than 100
        }
        else if(mtuSize >= MAX_MTU_SIZE)
        {
            // if we get here the micropython on board rxbuff has been updated 
            console.warn("BleManager updateMtuSize %d truncated to max %d", mtuSize, MAX_MTU_SIZE);
            mtuSize = MAX_MTU_SIZE;
        }
        this.mtuSize = mtuSize;
        for(let service of this.services){
            service.mtu = mtuSize;
        }
    }

    addService(newService){
        //create a service first: new BleService(serUUID, txUUID, rxUUID);
        let duplicate = false;
        for(let service of this.services){
            if (service.serviceUUID == newService.serviceUUID){
                duplicate = true;
                break;
            }
            service.mtu = this.mtuSize;
        }

        if(!duplicate){
            newService.bleManager = this;
            this.services.push(newService);
        }
    }

    async connect() {
        console.log("BleManager.connect");
        if(!this.initialized){
            console.log("BleManager initializing..");
            await this.driver.initialize(); //TODO: catch errors
            this.initialized = true;
        }

        //TODO: handle device not returned
        try {
            let serviceUUIDs = [];
            for(let service of this.services)
                serviceUUIDs.push(service.serviceUUID);
            
            if(serviceUUIDs.length > 0){
                this.device = await this.driver.requestDevice({services: serviceUUIDs,});
                await this._connectWithServices();
            }
            else 
                console.error("BleManager connected failed: no service uuids");
        }catch (e) {
            console.error(e);
            this.connected = false;
            return new Promise((resolve) => {resolve({connected: false, name: null});});
        }
        
        return new Promise((resolve) => {resolve({connected: true, name: this.device.name});});
    }

    async startNotifications(){
        for(let service of this.services){
            await service.startNotifications();
            await service.onConnect();
        }
    }

    async _connectWithServices(){
        if(this.device == null){
            console.error("BleManager._connectWithService failed, no device set");
            return;
        }
        await this.driver.connect(this.device.deviceId, (deviceId) => this.onDisconnect(deviceId));
        for(let service of this.services){
            try {
                //await service.ping();
                service.available = true;
            }catch(e) {
                console.error("Service: " + service.serviceUUID + ", not available");
            }
        }
        console.log('connected to device', this.device);
        await this.startNotifications();
        this.connected = true;
        return new Promise((resolve) => {resolve(true);});
    }

    onDisconnect(deviceId) {
        alert("JEM Device Disconnected: Please Reconnect");
        this.connected = false;
    }

    disconnect(){
        console.log("BleManager.disconnect");
        this.connected = false;
        for(let service of this.services)
            service.available = false;
        return this.driver.disconnect(this.device.deviceId)
    }

    async reconnect(){
        console.log("BleManager.reconnect");
        if(this.device){
            await this.disconnect();
            return this.connect();
        }
        else
            return new Promise((resolve) => {resolve(true)});
    }
}