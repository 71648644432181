// const pino = require('pino')
import pino from 'pino'
import debug from 'debug'
import Vue from 'vue';
import { EventBus } from '../lib/eventBus';


const levels = {
    emerg: 80,
    alert: 70,
    crit: 60,
    error: 50,
    warn: 40,
    notice: 30,
    info: 20,
    debug: 10,
    trace: 1 // minimum value = 1
  };

let loggerRef = null;
let debuggerRef = null;

const log = debug('log')
const wifi = debug('wifi')
const ble = debug('ble')
const storage = debug('storage')
const storageContent = storage.extend('content')
const network = debug('network')

debuggerRef = {
    log, wifi, ble, 
    storage, storageContent,
    network
}

// Override each namespace's log method

Object.keys(debuggerRef).forEach(namespace => {
  const originalLog = debuggerRef[namespace];
  debuggerRef[namespace] = (...args) => {
    console.log(namespace)
    console.log(args)
    EventBus.$emit('new-debug-message', `[${namespace}] ${args.join(' ')}`);
    originalLog(...args);
  };
});

export default {
    install: (Vue, { level, debugNamespaces }) => {
        const logger = pino({
            transport: {
              target: 'pino-pretty',
              options: {
                colorize: true
              }
            },
            level: level || 'info',
            customLevels: levels,
            useOnlyCustomLevels: true
          })
    
        // eslint-disable-next-line
        Vue.prototype.$logger = logger;

        loggerRef = logger
        
        logger.trace('logger trace')
        logger.debug('logger debug')
        logger.info('logger info')
        logger.notice('logger notice')
        logger.warn('logger warn')
        logger.error('logger error')
        logger.crit('logger crit')
        logger.alert('logger alert')
        logger.emerg('logger emerg')

        // const child = logger.child({ logtype: 'property' })
        // child.notice('hello child!')
        // Vue.prototype.$loggerchild = child;
        
        Vue.prototype.$debug = debuggerRef;
        debug.enable(debugNamespaces || '*')

        // console.log(debuggerRef)
        // console.log(debugNamespaces)
        
        debuggerRef.log('log')
        debuggerRef.wifi('wifi')
        debuggerRef.ble('ble')
        debuggerRef.storage('storage')
        debuggerRef.storageContent('storageContent')
        debuggerRef.network('network')
    },
    log: () => {
        return loggerRef
    },
    debug: () => {
        return debuggerRef
    }
}