//ble_repl_service.js
'use strict'

import { BleUartService } from "./ble_uart_service.js";
import { Mutex } from "../mutex.js";
import { SleepMs, ReadFileResponse, GetChecksum } from "../helpers.js";
import { FTPCmdResp, FTPWriteCmd, FTPReadCmd, FTPChecksumCmd, FTPFailCmd, FTPGetDirsCmd } from "./ftp_cmd.js";

//ftp uart ble uuids
const ftpServiceUUID  = '6e400001-b5a3-f393-e0a9-e50e24dcca77';
const ftpRxCharUUID   = '6e400002-b5a3-f393-e0a9-e50e24dcca77';
const ftpTxCharUUID   = '6e400003-b5a3-f393-e0a9-e50e24dcca77';

export class BleFtpService extends BleUartService {
    constructor(serviceUUID=ftpServiceUUID, txUUID=ftpTxCharUUID, rxUUID=ftpRxCharUUID){
        super(serviceUUID, txUUID, rxUUID);
        this.ftpMutex = new Mutex();
        this.ftpResp = []; // save raw resp bytes in respCallback here
    }

    read(fileName, rdPos, rdLen){
        console.log("BleFtpService.read: " + fileName + ", rdPos: " + rdPos + ", rdLen: " + rdLen);
        //read chunk of file (could read entire file if small, but recommend using readFile method)
        let readFileMsg = FTPReadCmd.getSendMsg({rdPos: rdPos, rdLen: rdLen, fileName: fileName});
        let timeoutMs =  10000; //1000 + rdLen * 5; //ex: 1000 bytes -> 1000 + 5000 ms timeout = 6 sec
        return this.send(readFileMsg, true, timeoutMs);
    }

    write(fileName, fileData, wrPos, wrMethod){
        console.log("BleFtpService.write: " + fileName + ", wrPos: " + wrPos + ", wrMethod: " + wrMethod);
        let writeFileMsg = FTPWriteCmd.getSendMsg({wrMethod: wrMethod, wrPos: wrPos, fileName: fileName, fileData: fileData});
        let timeoutMs =  10000; //1000 + writeFileMsg.length * 5; //ex: 1000 bytes -> 100 + 5000 ms timeout = 5.1 sec
        return this.send(writeFileMsg, true, timeoutMs);
    }

    async _readDirs(rootName="/flash"){
        let readDirsMsg = FTPGetDirsCmd.getSendMsg(rootName);
        let rxCmd = await this.send(readDirsMsg, true, 20000);

        let msg = rxCmd.getRespMsg();
        return new Promise((resolve) => { 
            resolve(msg);
        });
    }

    async readDirs(rootName="/flash"){
        console.log("BleFtpService.readDirs: " + rootName);
        let msg = await this.ftpMutex.synchronize(() => {
            return this._readDirs(rootName);
        });
        return msg;
    }

    async getFileChecksum(filePath){
        console.log("BleFtpService.getFileChecksum: " + filePath);
        let msg = await this.ftpMutex.synchronize(() => {
            return this._getFileChecksum(filePath);
        });
        let resp = {checksum: 0, length: 0};
        if(msg)
        {
            console.log("checksum: " + msg[0]);
            resp = {checksum: msg[0], length: msg[1]};
        }
        else
        {
            console.error("checksum error or not received");
        }
        return resp;
    }

    async _getFileChecksum(filePath){
        let rxCmd = await this.checksumFile(filePath);
        let msg = null;
        if(rxCmd)
        {
            msg = rxCmd.getRespMsg();
        }
        return new Promise((resolve) => { 
            resolve(msg);
        });
    }

    async writeFile(fileName, fileContentString, chunkSize){
        return this.ftpMutex.synchronize(() => {
            return this._writeFile(fileName, fileContentString, chunkSize);
        });        
    }

    async _writeFile(filePath, fileContentString, chunkSize){
        console.log("BleFtpService.writeFile: " + filePath + " chunkSize " + chunkSize);
        //write entire file
        let wrPos = 0;
        //let chunkSize = 1000 // chunkSize Note: 1000 bytes write chunks seems to be optimum
        let writeCompleted = false;
        let fileData = new TextEncoder().encode(fileContentString);
        let parseFaileCnt = 0;
        let wrMethod = "wb";
        let prevWrMethod = wrMethod;
        let checksumResp = null;
        
        while(writeCompleted == false && parseFaileCnt <= 3){
            let rxCmdResp = await this.write(filePath, fileData.slice(0,chunkSize), wrPos, wrMethod); //wait for resp cmd and then read
            wrMethod = "ab"; //initially "wb" then change to append
            if(rxCmdResp && rxCmdResp instanceof FTPWriteCmd){
                if(rxCmdResp.getRespMsg() != "ok"){
                    console.error("BleFtpService.writeFile: " + filePath + " write failed");
                    console.error("resp: " + rxCmdResp.getRespMsg())
                    break; //end loop, write not completed
                }
            }
            else if(rxCmdResp && rxCmdResp instanceof FTPFailCmd){
                console.error("BleFtpService.writeFile: " + filePath + " parsing failed: " + rxCmdResp.getRespMsg());
                parseFaileCnt++;
                wrMethod = prevWrMethod; 
                continue; //skip next code and restart send of previous message
            }
            else if(rxCmdResp)
            {
                // no valid resp, quit writing and try again or notify user?
                console.error("invalid write resp: " + rxCmdResp.getRespMsg());
                break;
            }
            else 
            {
                console.error("no write resp");
                break;
            }

            fileData = fileData.slice(chunkSize);
            if(fileData.length == 0){
                console.log("finished write, checksum verify ...");
                SleepMs(10); // TODO: do we need this?
                let msg = await this._getFileChecksum(filePath);
                checksumResp = {'checksum': msg[0], 'length': msg[1]};
                writeCompleted = true;
                break;
            }
            SleepMs(5); // TODO: do we need this?
            prevWrMethod = wrMethod;
        }

        if(writeCompleted)
        {
            if(checksumResp.checksum != GetChecksum(fileContentString))
            {
                console.error("checksum check failed for write " + filePath);
                writeCompleted = false;
            }
            else if(checksumResp.length != fileContentString.length)
            {
                console.error("file len check failed for write " + filePath);
                writeCompleted = false;
            }
        }
        return new Promise((resolve)=>{
            try {
                resolve(writeCompleted);
            }catch (e) {
                console.error(e);
                resolve(false);
            }
        });
    }

    async readFile(fileName){
        return this.ftpMutex.synchronize(() => {
            return this._readFile(fileName);
        }); 
    }

    async _readFile(fileName){
        console.log("BleFtpService.readFile: " + fileName);
        let readContent = "";
        //read entire file
        //start at pos 0 and read len = 1000 bytes
        let rdPos = 0;
        let chunkSize = 5000; //5k bytes read chunks seems to be optimum
        let readCompleted = false;
        var fileChunk = null;
        let parseFaileCnt = 0;
        while(readCompleted == false && parseFaileCnt <= 3){
            let rxCmd = await this.read(fileName, rdPos, chunkSize); //wait for resp cmd and then read
            if((rxCmd && rxCmd instanceof FTPFailCmd) || !rxCmd){
                let failReason = rxCmd ? rxCmd.getRespMsg() : "no rx cmd resp";
                console.error("BleFtpService.readFile: " + fileName + " parsing failed: " + failReason);
                parseFaileCnt++;
                continue; //skip next code and restart send of previous message
            }
            else if (rxCmd instanceof FTPReadCmd)
            {
                fileChunk = rxCmd.getRespMsg();
                readContent += fileChunk;
                if(fileChunk.length < chunkSize){
                    console.log("finished");
                    readCompleted = true;
                    break;
                }
                rdPos += chunkSize;
                SleepMs(50);
            }
            else
            {
                console.error("err cmd not read cmd");
                break;
            }
        }

        return new Promise((resolve, reject) => { 
            if(!readCompleted){
                resolve(null);
            }
            else {
                let checksum = GetChecksum(readContent)
                resolve(new ReadFileResponse({name: fileName, checksum: parseInt(checksum), data: readContent}));
            }
        });
    }

    checksumFile(fileName){
        let checksumFileMsg = FTPChecksumCmd.getSendMsg(fileName);
        let timeoutMs = 10 * 1000; //10 sec
        return this.send(checksumFileMsg, true, timeoutMs);
    }

    clearResp()
    {
        this.ftpResp = [];
    }

    // this gets called by ble_uart_service.js in rxHandler
    respCallback(resp){
        if(this.saveResp){ // indicates we want to save any resp we get from ble device
            this.ftpResp = this.ftpResp.concat(resp);
            let data = this.ftpResp;
            let respCmd = FTPCmdResp.getResp(new Uint8Array(data));
            if(respCmd.cmd){
                this.setSavedResp(respCmd.cmd);
                this.signalWaitForRespCompleted();// signals to ble_uart_service.js _send cmd that send completed
                console.log("got rxCmd");
            }
        }
    }
}
