// repl.js - Interface / Wrapper for micropython repl
// serialInterface object must have the required methods, but that's it!

'use strict'
import { SleepMs } from "./helpers.js";

export class ReplService {

    constructor(serialInterface)
    {
        this.serial = serialInterface;
        this.replResp = [];
        this.encoder = new TextEncoder();
        this.decoder = new TextDecoder();
        this.serial.registerRxCallback((resp) => { this.rxCallback(resp); });
    }

    isConnected()
    {
        return this.serial.isConnected();
    }

    clearResp()
    {
        this.replResp = []
    }
    
    rxCallback(resp){
        if(this.saveResp){
            this.replResp = this.replResp.concat(resp);
        }
        let str = this.decodeRawResp(resp);
        this.updateTerminal(str);
    }

    async flush()
    {
        this.replResp = [];
    }

    async read(timeoutMs = 1000)
    {
        let b = null;
        let tries = 0;
        do 
        {
            if(this.replResp.length)
            {
                b = this.replResp[0];
                this.replResp = this.replResp.slice(1, this.replResp.length);
            }
            else 
            {
                await this.waitForRxResp(timeoutMs);
                tries++;
            }
        }
        while((b == null) && (tries <= 1));

        return b;
    }

    async write(data)
    {
        let result = await this.serial.write(data);
        return result;
    }

    waitForRxResp(timeoutMs=500){
        // this function starts a timer that polls the this.resp buffer
        // this.resp is typicall filled in by the rxHandler -> respCallback function
        // once signalWaitForRespCompleted is set we are done
        let currentRespLen = this.replResp.length;
        return new Promise((resolve) => {
            try {
            var timeoutID = null;
            var intervalID = setInterval(() => {
                if(currentRespLen != this.replResp.length){
                    clearInterval(intervalID);
                    if(timeoutID != null){
                        clearTimeout(timeoutID);
                    }
                    resolve(true);
                }
            }, 5);
            timeoutID = setTimeout(()=>{
                clearInterval(intervalID); 
                resolve(false);
            }, timeoutMs);
        }catch(e){
            console.error("waitForResp failed " + e);
        }
        });

    }

    async write_and_read_until(cmd, expect, data_consumer) {
        //this.serial.pause()
        //const chunkSize = 128
        let cmdBytes = new Uint8Array(this.encoder.encode(cmd)); // str to bytes
        if(expect) {
            this.saveResp = true;
        }
        
        await this.write(cmdBytes);
        await SleepMs(10);
        let o = null
        if(expect) {
            o = await this.read_until(expect, data_consumer)
            this.saveResp = false; // clear so we no longer save resp from read handler
        }
        await this.flush()
        await SleepMs(10)
        //this.serial.resume()
        return o
    }

    async read_until(endingStr, data_consumer) {
        //console.warn("read_until: " + endingStr);
        // NOTE: assuming this.saveResp = true from caller function
        let buff = [];
        let attempts = 0;
        let timeoutMs = 1000; // 1 sec per byte
        do
        {
            let byte = await this.read(timeoutMs); // 
            if (byte) {
                buff.push(byte);
                if (data_consumer) {
                    let strResp = this.decodeRawResp(buff); // [123, 45, 67, ...etc] -> "some string"
                    data_consumer(strResp)
                }
            }
            else 
            {
                attempts++;
            }

            let strBuff = this.decodeRawResp(buff); // bytes to string
            //console.warn("strBuff: " + strBuff);
            //console.warn("buff: " + buff);

            if (strBuff.indexOf(endingStr) !== -1) {
                //this.serial.removeListener('readable', fn)
                //console.error("read_until got expected: " + endingStr);
                return strBuff;
            }
        }while(attempts <= 10); // 10 sec
        console.error("timeout after attempts " + attempts);
        return null;
    }
    
      async enter_raw_repl() {
        let result = await this.write_and_read_until(`\x01`, `raw REPL; CTRL-B to exit`);
        return result;
      }
    
      async exit_raw_repl() {
        console.log("exit_raw_repl");
        let result = await this.write_and_read_until(`\x02`, '\r\n>>>');
        console.log("exit_raw_repl done");
        return result;
      }
    
      async exec_raw(cmd, data_consumer) {
        await this.write_and_read_until(cmd)
        let out = await this.write_and_read_until('\x04', '\x04>', data_consumer)
        return out;
      }

    decodeRawResp(resp){
        //resp is assumed to be an array like [0x04, 0x33, 0x68] ... etc
        return this.decoder.decode(new Uint8Array(resp));
    }

    updateTerminal(text) {
        //set this method to something that populates terminal (for example)
        console.warn("BleReplService.updateTerminal: not implemented yet")
    }    
}

