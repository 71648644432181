//ble_remote_control_service.js
'use strict'

import { BleUartService } from "./ble_uart_service.js";
import { numberToUUID } from '@capacitor-community/bluetooth-le';
import { CodeCmd, FTPCmdResp } from "./ftp_cmd.js";
import { Mutex } from "../mutex.js";


//repl uart ble uuids
//numberToUUID(0x180f);
const rcServiceUUID  = numberToUUID(0xca33);
const rcRxCharUUID   = numberToUUID(0xcb33);
const rcTxCharUUID   = numberToUUID(0xcc33);
const rcAuxCharUUID   = numberToUUID(0xcd33);
const rcSyncCharUUID   = numberToUUID(0xcf33);

const EOT = 0x04; // end of transmission
const EOT_END = 62; // ">"
const CLEAR_CMD = 'c'; //clear cmd buffer on jem board

export class BleRemoteControlService extends BleUartService {
    constructor(serviceUUID=rcServiceUUID, txUUID=rcTxCharUUID, rxUUID=rcRxCharUUID){
        super(serviceUUID, txUUID, rxUUID);
        this.handleResp = null;
        this.handleAuxResp = null;
        this.auxCharUUID = rcAuxCharUUID; // used to received asynchronous messages from kit running on JEM
        this.syncCharUUID = rcSyncCharUUID; // used to tell JEM that we are finished sending a cmd and to exec() it
        this.execResponseExpected = true;
        this.evalRespExpected = false;
        this.rcResp = [];
        this.cmdMutex = new Mutex();
    }

    clearResp()
    {
        this.rcResp = [];
    }

    parseCmdResp(data){
        let resp = FTPCmdResp.getResp(data);
        if(resp.cmd){
            return resp.cmd.getRespMsg();
        }
        return null;
    }

    respCallback(resp){
        if(this.execResponseExpected){
            if(resp[0] == "o".charCodeAt(0) && resp[1] == "k".charCodeAt(0)){
                this.setSavedResp(resp);
                this._sendSync('x');
                setTimeout(()=>{ this.signalWaitForRespCompleted();}, 20); //tells jem to process the cmd as 'eval'
            }
        }
        else if(this.evalRespExpected) {
            if(resp[0] == "o".charCodeAt(0) && resp[1] == "k".charCodeAt(0)){
                this._sendSync('e');
            }

            this.rcResp = this.rcResp.concat(resp);
            let cmdResp = this.parseCmdResp(new Uint8Array(this.rcResp));
            if(cmdResp) { 
                this.setSavedResp(cmdResp);
                this.signalWaitForRespCompleted(); 
                if(this.handleResp) {this.handleResp(cmdResp); }
            }
            else 
            {
                //console.log("nope")
            }
        }
    }  
    
    auxRespCallback(resp){
        if(this.handleAuxResp)
            this.handleAuxResp(new Uint8Array(resp));
        else 
        {
            console.warn("no handleAuxResp for resp " + resp);
        }
    } 

    async _sendCommand(pyCommandStr){ 
        if(!this.isConnected())
        {
            alert("Not Connected over BLE");
            return null;
        }
        //micropython will call exec(pyCommandStr) to execute the code
        //ex: sendCommand('print("hello world")')
        let saveResp=true;
        let timeoutMs=2000;
        let rawBytes = this.encoder.encode(pyCommandStr);
        let cmdMsg = CodeCmd.getSendMsg(rawBytes);
        this.evalRespExpected = false;
        this.execResponseExpected = true;
        return this.send(cmdMsg, saveResp, timeoutMs); //.then(() => {console.log("sendCommand done!")});
    }

    async _sendEvalCommand(pyCommandStr){ 
        if(!this.isConnected())
        {
            alert("Not Connected over BLE");
            return null;
        }
        //micropython will call eval(pyCommandStr) to execute the code
        //ex: sendCommand('returnSomeValue()')
        let saveResp=true;
        let timeoutMs=5000;
        let rawBytes = this.encoder.encode(pyCommandStr);
        let cmdMsg = CodeCmd.getSendMsg(rawBytes);
        this.evalRespExpected = true;
        this.execResponseExpected = false;
        let resp = await this.send(cmdMsg, saveResp, timeoutMs);
        return new Promise((resolve) => { resolve(resp);});
    }


    auxRxHandler(value) {
        //value is a type DataView
        let resp = [];
        for (let i = 0; i < value.byteLength; i++){
            resp.push(value.getUint8(i));
            if(this.debug)
                console.log(value.getUint8(i));
        }
        
        this.auxRespCallback(resp);
    }
    
    async startNotifications(){
        await super.startNotifications();
        
        return this.bleManager.driver.startNotifications(
            this.bleManager.device.deviceId,
            this.serviceUUID,
            this.auxCharUUID,
            value => { this.auxRxHandler(value); },
        );
    }

    _sendSync(sync_type) {
        //Tell JEM to exec the cmd we sent using sendCommand
        //sync_types: 'e' for eval, 'x' for exec and 'c' for clear
        let bytes = this.encoder.encode(sync_type);
        return this.bleManager.driver.write(
                this.bleManager.device.deviceId,
                this.serviceUUID,
                this.syncCharUUID,
                new DataView(bytes.buffer),
            );
    }

    sendSync(sync_type){
        return this.mutex.synchronize(() => {return this._sendSync(sync_type);});
    }

    sendClearSync(){
        return this.sendSync(CLEAR_CMD); //this tells the ble rc service to reset / flush any pending cmds or data not parsed yet
    }

    sendEvalCommand(cmd){
        return this.cmdMutex.synchronize(() => {return this._sendEvalCommand(cmd);});
    }

    sendCommand(cmd){
        return this.cmdMutex.synchronize(() => {return this._sendCommand(cmd);});
    }
}
