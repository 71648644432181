'use strict'
var pathLib = require('path');
var pathLibExt = require("pathlib"); // extra path stuff
import _ from 'lodash'
import { GetChecksum } from '../helpers';
import logger from '../logger'
const log = logger.log()
const debug = logger.debug()

function createFlatNestedList(flatList)
{
  // ex:
  /*
  path: '/', type: 'tree'
  path: '/main.py' type: 'blob'
  path: '/drivers', type: 'tree'
  path: 'drivers/test.py', type: 'blob'

  path: '/' isDir: true, children: ['/main.py', '/drivers', 'main.py']
  path: '/drivers', isDir: true, children: ['/drivers/test.py']
  */
 let newFlatList = [];
 for(let i in flatList)
 {
    //flatList[i]; // ex: {path: '/main.py', type: 'blob'}
    let e = flatList[i]; // element
    let isDir = (e.type == 'tree'); // 'blob' is file, 'tree' is directory
    let fName = pathLib.basename(e.path); //ex: /drivers/test.py -> test.py
    let fileType  = isDir ? null : "txt"; // only files get a file type (ex: .py, .txt ...etc)
    let rootPath = pathLib.dirname(e.path); //ex: /drivers/test.py -> /drivers
    let path = e.path; 
    if(pathLib.extname(fName).split(".").length > 1){
        fileType = pathLib.extname(fName).split(".")[1];
    }
    let config = {name: fName, file: fileType, isDir: isDir, rootPath: rootPath, filePath: path, src: e};
    newFlatList.push(new FileElement(config));
 }

 // go through each newFlatList element, if it's a dir then find if it belongs in a parent dir and add it to parent childrens list
 for(let i in newFlatList)
 {
  let child = newFlatList[i]; // get file (or dir)

  // now find out where it belongs (parent dir)
  for(let j in newFlatList)
  {
    let f = newFlatList[j]; // get potential parent directory
    if(f == child || !f.isDir)
    {
      continue; //igore if same element or is not a directory
    }

    if(child.rootPath == f.filePath) // criteria for being a parent of child
    {
      // f is a parent of child
      f.children.push(child); // note: make sure FileElement f has children = [] when created
    }
  }
 }
 return newFlatList;
}

export class FileElement {
    constructor ({name, file, children, isDir=false, dirty=false, rootPath="", filePath="", src=null, deviceFilePath=null, removable=true}) {
      this.name = name;
      this.file = file; //file type (ex: .py, .txt)
      this.isDir = isDir;
      this.dirty = dirty;
      this.rootPath = rootPath;
      this.deviceFilePath = deviceFilePath; //used for flashing files to device
      this.filePath = filePath;
      this.children = children;
      this.synced = false;
      this.src = src; //stores original tree source object that was used to make this element
      this.removable=removable;
      this.isNew = false; // use this if adding new file to project that already exists

      // file name change support
      this._nameChanged = false;
      this._newFilePath = null;
      this._newDevicePath = null;

      // if directory, then this file element has subfiles or subdirs (children)
      if(children == undefined && isDir)
        this.children = [];
    }

    setSynced(synced) {
      this.synced = synced;
    }

    setNewName(newName)
    {
      if(newName != this.name)
      {
        this._newFilePath = pathLibExt.join(this.rootPath, newName);
        this._nameChanged = (this._newFilePath != this.filePath);
        if(this._nameChanged)
        {
          this.name = newName;
          // get parent directory
          //pathLibExt("path/to/file.ext").dir().path //ex: /drivers/sensor.py -> /drivers
          let parentDir = pathLibExt(this.deviceFilePath).dir().path
          this._newDevicePath = pathLibExt.join(parentDir, newName); // ex: /drivers/new_sensor.py    
        }
      }
    }

    nameChanged()
    {
      return this._nameChanged;
    }

    getNewDeviceFilePath()
    {
      return this._newDevicePath;
    }

    getNewFilePath()
    {
      return this._newFilePath;
    }

    updateWithNewFilePath()
    {
      let self = this;
      function updateChildrenPaths(self, children){
        //update all children
        for(let i in children)
        {
          let f = children[i];
          f.rootPath = self.getNewFilePath();
          f.deviceFilePath = pathLib.join(self.getNewDeviceFilePath(), f.name);
          f.filePath = pathLib.join(f.rootPath, f.name); 
          if(f.isDir)
          {
            updateChildrenPaths(self, f.children);
          }
        }
      }

      if(this.isDir)
      {
        updateChildrenPaths(self, this.children);
      }

      //we now switch out all the new file stuff and make it current
      this.deviceFilePath = this.getNewDeviceFilePath();
      this.filePath = this.getNewFilePath();
      
      this.clearPendingNewName();
      
    }

    clearPendingNewName()
    {
      this._nameChanged = false; //clear
      this._newFilePath = null;
      this._newDevicePath = null;
    }
}

export const ConstructFlatTreeFromProjectTree = function(projTree){
    var flatTree = [];
    function appendToFlatTree(tree){
      for(let i = 0; i<tree.length; i++){
        flatTree.push(tree[i]);
        if(tree[i].children){
          appendToFlatTree(tree[i].children);
        }
      }
    }
    appendToFlatTree(projTree);
    return flatTree;
}

export const AddDeviceFilePathToTree = function({ projTree=null, basePath=null, newRootPath="/flash" }){
  //this will add a new file path to altFilePath in FileElement
  //it's typically used to take a file path from a cloud project and convert the path for the device flash
  //ex: (cloud path): /api/jem/drivers/bno055.py -----> (flash device path): /flash/drivers/bno055.py
  debug.storage("AddDeviceFilePathToTree basePath " + basePath + " with newRootPath " + newRootPath);
  function appendToTree(tree){
    for(let i = 0; i<tree.length; i++){
      //ex basePath = /api/jem
      if(basePath == newRootPath)
      {
        tree[i].deviceFilePath = tree[i].filePath; //ex: basePath = "/" and newRootPath = "/", devicePath == filePath then
      }
      else 
      {
        debug.storage("filePath: " + tree[i].filePath);
        let newFilePath = pathLibExt(tree[i].filePath).from(basePath).mount(newRootPath).path
        tree[i].deviceFilePath=newFilePath;
        debug.storage("new deviceFilePath: " + tree[i].deviceFilePath);

        // pathLibEx: example of move file tree to new base path
        //path("base/path/to/file.ext").from("base").mount("other/place").path // "other/place/path/to/file.ext"
      }
      
      if(tree[i].children)
        appendToTree(tree[i].children);
    }
  }
  appendToTree(projTree);
  return;
}

export const ConstructProjectSuperTree = function(flatJsonTree) {
  /* flatJsonTree has at least the following members
    example flatJsonTree
    [
      {path: '/', type: 'tree}.
      {path: '/somepath', type: 'tree'},
      {path: '/somepath/test.py', type: 'blob'}
    ]
    construct a tree model based on Vuetify treeview component data
    see: https://vuetifyjs.com/en/components/treeview/#selectable-icons

    // super tree is list of FileElement objects, which can have children list of other FileElements
    // so it's a combination of a flat structure with potential nested sub dirs / files within each FileElement (children)
    superTreeModel = 
    [
      {filePath: '/', rootPath: '/' , children [{}, {} .. ], isDir: true },
      {filePath: '/somepath', rootPath: '/' , children [{filePath: '/somepath/test.py', rootPath, isDir ..etc}, .. ], isDir: true }
      {filePath: '/somepath/test.py, rootPath: '/somepath' , children: null, isDir: false },
    ]
  */
  let superTreeModel = createFlatNestedList(flatJsonTree);

  return superTreeModel;
}

//get converted to list of nested FileElements
export class ProjectTree {
    constructor( {rootPath="/"} ){
        this.deviceTree = []; //tree starting from root dir of main.py (this is what should be flashed to device)
        this.rootPath = rootPath;
        this.filesToRemove = []; //remove items from main tree and put them here if user calls removeFile
        this.treeJson = null; // original json flat tree that is passed in from device or github
        this.legacyJem1 = false;
        this.deviceRootPath = "/"; // default, but for jem1 it's /flash and we need to update on connection
        this.isDevice = false;
        this.superTree = null; // this is the flat tree with all the FileElements (files and directories with children)
    }

    generateFileRootPath(fileName)
    {
      return pathLibExt.join(this.deviceRootPath, fileName);
    }

    updateDeviceRootPath(p)
    {
      debug.storage("updateDeviceRootPath to " + p);
      this.deviceRootPath = p;
      let devRootDir = this.deviceTree[0];
      AddDeviceFilePathToTree({ projTree: this.deviceTree, basePath: devRootDir.filePath, newRootPath: this.deviceRootPath});
    }

    updateTreeFromJson(treeJson){
      this.treeJson = treeJson;
      this.superTree = ConstructProjectSuperTree(treeJson); //flat list of FileElements (which can be nested)      
      
      console.log("superTree: ");
      console.log(this.superTree);

      let devRootDir = this.getDeviceRoot();
      debug.storage("devRootDir")
      debug.storage(devRootDir);
      devRootDir.removable=false; // tells UI, user can't delete this dir
      this.deviceTree = [devRootDir]; 
      if(this.isDevice)
      {
        this.deviceRootPath = devRootDir.filePath;
      }
      AddDeviceFilePathToTree({ projTree: this.deviceTree, basePath: devRootDir.filePath, newRootPath: this.deviceRootPath});
    }

    getDeviceRoot(){
      // get root directory (where main.py is located)
      let rootDir = null;
      for(let i in this.superTree)
      {
        let mainFile = this.superTree[i];
        if("main.py" == mainFile.name || "boot.py" == mainFile.name)
        {
          console.log("found main or boot file");
          console.log(mainFile);
          // now find dir where this file is located and that's our root directory
          for(let j in this.superTree)
          {
            let f2 = this.superTree[j];
            if((mainFile.filePath == f2.filePath) || !f2.isDir)
            {
              continue; // ignore, same file or not a directory
            } 

            if(mainFile.rootPath == f2.filePath)
            {
              // f2 is our root dir
              // found the root directory that contains main.py
              debug.storage("found main or boot file root")
              rootDir = f2;
              debug.storage(rootDir);
              break;
            }
          }
          if(!rootDir)
          {
            console.warn("main or boot file found but could not find the root dir element, adding it");
            //ex: we found boot.py and with root dir = "/" but there is no root file element in the superTree
            //so we need to add it and then make sure we update all the files in the superTree with the root
            var rootPath = mainFile.rootPath;
            

            let config = {name: "", file: null, isDir: true, rootPath: "", filePath: rootPath, src: null};
            let rootFile = new FileElement(config);

            let newSuperTree = [];
            newSuperTree.push(rootFile);
            for(let p in this.superTree)
            {
              if(this.superTree[p].rootPath == rootFile.filePath)
              {
                rootFile.children.push(this.superTree[p]); // add root file or dir to root folder
              }
              newSuperTree.push(this.superTree[p]);
            }

            //this.superTree.push(f);
            this.superTree = newSuperTree;
            rootDir = rootFile;
          }
          break;
        }
      }
      if(!rootDir)
      {
        console.warn("getDeviceRoot could not find main.py or boot.py, using default root");
        //we couldn't find main.py or boot.py, so let's look for the the "/" file element and make that the root
        for(let s in this.superTree)
        {
          let path = pathLibExt(this.superTree[s].filePath); //ex: "/main.py"
          console.log(path.base());
          if(path.base() == "")
          {
            // we found root, 
            console.log("root default = " + this.superTree[s].filePath);
            rootDir = this.superTree[s];
          }
        }
      }
      return rootDir;
    }

    searchForFilesByName(fileName){
      let files = [];
      for(let i in this.superTree){
        if(this.superTree[i].name == fileName){
          debug.storage("found " + fileName + " at " + this.superTree[i].filePath);
          files.push(this.superTree[i]);
        }
      }
      return files;
    }

    searchForFilesByType(fileType){
      let files = [];
      for(let item in this.superTree){
        if(this.superTree[item].file == fileType){
          debug.storage("found " + fileType + " at " + this.superTree[item].filePath);
          files.push(this.superTree[item]);
        }
      }
      return files;
    }

    getFilesInFolder(folderPath){
      debug.storage("getFilesInFolder");
      let flatTree = this.getFlatTree(); //convert nested tree into list
      let files = [];
      for(let item in flatTree){
        debug.storage("getFilesInFolder");
        debug.storage(item)
        // if(flatTree[item].file == fileType){
        //   debug.storage("found " + fileType + " at " + flatTree[item].filePath);
        //   files.push(flatTree[item]);
        // }
      }
      return files;
    }

    clearTree(){
        this.tree = [];
    }

    getFlatTree(){
      return this.superTree; // super tree is already flat in structure, just go into children
    }

    getDeviceFlatTree(){
      return ConstructFlatTreeFromProjectTree(this.deviceTree);
    }

    fileInDeviceTree(filePath)
    {
      let deviceFlatTree = this.getDeviceFlatTree();
      for(let f in deviceFlatTree)
      {
        if(filePath == deviceFlatTree[f].filePath)
        {
          return true;
        }
      }
      return false;
    }

    getDirFromTree(dirPath){
      debug.storage("getDirFromTree for path " + dirPath)
      let dir = null;
      for(let i in this.superTree)
      {
        let f = this.superTree[i];
        if(!f.isDir)
        {
          continue; //ignore, not directory
        }
        if(dirPath == f.filePath)
        {
          debug.storage("found dir");
          dir = f;
        }
      }
      return dir;
    }

    getFileFromTree(filePath){
      // will get a file or directory FileElement
      debug.storage("getFileFromTree: " + filePath)
      let file = null;
      for(let i in this.superTree)
      {
        let f = this.superTree[i];
        if(filePath == f.filePath)
        {
          debug.storage("found file");
          file = f;
        }
      }
      return file;
    }

    removeFile(filePath){
      let file = this.getFileFromTree(filePath);
      debug.storage("ProjectTree.removeFile: ", filePath);
      let dir = this.getDirFromTree(file.rootPath);
      if(dir){
        let index = -1;
        for(let fIndex in dir.children){
            if(dir.children[fIndex].filePath == filePath){
              debug.storage("removing " + filePath + " from dir " + dir.filePath);
              index = fIndex;
              break;
            }
        }
        if(index != -1){
          this.filesToRemove.push(dir.children[index]);
          dir.children.splice(index,1); //remove child at index
        }
      }
    }

    createFileElement(dirPath, itemName, isDir){
      debug.storage("createFileElement: %s, %s, %s", dirPath, itemName, isDir);
      let dirElement = this.getDirFromTree(dirPath);
      if(dirElement){
        let rootPath = dirElement.filePath;
        let filePath = pathLib.join(dirElement.filePath, itemName);
        let devicePath = pathLib.join(dirElement.deviceFilePath, itemName);
        let fileType  = isDir ? undefined : "txt";
        if(!isDir && pathLib.extname(itemName).split(".").length > 1){
          fileType = pathLib.extname(itemName).split(".")[1];
        }
        return new FileElement({name: itemName, file: fileType, isDir: isDir, rootPath: rootPath, filePath: filePath, deviceFilePath: devicePath});
      }
    }

    addFileElement({dirPath=null, itemName=null, isDir=false}){
      console.log("addFileElement: %s, %s, %s", dirPath, itemName, isDir);
      let fileIsNew = true
      let file = this.createFileElement(dirPath, itemName, isDir);
      let dir = this.getDirFromTree(dirPath);
      let children = dir.children
      
      for(let i = 0; i<children.length; i++){
        if(children[i].filePath == file.filePath){
          fileIsNew = false
          break
        }
      }
      if(fileIsNew){
        file.isNew = true;
        file.dirty = true;
        children.push(file)
        this.superTree.push(file);
      }

      return file;
    }
}
