<template>
  <div class="text-center">
    <v-menu v-model="menu" 
    allow-overflow
    offset-overflow
    left
    top
    offset-y
    offset-x
    internal-activator
    :close-on-content-click="false" 
    :nudge-width="200">
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on" @click.stop="toggleProjectItemOptions">mdi-dots-vertical</v-icon>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Rename...</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn :class="'mdi-rename'" icon @click="selectItemForRename(item)">
                <v-icon>mdi-rename</v-icon>
              </v-btn>
            </v-list-item-action>

            <v-dialog v-model="createRenamePromptEn" persistent max-width="290">
              <v-card>
                <v-card-actions>
                  <v-text-field v-model="selectedItemName" variant="outlined" type="input"></v-text-field>
                  <v-btn color="green darken-1" text @click="renameItem(item, selectedItemName)">
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-list-item>

          <v-list-item v-if="!item.file">
            <v-list-item-content>
              <v-list-item-title>Create file</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn :class="'mdi-file'" icon @click="createFilePromptEn = true">
                <v-icon>mdi-file</v-icon>
              </v-btn>
            </v-list-item-action>

            <v-dialog v-model="createFilePromptEn" persistent max-width="290">
              <v-card>
                <v-card-actions>File name:
                  <v-text-field v-model="newFileName" variant="outlined" type="input"></v-text-field>
                  <v-btn color="green darken-1" text @click="createFile(item, newFileName, false)">
                    Create
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-list-item>

          <v-list-item v-if="!item.file">
            <v-list-item-content>
              <v-list-item-title>Create folder</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn :class="'mdi-folder'" icon @click="createDirPromptEn = true">
                <v-icon>mdi-folder</v-icon>
              </v-btn>
            </v-list-item-action>

            <v-dialog v-model="createDirPromptEn" persistent max-width="290">
              <v-card>
                <v-card-actions>
                  <v-text-field v-model="newDirName" variant="outlined" type="input"></v-text-field>
                  <v-btn color="green darken-1" text @click="createFile(item, newDirName, true)">
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Delete</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn :class="(item.removable && fav) ? 'red--text' : ''" icon @click="deleteItem(item)">
                <v-icon>mdi-delete-forever</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: mapState({
    activeProject: state => state.projects.activeProject,
  }),
  props: {
    item: Object
  },
  data: () => ({
    fav: true,
    menu: false,
    message: false,
    hints: true,
    createRenamePromptEn: false,
    createFilePromptEn: false,
    createDirPromptEn: false,
    newFileName: "untitled.py",
    newDirName: "untitled",
    selectedItem: null,
    selectedItemName: ""
  }),
  methods: {

    selectItemForRename(item) {
      console.log('selectItemForRename', item)
      this.selectedItemName = item.name
      this.createRenamePromptEn = true
    },

    renameItem(parent, name, isDir = false) {
      console.log("renameItem ", name);
      
      this.createRenamePromptEn = false

      console.log('renameItem path', parent.filePath);

      this.$store.dispatch('projects/renameFileLocally', {
        project: this.activeProject, filePath: parent.filePath,
        newName: name, isDir: isDir});
    },

    createFile(parent, name, isDir = false) {
      console.log("createFile ", name);
      if (isDir)
        this.createDirPromptEn = false;
      else
        this.createFilePromptEn = false;

      console.log('createFile', parent.filePath);
      this.$store.dispatch('projects/addFileElementToProject', {
        project: this.activeProject, dirPath: parent.filePath,
        itemName: name, isDir: isDir
      });
    },

    // https://vuejs.org/v2/guide/events.html#Event-Modifiers
    deleteItem(parent) {
      console.log('deleteItem', parent);
      if (parent.removable)
        this.$store.dispatch('projects/removeFileFromProject', { project: this.activeProject, path: parent.filePath });
      else
        console.log("file " + parent.filePath + " not removable");
    },

    toggleProjectItemOptions() {
      console.log('toggleProjectItemOptions');
    },
  }
}
</script>