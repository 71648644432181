'use strict'

class ReadFileResponse {
  constructor ({name=null, checksum=null, data=null}) {
    this.fileName = name;
    this.checksum = checksum;
    this.fileData = data;
  }
}

var GetChecksum = function(data){
    var sum = 0;
    for(let i = 0; i < data.length; i++){
        if(typeof(data)=="string")
          sum = (sum + data.charCodeAt(i)) & 0x00FF;
        else
          sum = (sum + data[i]) & 0x00FF;
    }
    return ((sum ^ 0xFF) + 1) & 0x00FF;
}

var SleepMs = function(ms){
  return new Promise((resolve)=>{
      setTimeout(()=>{
          resolve(true);
      }, ms);
  });
}

var RemoveNonAscii = function(data){
  return data.replace(/[\u200B-\u200D\uFEFF\u00A0]/g, '');
}

module.exports = { ReadFileResponse, GetChecksum, SleepMs, RemoveNonAscii };