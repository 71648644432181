<template>
    <div id="Kit" :style="{'cursor': 'pointer'}">
    <h1> No Kit Selected </h1>
    <p>Make sure your JEM has a kit in the kits directory</p>
    <p>And that config.json is set to the correct kit</p>
    </div>
  </template>
  
  <script>
  import { Device } from '../store/projects.js';
  export default {
    name: 'DefaultKit',
    components: {
      // GithubCorner
    },
    data: () => ({
        device: Device,
        kitReady: false,
    }),
  
    mounted(){
      console.log('mounting');
    },
  
    methods: {

      sendCommand(cmdStr){
        if(!this.kitReady){
            this.prepareKit();
            this.kitReady = true;
        }
        if(this.device.isConnected()){
            return this.device.rcService.sendCommand(cmdStr);
        }
        return null;
      },

      sendEvalCommand(cmdStr){
        if(!this.kitReady){
            this.prepareKit();
            this.kitReady = true;
        }
        if(this.device.isConnected()){
            this.device.rcService.handleResp = this.kitResponseHandler;
            return this.device.rcService.sendEvalCommand(cmdStr);
        }
        return null;
      },
    }
  }
  </script>
  <style>
  table, th, td {
    border:1px solid black;
  }
  </style>
  