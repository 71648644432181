<template>
  <div id="term">
 </div>
</template>

<script>

export default {
  
  data: () => ({
    terminal: null,
    terminalFitaddon: null
  }),

  mounted() {
    console.log("Mounting Terminal!")
    this.$store.dispatch('projects/setupTerminal').then(response => {
      console.log('setupTerminal',response)
      console.log(this)
      this.terminal = response.terminal
      this.terminalFitaddon = response.terminalFitAddon
    });
  },

  methods: {
    resizeTerminal() {
      // https://xtermjs.org/docs/api/addons/fit/
      if (this.terminalFitaddon != null) {
        this.terminalFitaddon.fit()
      }
    }
  }

};
</script>

<style scoped>

#term {
  /* min-height: 100vh; */
  min-height: 100px;
  height: 100%;
  width: 100%;
}
</style>