import { Capacitor } from '@capacitor/core';
const isCameraAvailable = Capacitor.isPluginAvailable('Camera');
const currentPlatform = Capacitor.getPlatform()

console.log(`Capacitor.isNativePlatform ${Capacitor.isNativePlatform}`)
console.log(`currentPlatform ${currentPlatform}`)
console.log(`isCameraAvailable ${isCameraAvailable}`)

// handle different platforms
if (currentPlatform === 'web') {
  console.log('enable features for web')
}

if (Capacitor.isNativePlatform) {
  if (currentPlatform === 'ios') {
    console.log('enable features for ios')

  } else if (currentPlatform === 'android') {
    console.log('enable features for android')
    
  }
}

import Vue from "vue";

import logger from "./lib/logger";


// https://github.com/pinojs/pino/blob/master/docs/browser.md
const LOG_LEVEL = 'info'
// const LOG_LEVEL = 'info'

// https://www.npmjs.com/package/debug
// const DEBUG_NAMESPACES = '*' // all namespaces
// const DEBUG_NAMESPACES = '-' // no namespaces

const DEBUG_NAMESPACES = [
  // '*', // all namespaces
  // '-', // no namespaces
  'log',
  'wifi',
  'ble',
  'storage',
  // 'storage:content',
  'network'
].join(',')


Vue.use(logger, { level: LOG_LEVEL, debugNamespaces: DEBUG_NAMESPACES })

import Vuex from "vuex";
Vue.use(Vuex);

import App from "./Main.vue";
import vuetify from "./plugins/vuetify";

import store from "./store";

// import { codemirror } from "vue-codemirror";
import VueCodemirror from "@/components/codemirror";
// import more codemirror resource...
import "codemirror/mode/python/python.js";
import "codemirror/mode/javascript/javascript.js";

// import base style
import "codemirror/lib/codemirror.css";
import "codemirror/theme/base16-light.css";
import "codemirror/theme/monokai.css";

// import VBodyScrollLock from 'v-body-scroll-lock'
// Vue.use(VBodyScrollLock)

// import Vue2TouchEvents from 'vue2-touch-events'
// Vue.use(Vue2TouchEvents)

// import amplitude from 'amplitude-js';
// amplitude.getInstance().init("6aae16e9f96b0e56fe0725b48dfe71c2");
// const event = "App Launch";
// amplitude.getInstance().logEvent(event);

import LiveUpdate from "./lib/liveUpdate";
Vue.use(LiveUpdate, { buildChannel: "Production" })

import Monitor from "./lib/monitor";
Vue.use(Monitor, { apiKey: "6aae16e9f96b0e56fe0725b48dfe71c2" });


Vue.use(
  VueCodemirror /* {
  options: { theme: 'base16-dark', ... },
  events: ['scroll', ...]
} */
);

import titleMixin from './mixins/documentTitle'
Vue.mixin(titleMixin)

Vue.config.productionTip = false;

new Vue({
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");