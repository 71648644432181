import { BleManager } from "./ble/ble_manager.js";
import { BleUartReplService } from "./ble/ble_repl_service.js";
import { BleFtpService } from "./ble/ble_ftp_service.js";
import { BleInfoService } from "./ble/ble_info_service.js";
import { Pyboard } from "./pyboard";
import { BleRemoteControlService } from "./ble/ble_remote_control_service.js";
import { Mutex } from "./mutex.js";
import { ReplService } from "./repl.js";

'use strict'

class ReplInterface
{
    constructor(bleRepl, serialRepl)
    {
        this.encoder = new TextEncoder();
        this.bleRepl = bleRepl;
        this.serialRepl = serialRepl;
    }

    encodeData(b)
    {
        var data = null;
        if(typeof(b) == "string"){
            data = new Uint8Array(this.encoder.encode(b)); //Uint8Array
        }
        else if (b.length == undefined)
            data = new Uint8Array([b]);
        else 
            data = new Uint8Array(b);

        return data;
    }

    send(b, saveResp=false, timeoutMs=500)
    {
        let data = this.encodeData(b);
        if(this.serialRepl.serial.isConnected())
        {
            return this.serialRepl.write(data, saveResp, timeoutMs);
        }
        else 
        {
            return this.bleRepl.write(data); 
        }
    }

    // write: like send cmd above but without the saveResp / timeout args
    async write(cmd)
    {
        let data = this.encodeData(cmd);
        if(this.serialRepl.serial.isConnected())
        {
            return this.serialRepl.write(data);
        }
        else 
        {
            return this.bleRepl.write(data);
        }
    }

    isConnected()
    {
        return this.serialRepl.serial.isConnected() || this.bleRepl.isConnected();
    }

    async exit_raw_repl()
    {
        if(this.serialRepl.serial.isConnected())
        {
            console.log("serialRepl exit raw repl");
            return this.serialRepl.exit_raw_repl();
        }
        else 
        {
            return this.bleRepl.exit_raw_repl();
        }
    }

    async enter_raw_repl()
    {
        if(this.serialRepl.serial.isConnected())
        {
            return this.serialRepl.enter_raw_repl();
        }
        else 
        {
            return this.bleRepl.enter_raw_repl();
        }
    }

    async exec_raw(cmd)
    {
        if(this.serialRepl.serial.isConnected())
        {
            return this.serialRepl.exec_raw(cmd);
        }
        else 
        {
            return this.bleRepl.exec_raw(cmd);
        }
    }

    registerUpdateTerminalCallback(callback)
    {
        this.serialRepl.updateTerminal = callback;
        this.bleRepl.updateTerminal = callback;
    }
}

class SerialInterface {
    //src: https://codelabs.developers.google.com/codelabs/web-serial
    constructor(board)
    {
        this.device = board;
        this.connected = false;
        this.port = null;
        this.reader = null;
        this.outputDone = null;
        this.inputDone = null;
        this.inputStream = null;
        this.outputStream = null;
        this._rxHandler = null;
        this.mutex = new Mutex();
    }

    async connect(navigator)
    {
      console.log("connect to serial!")
      if(navigator != null)
        this.navigator = navigator;
      // CODELAB: Add code to request & open port here.
      this.port = await this.navigator.serial.requestPort();
      // - Wait for the port to open.
      await this.port.open({ baudRate: 115200 });
      // CODELAB: Add code setup the output stream here.
      // eslint-disable-next-line no-undef
      const encoder = new TextEncoderStream();
      this.outputDone = encoder.readable.pipeTo(this.port.writable);
      this.outputStream = encoder.writable;
      
      // CODELAB: Send CTRL-C and turn off echo on REPL
      // CODELAB: Add code to read the stream here.
      // eslint-disable-next-line no-undef
      let decoder = new TextDecoderStream();
      this.inputDone = this.port.readable.pipeTo(decoder.writable);
      this.inputStream = decoder.readable;

      this.reader = this.inputStream.getReader();
      this.connected = true;
      this.readLoop();
      return {name: 'JEM Serial', connected: true};
    }

    async disconnect()
    {
        if (this.port) {
            // Close the input stream (reader).
            if (this.reader) {
                await this.reader.cancel();
                await this.inputDone.catch(() => {});
                this.reader = null;
                this.inputDone = null;
            }
            // Close the output stream.
            if (this.outputStream) {
                await this.outputStream.getWriter().close();
                await this.outputDone;
                this.outputStream = null;
                this.outputDone = null;
            }

            // Close the port.
            this.connected = false;
            await this.port.close();
            this.port = null;
            return;
        }
    }

    isConnected()
    {
        return this.connected;
    }

    registerRxCallback(rxCallback)
    {
        // whoever is using serial needs to set this, so they receive rx data from readLoop
        this._rxHandler = rxCallback;
    }

    rxHandler(bytes)
    {
        if(this._rxHandler == null)
        {
            console.warn("not implemented");
        }
        else 
        {
            console.log("rxHandler: " + bytes);
            this._rxHandler(bytes);
        }
    }

    async _write(bytes) // write, don't wait for resp
    {
        let chunkSize = 100;
        const writer = this.outputStream.getWriter();
        while(bytes.length > 0){
            await writer.write(Buffer.from(bytes.slice(0,chunkSize)).toString());
            bytes = bytes.slice(chunkSize);
        }
        writer.releaseLock();
    }

    async write(bytes){
        return this.mutex.synchronize(() => {return this._write(bytes);});
    }

    //runs in background after connected
    async readLoop() {
        let encoder = new TextEncoder();
        let reading = true;
        while (reading) {
        const { value, done } = await this.reader.read();
          if (value) {
            console.log("serial rd: " + value);
            //this.device.updateTerminal(value);
            let bytes = new Uint8Array(encoder.encode(value)); //Uint8Array
            let resp = [];
            for (let i = 0; i < bytes.length; i++){
                resp.push(bytes[i]);
            }
            this.rxHandler(resp);
          }
          if (done) {
            reading = false;
            console.log('[readLoop] DONE', done);
            this.reader.releaseLock();
            break;
          }
        }
      }
}

export class MicropythonBoard {
    constructor(bleDriver){
        this.bleManager = new BleManager(bleDriver);
        
        this.bleUartRepl = new BleUartReplService();
        this.ftpService = new BleFtpService();
        this.rcService = new BleRemoteControlService();
        this.infoService = new BleInfoService();

        this.bleManager.addService(this.bleUartRepl);
        this.bleManager.addService(this.ftpService);
        this.bleManager.addService(this.rcService);
        this.bleManager.addService(this.infoService);

        this.serial = new SerialInterface(this); // raw serial interface to host PC

        this.replBle = new ReplService(this.bleUartRepl); // repl using ble uart to jem interface
        this.replSerial = new ReplService(this.serial); // repl using wired serial from host pc to jem
        
        //this.replSerial = new SerialReplService(this.serial);
        this.replInterface = new ReplInterface(this.replBle, this.replSerial);
        this.pyboard = new Pyboard(this.replInterface);
    }

    async connect(type, args){
        console.log("Device.connect " + type);
        if(type == 'ble')
        {
            return this.bleManager.connect();
        }
        else if(type == 'serial') //&& !this.serial.isConnected())
        {
            return this.serial.connect(args)
        }
    }

    disconnect(type = 'ble')
    {
        if(type == 'ble')
            return this.bleManager.disconnect();
        else if(type == 'serial')
            return this.serial.disconnect();
    }

    async send(data){
        this.replInterface.send(data);
    }

    async getDirList(path){
        if(this.ftpService.available)
        {
            return this.ftpService.readDirs(path);
        }
        else 
        {
            return this.pyboard.getDirList(path);
        }
    }

    async renameFile(old_file_path, new_file_path)
    {
        return this.pyboard.renameFile(old_file_path, new_file_path);
    }

    async writeFile(path, data, chunkSize){
        if(this.ftpService.available)
            return this.ftpService.writeFile(path, data, chunkSize);
        else
            return this.pyboard.writeFile(path, data, chunkSize);
    }

    async mkDir(path)
    {
        return this.pyboard.mkDir(path);
    }

    async removeFiles(paths){
        //todo: add remove files support to ftp ble service
        return this.pyboard.removeFiles(paths); //use repl for now
    }

    async removeDirs(paths){
        //todo: add remove dirs support to ftp ble service
        return this.pyboard.removeDirs(paths); //use repl for now
    }

    async readFileChecksum(path){
        if(this.ftpService.available)
            return this.ftpService.getFileChecksum(path);
        else
            return this.pyboard.getFileChecksum(path);
    }

    async readFile(path){
        if(this.ftpService.available)
            return this.ftpService.readFile(path);
        else
            return this.pyboard.readFile(path);
    }

    isConnected(){
        return this.pyboard.repl.isConnected();
    }
}