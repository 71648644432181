<template>
  <div>
    <v-treeview v-model="tree" :items="activeProject.deviceTree" activatable item-key="filePath" open-on-click
      @update:active="loadFile">
      <template v-slot:prepend="{ item, open }">
        <v-icon v-if="!item.file">
          {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
        </v-icon>
        <v-icon v-else>
          {{ files[item.file] }}
        </v-icon>
      </template>
      <template v-slot:append="{ item }">
        <ProjectItemOptions :item="item"></ProjectItemOptions>
      </template>
    </v-treeview>
    <v-divider></v-divider>
    <v-btn class="ma-2" color="success" dark @click="flash()">
      Flash to JEM
      <v-tooltip :open-on-hover="true" location="top">Load project code onto JEM</v-tooltip>
      <template v-slot:loader>
        <span>Flashing...</span>
      </template>
    </v-btn>

    <v-btn
      text size="small" rounded
      @click="dialogTooltipFlash = true"
    >
      <v-icon>mdi-information</v-icon>
      <v-dialog
        v-model="dialogTooltipFlash"
        width="auto"
      >
        <v-card>
          <v-card-text>
            "Flash" loads current project code onto connected JEM
          </v-card-text>
          <v-card-actions>
            <v-btn text color="primary" block @click="dialogTooltipFlash = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-btn>

    <v-dialog v-model="notifyNotConnected" persistent max-width="290">

      <v-card>
        <v-card-title class="text-h5">
          No Device Connected
        </v-card-title>
        <!-- <v-card-text>Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running.</v-card-text> -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="notifyNotConnectedConfirm()">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="flashDialog" persistent max-width="290">

      <v-card>
        <v-card-title class="text-h5">
          Flash Complete
        </v-card-title>
        <!-- <v-card-text>Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running.</v-card-text> -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="reconnectDevice()">
            Reconnect
          </v-btn>
          <v-btn color="blue" text @click="cancelReconnectDevice()">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-divider></v-divider>
    <v-btn class="ma-2" color="blue" dark @click="sync()">
      Sync
      <v-tooltip :open-on-hover="true" location="top">Load project code from JEM or Github repository</v-tooltip>
      <template v-slot:loader>
        <span>Syncing...</span>
      </template>
    </v-btn>
    <v-btn
      text size="small" rounded
      @click="dialogTooltipSync = true"
    >
      <v-icon>mdi-information</v-icon>
      <v-dialog
        v-model="dialogTooltipSync"
        width="auto"
      >
        <v-card>
          <v-card-text>
            "Sync" fetches the project from the connected JEM or a remote Github repository and loads it as the current project. 
          </v-card-text>
          <v-card-actions>
            <v-btn text color="primary" block @click="dialogTooltipSync = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-btn>

    <v-dialog v-model="syncDialog" persistent max-width="290">

      <v-card>
        <v-card-title class="text-h5">
          Sync Complete
        </v-card-title>
        <!-- <v-card-text>Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running.</v-card-text> -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="syncCompleted()">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-divider></v-divider>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ProjectItemOptions from "./ProjectItemOptions";
import { Device } from '../store/projects.js';

export default {
  name: "FileExplorer",
  computed: mapState({
    activeProject: state => state.projects.activeProject,
    flashing: state => state.projects.flashing,
    syncing: state => state.projects.syncing
  }),
  components: { ProjectItemOptions },
  data: () => ({
    zipFile: null,
    notifyNotConnected: false,
    dialogTooltipFlash: false,
    dialogTooltipSync: false,
    device: Device,
    flashDialog: false,
    syncDialog: false,
    basePath: "/",
    files: {
      html: 'mdi-language-html5',
      js: 'mdi-nodejs',
      json: 'mdi-json',
      md: 'mdi-markdown',
      pdf: 'mdi-file-pdf',
      png: 'mdi-file-image',
      txt: 'mdi-file-document-outline',
      xls: 'mdi-file-excel',
      py: 'mdi-language-python' //todo add python icon, this doesn't do anything
    },
    tree: [],
  }),
  watch: {
    // This would be called anytime the flashing state changes
    flashing(isFlashing) {
      console.log("flashing state change detected to:" + isFlashing);
      if (isFlashing) {
        console.log("flashing started");
      } else {
        this.flashDialog = true; //trigger popup asking user for permissino to reboot / connect
      }
    },

    syncing(isSyncing) {
      console.log("syncing state change detected to:" + isSyncing);
      if (isSyncing) {
        console.log("isSyncing started");
      } else {
        this.syncDialog = true; //trigger popup
      }
    }
  },
  methods: {
    loadFile: function (path) {
      console.log("loadFile: " + path)
      if (path.length > 0){ // && this.activeProject.tree.length) {
        this.setCurrentFile(path.toString().trim());
      }
    },

    reconnectDevice: function () {
      console.log("reconnectDevice");
      this.flashDialog = false;
      this.$store.dispatch('projects/reconnectDevice');
    },

    cancelReconnectDevice: function () {
      console.log("cancelReconnectDevice");
      this.flashDialog = false;
    },

    syncCompleted: function () {
      this.syncDialog = false;
    },

    flash: function () {
      console.log("Flash project");
      if (!Device.isConnected()) {
        this.notifyNotConnected = true;
        return;
      }
      this.$store.dispatch('projects/flash', { project: this.activeProject });

    },
    sync: function () {
      console.log("Sync project");
      this.$store.dispatch('projects/sync', { project: this.activeProject });

    },
    setCurrentFile: function (filePath) {
      console.log("setCurrentFile: " + filePath);
      this.$store.dispatch('projects/loadFile', { filePath: filePath });
    },

    notifyNotConnectedConfirm: function () {
      this.notifyNotConnected = false;
    },

  },
}
</script>

