'use strict'

//FTP Classes used to parse incoming BLE UART FTP Commands for File i/o
//import { GetChecksum } from "../helpers.js";
const helpers = require( "../helpers.js");
const struct = require('python-struct'); //for packing / unpacking bytes

var AppendByteBuffer = function(buffer1, buffer2) {
    //example: let c = AppendByteBuffer(new Uint8Array([1,2,3]), new Uint8Array([4,5,6]))
    var tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
    tmp.set(new Uint8Array(buffer1), 0);
    tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
    return tmp;
};

var FindSubArray = function(master,sub){
    let sublen = sub.length;
    for(let i  =0; i<master.length; i++){
        if(sublen + i >= master.length)
            return -1;
        let newsub = master.slice(i,sublen + i);
        if(String(newsub) == String(sub))
            return i;
    }
    return -1;
    }

class FTPCmdMsg {
    static START = new Uint8Array([1,2]); //SOH, STX ascii
    static START_INDEX = 0;
    static CMD_ID_I = FTPCmdMsg.START_INDEX + 2;
    static PAYLOAD_LEN_I = FTPCmdMsg.CMD_ID_I + 1;
    static PAYLOAD_I = FTPCmdMsg.PAYLOAD_LEN_I + 2;

    static getMsg(cmdId, payload){
        console.log("getMsg cmdId: " + cmdId);
        let header = AppendByteBuffer(FTPCmdMsg.START, struct.pack("<BH",cmdId, payload.length).buffer);
        let msg = AppendByteBuffer(header, payload);
        let checksum = helpers.GetChecksum(msg);
        return AppendByteBuffer(msg, new Uint8Array([checksum]));
    }
}

class FTPCmdResp {
    //cmd ids
    static READ_FILE = 3;
    static WRITE_FILE = 4;
    static FILE_CHECKSUM = 5;
    static FAIL_RESP = 6;
    static EXE_CODE = 7;
    static GET_DIRS = 8;

    static getResp(buffer){
        let [startIndex, endIndex, cmdId, payloadLen, checksumValid] = [null, null, null, null, null];
        let ftpCmd = null;
        if(FindSubArray(buffer, FTPCmdMsg.START) != -1){
            console.log("FTPCmdResp.getResp found start");
            startIndex = FindSubArray(buffer, FTPCmdMsg.START);
            console.log("startIndex: " + startIndex);
            if(buffer.subarray(startIndex,).length >= (FTPCmdMsg.CMD_ID_I + 1)){
                cmdId = buffer[startIndex + FTPCmdMsg.CMD_ID_I];
                if(buffer.subarray(startIndex,).length >= (FTPCmdMsg.PAYLOAD_LEN_I + 1)){
                    payloadLen = struct.unpack("<H",Buffer.from(buffer.subarray(startIndex + FTPCmdMsg.PAYLOAD_LEN_I, startIndex + FTPCmdMsg.PAYLOAD_LEN_I + 2)))[0];
                    console.log("payloadLen: " + payloadLen);
                    let remaining = buffer.subarray(startIndex + FTPCmdMsg.PAYLOAD_I,).length;
                    if(remaining > payloadLen){
                        let checksumIndex = startIndex + FTPCmdMsg.PAYLOAD_I + payloadLen;
                        console.log("checksumIndex: " + checksumIndex);
                        let rxChecksum = buffer[checksumIndex];
                        endIndex = checksumIndex + 1;
                        let realChecksum = helpers.GetChecksum(buffer.subarray(startIndex, checksumIndex));
                        checksumValid = (rxChecksum == realChecksum);
                        let pStart = startIndex + FTPCmdMsg.PAYLOAD_I;
                        let pEnd = startIndex + FTPCmdMsg.PAYLOAD_I + payloadLen;
                        if (checksumValid)
                            ftpCmd = FTPCmdResp.create(cmdId, buffer.subarray(pStart, pEnd));
                        else
                            ftpCmd = FTPCmdResp.create(FTPCmdResp.FAIL_RESP, "cmd_id " + cmdId + ": checksum failed");
                    }
                }
            }
        }

        return {valid: checksumValid, endIndex: endIndex, cmd: ftpCmd};
    }
   
    static create(id, payload){
        console.log("create cmd id " + id);
        if(id == FTPCmdResp.WRITE_FILE){
            return new FTPWriteCmd(payload);
        }
        else if(id == FTPCmdResp.READ_FILE){
            return new FTPReadCmd(payload);
        }
        else if (id == FTPCmdResp.FAIL_RESP){
            return new FTPFailCmd(payload);
        }
        else if (id == FTPCmdResp.FILE_CHECKSUM){
            return new FTPChecksumCmd(payload);
        }
        else if (id == FTPCmdResp.EXE_CODE){
            return new CodeCmd(payload);
        }
        else if (id == FTPCmdResp.GET_DIRS){
            return new FTPGetDirsCmd(payload);
        }
        else {
            console.log("FTPCmdResp.create with id: " + id + " unhandled");
        }
        return null
    }
}

class FTPWriteCmd {
    static METHOD_LIST = ["wb", "ab"];
    constructor(respPayload){
        this.respPayload = respPayload;
    }

    getRespMsg(){
        //should be 'ok' or 'failed'
        return Buffer.from(this.respPayload).toString();
    }

    static getSendMsg({wrMethod="wb", wrPos=0, fileName=null, fileData=null}){
        let wrMethodId = 0;
        if(FTPWriteCmd.METHOD_LIST.includes(wrMethod))
            wrMethodId = FTPWriteCmd.METHOD_LIST.indexOf(wrMethod);
        let pat = "<BLH" + String(fileName.length) + "s"; //ex: <BLH10s if filename is 10 char long
        let msg = struct.pack(pat, wrMethodId, wrPos, fileName.length, fileName);
        let payload = AppendByteBuffer(msg.buffer, fileData);
        console.log("wr getSendMsg " + fileName + " bytes " + fileData.length);
        return FTPCmdMsg.getMsg(FTPCmdResp.WRITE_FILE, payload); // ftp msg buffer
    }

}

class CodeCmd {
    constructor(respPayload){
        this.respPayload = respPayload;
    }

    getRespMsg(){
        //should be 'ok' or 'failed'
        return Buffer.from(this.respPayload).toString();
    }

    static getSendMsg(codeBytes){
        return FTPCmdMsg.getMsg(FTPCmdResp.EXE_CODE, codeBytes);
    }
}

class FTPReadCmd {
    constructor(respPayload){
        this.respPayload = respPayload;
    }

    getRespMsg(){
        //should be 'ok' or 'failed'
        return Buffer.from(this.respPayload).toString();
    }

    static getSendMsg({rdPos=0, rdLen=0, fileName=null}){
        let pat = "<LHH" + String(fileName.length) + "s"; //ex: <BLH10s if filename is 10 char long
        let msg = struct.pack(pat, rdPos, rdLen, fileName.length, fileName);
        let payload = new Uint8Array(msg.buffer)
        return FTPCmdMsg.getMsg(FTPCmdResp.READ_FILE, payload);
    }
}

class FTPFailCmd {
    constructor(respPayload){
        this.respPayload = respPayload;
    }

    getRespMsg(){
        //should be 'ok' or 'failed'
        return Buffer.from(this.respPayload).toString();
    }
}

class FTPChecksumCmd {
    constructor(respPayload){
        this.respPayload = respPayload;
        this.checksum = null;
        this.fileLen = 0;
    }

    getRespMsg(){
        //should be 'ok' or 'failed'
        let msg = struct.unpack("<BL", Buffer.from(this.respPayload));
        this.checksum = msg[0];
        this.fileLen = msg[1];
        return [this.checksum, this.fileLen];
    }

    static getSendMsg(fileName=null){
        let pat = "<H" + String(fileName.length) + "s"; //ex: <BLH10s if filename is 10 char long
        let msg = struct.pack(pat, fileName.length, fileName);
        let payload = new Uint8Array(msg.buffer)
        return FTPCmdMsg.getMsg(FTPCmdResp.FILE_CHECKSUM, payload);
    }
}

class FTPGetDirsCmd {
    constructor(respPayload){
        this.respPayload = respPayload;
    }

    getRespMsg(){
        //should be json formatted string of nested directory structure of jem
        return JSON.parse(Buffer.from(this.respPayload).toString());
    }

    static getSendMsg(rootName=null){
        let pat = "<H" + String(rootName.length) + "s"; //ex: <BLH10s if rootName is 10 char long
        let msg = struct.pack(pat, rootName.length, rootName);
        let payload = new Uint8Array(msg.buffer)
        return FTPCmdMsg.getMsg(FTPCmdResp.GET_DIRS, payload);
    }
}

module.exports = { FTPWriteCmd, FTPReadCmd, FTPFailCmd, FTPChecksumCmd, FTPCmdMsg, FTPCmdResp, CodeCmd, FTPGetDirsCmd, AppendByteBuffer, FindSubArray };