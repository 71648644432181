// recommended structure example
// https://github.com/vuejs/vuex/tree/dev/examples/shopping-cart

import Vue from 'vue'
import Vuex from 'vuex'
import projects from './projects'
import ide from './ide'
// import createLogger from '../../../src/plugins/logger'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    projects,
    ide
  },
  strict: debug,
  // plugins: debug ? [createLogger()] : []
})