<template>
    <div class="debug-terminal">
      <h3>Debug Logs</h3>
      <div class="terminal-content">
        <ul>
          <li v-for="(message, index) in messages" :key="index">{{ message }}</li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  import { EventBus } from '../lib/eventBus';

  export default {
    data() {
      return {
        messages: ['hello', 'yes'],
      };
    },

    methods: {
    
    },
    destroyed() {
        console.log("Destroyed!!!")
        EventBus.$off('new-debug-message', this.handleLogEvent);
    },
    created() {
      console.log("!!!CREATED!!!");
      // Listen to an event to update messages
      EventBus.$on('new-debug-message', (message) => {
        this.messages.push(message);
        // Optional: Auto-scroll to the bottom
        this.$nextTick(() => {
          const content = this.$el.querySelector('.terminal-content');
          content.scrollTop = content.scrollHeight;
        });
      });

    },
    beforeDestroy() {
        EventBus.$off('new-debug-message');
    }
  };
  </script>
  
  <style scoped>
  .debug-terminal {
    background-color: black;
    color: lime;
    padding: 10px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    font-family: monospace;
  }
  
  .terminal-content ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .terminal-content li {
    margin: 5px 0;
    color: white
  }
  </style>
  